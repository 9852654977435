import { Autocomplete, TextField, Chip } from "@mui/material";
import React from "react";

export default function ChipInput(props) {
  const {  label, placeholder, error, value, onChange, fullWidth } = props;

  const hasError = !!error;

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      value={value}
      freeSolo
      autoSelect
      onChange={onChange}
      fullWidth={fullWidth}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          console.log(option);
          return (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError}
          helperText={error}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}
