import React from "react";
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { secondaryDarker, secondaryMain, primaryMain } from '../../../constants/colors';

const Container = styled.div`
  background: linear-gradient(180deg, #7F003E -2.58%, #8D0944 3.5%, #A91D51 18.7%, #C02C5B 34.92%, #D03762 52.15%, #D93D67 71.41%, #DC3F68 98.77%), #1C2945;
  border-radius: 40px;
  padding: 15px;
`;

const BaseTypography = styled(Typography) `
  color: white;
`;

const HeaderTypography = styled(BaseTypography)`
  font-size: 2.75rem;
`;

const BodyTypography = styled(BaseTypography)`
  font-size: 1.25rem;
`;

const GovCloudLearnMoreButton = styled(Button)`
  width: 180px;
  height: 55px;
  
  background: white;
  box-shadow: 0px 19.6981px 45.6165px rgba(212, 58, 101, 0.37), inset 0px 2.75054px 13.7527px rgba(255, 255, 255, 0.31);
  border-radius: 15px;
  color: ${secondaryDarker};
  font-size: 21px;
  text-transform: none;

  &:hover {
    background: white;
  }
`;
const GovCloudLearnMoreLink = 'https://aws.amazon.com/govcloud-us/faqs/'

const AWSGovCloud = (props) => {
  return (
    <Container>
      <HeaderTypography variant='h3' align='center' paddingTop={3} paddingBottom={2}>
        STAR is now available on <u>AWS GovCloud</u>
      </HeaderTypography>
      <BodyTypography variant='body1' marginBottom={1} paddingX={2}>
        <b>What is AWS GovCloud?</b> <br/>
        AWS GovCloud (US) is designed to address specific regulatory and compliance requirements of US government agencies at the federal, state, and local level, as well as contractors, educational institutions, and other U.S. customers that run sensitive workloads in the cloud.
      </BodyTypography>
      <div style={{textAlign:"center"}}>
        <GovCloudLearnMoreButton href={`${GovCloudLearnMoreLink}`} target='_blank'>Learn More</GovCloudLearnMoreButton>
      </div>
    </Container>
  );
}

export default AWSGovCloud;