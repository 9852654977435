import React from 'react';
import { useLocation, NavLink } from "react-router-dom";
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import Header from './Header';
import Footer from './Footer';
import { maxWidth } from '../../constants/dimensions';
import { background, primaryMain, primaryMedium, primaryLight, secondaryMain } from '../../constants/colors';
import { signInPath, registerPath, resetPasswordPath, landingPath, overviewPath } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  trueRoot: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: '#d4d7de',
  },
  root: {
    position: 'relative',
    height: '100%',
    transition: 'background 0.75s ease',
    marginBottom: 'auto',
  },
  banner: {
    zIndex: 100,
    position: 'absolute',
    borderBottom: `1px solid ${primaryMain}`,
    transition: 'height 0.75s ease',
  },
  children: {
    zIndex: 200,
    position: 'relative',
    paddingTop: 100,
    maxWidth: maxWidth,
    margin: '0 auto',
  }
}));

const defaultStyles = makeStyles((theme) => ({
  root: {
    background: background,
  },
  banner: {
    height: 248,
  },
}));

const authStyles = makeStyles((theme) => ({
  root: {
    background: background,
  },
  banner: {
    height: '100%',
  },
}));

const landingStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
  },
  banner: {
    // height: 360,
    height: '100%',
  },
}));

const useViewStyles = (view) => {
  let styles;
  const location = useLocation();

  switch (location.pathname) {
    case signInPath:
    case registerPath:
    case resetPasswordPath:
      styles = authStyles();
      break;
    case landingPath:
    case overviewPath:
      styles = landingStyles();
      break;
    default:
      styles = defaultStyles();
  }

  return styles;
}

export default function Layout(props) {
  const classes = useStyles();
  const viewClasses = useViewStyles();

  const { user, setUser, children, documentTitle } = props;

  const bannerProps = {
    boxShadow: 2,
    className: clsx(classes.banner, viewClasses.banner, 'moving-gradient'),
  }

  React.useEffect(() => document.title = documentTitle);

  return (
    <div className={classes.trueRoot}>
      <CssBaseline/>
      <Header user={user} setUser={setUser} />

      <div className={clsx(classes.root, viewClasses.root)}>
        <div className={classes.children}>
          {children}
        </div>
      </div>

      <Footer/>
    </div>
  )
}
