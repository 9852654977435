import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';

import PageTitle from '../../common/headings/PageTitle';
import TextSection from './TextSection';
import { primaryLightest, primaryDark } from '../../constants/colors'; 

const text_1 = `
  Sakura Software Solutions is committed to protecting our users’ Confidential Information.
  <i><b>Users</i></b> in the context of this policy means both employees and their employers.
  <i><b>Confidential Information</i></b> shall mean all information related to any aspect of the 
  business of our users which is either information not known by actual or potential competitors 
  of the users or is proprietary information of the users, whether of a technical nature or otherwise.
`;

const text_2 = `
  We will hold all Confidential Information or data in confidence and will not disclose, use, copy, 
  publish, summarize, or remove from the 3S STAR tool any Confidential Information, except when necessary 
  to setup, use, improve the application as required by the user providing such information.
  <br/>
  <br/>
  We will ensure that any user of the our software is only able to gain access to such information that said 
  user has provided, and not for any other users. This means that any information provided by any user will 
  only be available – within the tool – for the user providing it.
`;

const text_3 = `
  We will be happy to engage with all our users on all matters related to data management and security, and 
  agree to the necessary agreements related to information non-disclosure.
`;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 920,
    margin: '0 auto',
    marginBottom: 96,
    padding: '12px 12px',
    paddingBottom: 36,
  },
}));

export default function PrivacyPolicyView(props) {
  const classes = useStyles();
  
  const { setDocumentTitle } = props;
  
  React.useEffect(() => setDocumentTitle('3S | Privacy Policy'))

  return (
    <React.Fragment>
      <PageTitle>Privacy Policy</PageTitle>

      <Card className={classes.card}>
        <TextSection text={text_1} />
        <TextSection text={text_2} title='Data Privacy' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_3} title='Data Security & Non-Disclosure' color={primaryLightest} borderColor='silver' />
      </Card>
    </React.Fragment>
  )
}


