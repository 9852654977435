import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '12px 24px',
    margin: '0px 12px',
    paddingBottom: 24,
  },
  title: {
    fontSize: 18,
    paddingBottom: 6,
  },
  text: {
    fontSize: 14,
  }
}));

export default function TextSection(props) {
  const classes = useStyles();
  const { text, title, color = 'none', borderColor = 'white', elevation = 0 } = props;

  return (
    <Box className={classes.root} bgcolor={color} border={1} borderColor={borderColor} boxShadow={elevation}>
      {title && (
        <React.Fragment>
          <Typography variant='h2' className={classes.title}>
            {title}
          </Typography>
          <Divider />
          <br/>
        </React.Fragment>
      )}

      <Typography className={classes.text} align='justify'>
        { ReactHtmlParser(text) }
      </Typography>
    </Box>
  )
}