export const videos = [
  {
    navName: 'Overview',
    urlName: 'overview', 
    url: 'https://www.youtube.com/embed/0sEzGhlce3o'
  },
  {
    navName: 'Walkthrough',
    urlName: 'walkthrough', 
    url: 'https://www.youtube.com/embed/uC5Mvnizg5I'
  },
  {
    navName: 'Defect Trends',
    urlName: 'defect-trends', 
    url: 'https://www.youtube.com/embed/VZRHswUZdq4'
  },
  {
    navName: 'Early Defect',
    urlName: 'early-defect', 
    url: 'https://www.youtube.com/embed/xPi0GEpsGMc'
  },
  {
    navName: 'What is STAR?',
    urlName: 'what-is-star', 
    url: 'https://www.youtube.com/embed/5jM0xajiaF0'
  },
  {
    navName: 'STAR Quality Metrics',
    urlName: 'star-quality-metrics', 
    url: 'https://youtube.com/embed/cEnSK5Plfww'
  },
  {
    navName: 'Uploading Data to STAR',
    urlName: 'upload-data-to-star', 
    url: 'https://www.youtube.com/embed/66NrwujDHKQ'
  },
]
