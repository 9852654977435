import React from "react";
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Container = styled.div`
  background-color: #1C2945;
  border-radius: 40px;
  padding: 15px;
`;

const BaseTypography = styled(Typography) `
  color: white;
`;

const HeaderTypography = styled(BaseTypography)`
  font-size: 2.75rem;
`;

const BodyTypography = styled(BaseTypography)`
  font-size: 1.25rem;
`;

const WhatIsStar = (props) => {
  return (
    <Container>
      <HeaderTypography variant='h3' align='center' paddingTop={3} paddingBottom={2}>
        What is STAR?
      </HeaderTypography>
      <BodyTypography variant='body1' marginBottom={3} paddingX={2}>
        Introducing STAR: The Ultimate Software Quality Assurance Tool. <br/><br/>
        STAR is an advanced tool designed to pinpoint and resolve issues causing customer dissatisfaction with software products. By accurately predicting defects during planning or testing phases, STAR helps teams make informed decisions and provides guidance on corrective actions, thereby reducing development costs. Our cloud-based service offers automated processes, powerful analytics, and easy-to-understand visuals. Experience improved release quality and resource allocation with customizable features. <br/><br/>
        Get started today and help your team deliver a high quality product on-time!
      </BodyTypography>
    </Container>
  );
}

export default WhatIsStar;