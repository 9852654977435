import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function OneDotFourEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 1.4 Release Notes</h2> 
      <h3>Rollout started on February 9th, 2022</h3>

      <p>
        <b>STAR 1.4</b> introduces <b>Landing Page</b> Phase II which is available from <a href="https://sakurasoftsolutions.com/">https://sakurasoftsolutions.com/</a>. This will enable general users to access our STAR Demo without actually signing in.  It also introduces an explainer video and promo video to the website to supplement the user guide.
      </p>
      <p>
        <b>STAR 1.4</b> continues to improve the usability of our STAR service from the customer's side.
      </p>
      <p>
        Continue reading below to learn more about the most important updates and improvements we've worked on.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>Landing page:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Made the <b>STAR Demo</b> (Customer = Demo) available to general users through the landing page without log-in. Users can now see the STAR dashboard with Demo data.
            </li>
            <li className={classes.li}>
              Added a <b>video</b> on STAR introduction.
            </li>
            <li className={classes.li}>
              Added content from the one-page STAR flyer.
            </li>
            <li className={classes.li}>
              Re-organized <b>menu bar</b>.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Dashboard:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              User Input:
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Simplified the <b>user input configuration</b> to put three sets of user data in one page: Release parameters, defect data, and effort data.
                </li>
                <li className={classes.li}>
                  Introduced the following two new parameters in the user input configuration:
                  <ul className={classes.ul}>
                    <li className={classes.li}>
                      <b>“Start date”</b>: It is implemented in the core engine and user interface to provide consistent prediction results and charts.
                    </li>
                    <li className={classes.li}>
                      <b>“Prior release”</b>: It will be used for early defect prediction without actual defect data.
                    </li>
                  </ul>

                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Core Engine: 
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Introduced a <b>new preprocessing</b> on effort data for early defect prediction. It will improve the prediction algorithm to work efficiently and consistently. 
                </li>
                <li className={classes.li}>
                  Implemented an <b>additional condition</b> to identify the final curve in the algorithm and implemented it in the core engine for the defect trend view. It is needed for rare cases. The defect trend view will now work appropriately almost all cases.
                </li>
                <li className={classes.li}>
                  Adjusted the <b>corrective action</b> algorithm to work for early defect prediction.
                </li>
                <li className={classes.li}>
                  Expanded the early prediction algorithm to take care of <b>any leading data</b>, e.g., effort data, previous release data, release-level data for component-level prediction.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Output:
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Added early prediction with <b>actual defect data overlaid</b> (e.g., Leading Data (IPM)). We can now observe the difference between the early prediction and actual data.
                </li>
                <li className={classes.li}>
                  Added <b>corrective action view</b> for early defect prediction (e.g., Project D Release B).
                </li>
                <li className={classes.li}>
                  Introduced <b>effort-based defect prediction view</b> without prior release, e.g., Project D Release A in the STAR Demo.
                </li>
                <li className={classes.li}>
                  Added <b>“Prediction stability”</b> view for early defect prediction in Leading Data (IPM). We can now compare the prediction stability in two different views: Leading Data (IPM) and Defect Trend. It will clearly show the stability improvement by leading data.
                </li>
                <li className={classes.li}>
                  Completed the following three new views:
                  <ul className={classes.ul}>
                    <li className={classes.li}>
                      <b>"Defect by component"</b>
                    </li>
                    <li className={classes.li}>
                      <b>"Defect by severity"</b>
                    </li>
                    <li className={classes.li}>
                      <b>"Release over release"</b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Troubleshooting: Added more data and error handling for internal <b>troubleshooting</b>.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 1.4</b> resulted from User feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}

export function OneDotFourJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 1.4 リリースノート</h2> 
      <h3>2022 年 2 月</h3>
      
      <p>
        主な新機能と改善項目は以下の通りです。
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>ランディングページ</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              ランディングページのサイトからログインして登録することなく <b>STAR Demo</b> を閲覧することができます。
            </li>
            <li className={classes.li}>
              STAR  製品紹介の<b>動画</b>を掲載しました。
            </li>
            <li className={classes.li}>
              STAR <b>フライヤー</b>の一部を抜粋しました。
            </li>
            <li className={classes.li}>
              <b>メニュー</b>の組み立てを改良しました。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>ダッシュボード</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>ユーザーインプット</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Release config ページの簡素化－</b> ３ 項目（Release Parameters, Defect Data, and Effort Data）を一行にまとめました。
                </li>
                <li className={classes.li}>
                  <b>Start Date</b> を追加してチャートの始発日をコントロールできます。
                </li>
                <li className={classes.li}>
                  初期バグ予測曲線に必要な <b>Prior Release</b> を追加して選択できます。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>コアエンジン</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  初期バグ予測曲線の作成に必要な<b>プリプロセス</b>を導入して、予測アルゴリズムの効率的かつ安定度を改良しました。
                </li>
                <li className={classes.li}>
                  <b>余分のコンディション</b>を追加しバグ予測アルゴリズムの汎用性を改良しました。
                </li>
                <li className={classes.li}>
                  <b>Corrective Action</b> のアルゴリズムを改良して初期バグ予測曲線にも対応可能にした。
                </li>
                <li className={classes.li}>
                  <b>初期バグ予測曲線</b>のアルゴリズムを改良してエフォートデータ以外のリーディングデータ（例：前回のリリースデータ、コンポーネントの予測用のリリースデータ）にも対応可能にした。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>アウトプット</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Early prediction view:</b> 初期バグ予測を実際のバグデーターと比較できます。バグデーターの arrival, closure & open ごとに比較可能です。
                </li>
                <li className={classes.li}>
                  <b>Corrective action view:</b> 初期バグ予測にも対応可能にした。プロジェクトD　リリースB参照。
                </li>
                <li className={classes.li}>
                  <b>Prediction stability view:</b> 初期バグ予測にも対応可能にした。これにより、 Leading Data (IPM) と Defect Trend との比較可能です。
                </li>
                <li className={classes.li}>
                  以下の<b>新しい view </b> の追加を完了。
                  <ul className={classes.ul}>
                    <li className={classes.li}>
                      <b>“Defect by component”</b>
                    </li>
                    <li className={classes.li}>
                      <b>“Defect by severity”</b>
                    </li>
                    <li className={classes.li}>
                      <b>“Release over release”</b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>その他の改善項目</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  余分のデータと更なる Error Handling を追加して<b>トラブルシューティング</b>が迅速になります。
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください。
      </p>
    </React.Fragment>
  )
}
