import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import {primaryMain} from '../../constants/colors';
import Field from './Field';


const useStyles = makeStyles((theme) => ({
  text: {
    color: primaryMain,
    marginBottom: 12,
    fontWeight: 'bold'
  },
}));

function CompanyForm(props) {
  const classes = useStyles();
  const { company, companyError, handleCompanyChange } = props;

  return (
    <React.Fragment>
      <Typography className={classes.text}>
        Please enter and confirm your company name.
      </Typography>
      <Field value={company} label='Company Name' autoFocus={true} error={companyError} onChange={handleCompanyChange} />
    </React.Fragment>
  )
}

export default CompanyForm;
