import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const ChartImage = styled.img`
  width: 100%;
`;

const HeaderTypography = styled(Typography)`
  margin: 0 auto;
  max-width: 90%;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #fff1f5;
  border: 1px solid rgba(170, 31, 83, 0.52);
  border-radius: 40px;
  margin-bottom: 120px;
`;

const ImageCard = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
  background: #FFFFFF;
  box-shadow: 0px 52.4321px 54.4487px rgba(65, 62, 101, 0.11), inset 0px -5px 0px #C32F5D;
  border-radius: 26px;
  padding: 20px;
  margin-bottom: -120px;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  border-radius: 13px;
`;

const RealtimeImpact = (props) => {
  return (
    <BackgroundContainer>
      <HeaderTypography variant='h3' align='center' paddingTop={5}>
        See The Real-Time Impact of Corrective Actions
      </HeaderTypography>
      <HeaderTypography variant='h5' align='center' paddingBottom={2}>
        Interactively change your release date and staffing and track how your quality improves
      </HeaderTypography>

      <ImageCard>
        <ImageContainer>
          <ChartImage src={process.env.PUBLIC_URL + '/assets/landing/corrective-actions.gif'} alt='Corrective Actions Page Screenshot GIF' />
        </ImageContainer>
      </ImageCard>
    </BackgroundContainer>
  ); 
}

export default RealtimeImpact;
