import React from "react";
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';


const TermSelectionContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const TermSelectionButton = styled(Button)`
    width: 225px;
    height: 68px;
    background: ${({ isSelected }) => isSelected ? `linear-gradient(180deg, #7F003E -2.58%, #8D0944 3.5%, #A91D51 18.7%, #C02C5B 34.92%, #D03762 52.15%, #D93D67 71.41%, #DC3F68 98.77%), #1C2945` : "white"};
    border-radius: 20px;
    color: ${({ isSelected }) => isSelected ? "white" : "lightgray"};;
    font-size: 2rem;
    text-transform: none;
    display: inline-block;
    text-align: center;
`;

const ButtonText = styled.span`
  font-size: 2rem;
  display: inline-block;
  transform: ${({ slideUp }) => slideUp ? "translateY(-9px)" : "0px"};;
`;

const SavedPercent = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 21px;
  color: ${({ isSelected }) => isSelected ? "white" : "lightgray"};;
  text-align: center;
  width: 100%;
`;

const BodyTypography = styled(Typography)`
  font-size: 0.9rem;
`;


const ServicePeriodSelect = ({servicePeriodChanged, activeButton}) => {
    return (
        <>
            <TermSelectionContainer>
                <TermSelectionButton isSelected={activeButton === "monthly"} onClick={() => servicePeriodChanged("monthly")}><ButtonText>Monthly</ButtonText></TermSelectionButton>
                <TermSelectionButton isSelected={activeButton === "yearly"} onClick={() => servicePeriodChanged("yearly")}><ButtonText slideUp={true}>Yearly</ButtonText><SavedPercent isSelected={activeButton === "yearly"}>Save 15%</SavedPercent></TermSelectionButton>
            </TermSelectionContainer>
            <BodyTypography variant='body1' marginTop={1} marginBottom={0} align='center'>Select service period</BodyTypography>
        </>
    );
}

export default ServicePeriodSelect;