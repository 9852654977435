import React from 'react';
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import EmailField from '../components/form/EmailField';
import PasswordField from '../components/form/PasswordField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';
import AuthLink from '../components/form/AuthLink';
import ConfirmSignUp from './ConfirmSignUp';
import NewPassword from './NewPassword';
import { ForgotPasswordPromptLink } from './ForgotPasswordPrompt';
import { SignUpLink } from './SignUp';

export default function SignIn(props) {
  const {
    user,
    setUser,
    email,
    emailError,
    setEmailError,
    handleEmailChange,
    password,
    setPassword,
    passwordError,
    setPasswordError,
    handlePasswordChange,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();
  const [requestConfirmation, setRequestConfirmation] = React.useState(false);
  const [requestPasswordChange, setRequestPasswordChange] = React.useState(false);

  React.useEffect(() => setDocumentTitle('3S | Login'))

  const handleSubmit = async () => {
    // Validation.
    if (!email) {
      setCognitoError('Email Required');
      return;
    }
    if (!password) {
      setCognitoError('Password Required');
      return;
    }
    if (emailError || passwordError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      const response = await Auth.signIn(email, password);
      

      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setPassword('');
        setRequestPasswordChange(true)
      }

      else {
        setUser(response);
      }
    }
    catch (error) {
      const { code } = error;

      if (code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(email);
        setRequestConfirmation(true);
      }

      else {
        const { message } = error;
        setCognitoError(message);
      }
    }
    setSubmitting(false);
  }

  if (requestConfirmation) {
    return <ConfirmSignUp {...props} />
  }

  if (requestPasswordChange) {
    return <NewPassword user={user} {...props} />
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <EmailField value={email} error={emailError} onChange={handleEmailChange} autoFocus={true} />
      <PasswordField value={password} error={passwordError} onChange={handlePasswordChange} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Sign In" />
      <SignUpLink />
      <ForgotPasswordPromptLink />
    </form>
  )
}

export function SignInLink(props) {
  const { label } = props;

  const location = useLocation();
  const history = useHistory();

  const onClick = () => {
    history.push({
      pathname: '/login',
      search: location.search,
    });
  }

  return (
    <AuthLink label={label} onClick={onClick} />
  )
}

