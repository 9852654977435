import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { videos } from './videos';
import Paper from '@mui/material/Paper';
import { primaryLight, primaryLightest, secondaryMain } from '../../constants/colors';
import { videosPath } from '../../constants/routes';


const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid silver',
    background: primaryLightest,
  },
  ul: {
    padding: '0px 12px',
    listStyleType: 'none',
  },
  li: {
    padding: '6px 12px',
    borderBottom: '1px solid gainsboro',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:hover': {
      background: primaryLight,
      cursor: 'pointer',
    }
  },
  liActive: {
    fontWeight: 'bold',
    color: secondaryMain,
  }
}));

export default function VideoNav(props) {
  const classes = useStyles();
  const { selectedVideo } = props;
  const history = useHistory();

  return (
    <Paper className={classes.root}>
      <ul className={classes.ul}>
        { videos.map((r) => (
          <li 
          className={clsx(classes.li, { [classes.liActive]: r.navName === selectedVideo })} 
          onClick={() => history.push(`${videosPath}/${r.urlName}`)}
        >
            {r.navName}
        </li>
        ))}
      </ul>
    </Paper>
  )
}
