import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import PageTitle from '../../common/headings/PageTitle';
import VersionNav from './VersionNav';
import LanguageSelector from './LanguageSelector';
import { en, releaseNotes } from './notes';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1280,
  },
  sidebar: {
    borderRight: '1px solid silver',
    paddingTop: '0 !important',
    marginTop: 24,
    paddingRight: 24,
  },
}));


export default function ReleaseNotesView(props) {
  const classes = useStyles();
  const { setDocumentTitle } = props;

  const [language, setLanguage] = useState(en);
  const [selectedRelease, setSelectedRelease] = useState(releaseNotes[releaseNotes.length-1].name);

  let { releaseId } = useParams();
  if (releaseId && releaseId !== selectedRelease && releaseNotes.find(x => x.name === releaseId)) {
    setSelectedRelease(releaseId);
  }

  const releaseVersion = releaseNotes.find(obj => obj.name === selectedRelease);

  if (!releaseVersion.jp && language === 'jp') {
    setLanguage('en');
  } 

  const NotesComponent = releaseVersion[language];

  React.useEffect(() => setDocumentTitle('3S | Release Notes'))

  return (
    <React.Fragment>
      <PageTitle>Release Notes</PageTitle>
      <Card className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3} className={classes.sidebar}>
            <LanguageSelector releaseVersion={releaseVersion} language={language} onChange={(e) => setLanguage(e.target.value)} />
            <VersionNav selectedRelease={selectedRelease} />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Box px={2} pb={2}>
              { NotesComponent && <NotesComponent /> }
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}
