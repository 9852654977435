import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports.js';

import Layout from './common/layout/main';
import RouteWrapper from './common/routing/RouteWrapper';
import { documentationPath, routes, userGuidePath } from './constants/routes';

Amplify.configure(awsconfig);

export default function App() {
  const [user, setUser] = React.useState();
  const [documentTitle, setDocumentTitle] = React.useState('3S');

  const location = useLocation();

  React.useEffect(() => {
    async function authenticate() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
        // console.log('Authenticated');
      }
      catch (error) {
        setUser(false);
        // console.log('Unauthenticated');
      }
    };
    authenticate();
  }, []);

  return (
    <Layout user={user} setUser={setUser} documentTitle={documentTitle}>
      <Switch>
        <Route path={["/dashboard", "/dashboard/*"]} component={() => {
          const dashboardPath = location.pathname.replace("/dashboard", "");
          window.location.href = process.env.REACT_APP_DASHBOARD_URL + dashboardPath;
          return null;
        }} />

        <Route exact path={documentationPath}>
          <Redirect to={userGuidePath} />
        </Route>
        {routes.map(route => (
          <RouteWrapper user={user} setUser={setUser} setDocumentTitle={setDocumentTitle} {...route} />
        ))}
      </Switch>
    </Layout>
  )
}


