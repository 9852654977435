import React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Grid, Typography } from '@mui/material';
import Logo from '../../common/components/Logo';
import { Link, useLocation } from 'react-router-dom';
import { contactUsPath, userGuidePath, termsOfServicePath, privacyPolicyPath, landingPath, overviewPath } from '../../constants/routes';
import { background } from '../../constants/colors';


const StyledFooter = styled.footer`
  position: relative;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-image: url('/assets/landing/footer-background.svg');
  height: 400px;
  padding-top: 50px;
`;


const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const LinkContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledLink = styled(Link)`
  margin-top: 8px;
  text-decoration: none;
  color: navy;
`;

function GetLocationBackground(location) {
  switch (location.pathname) {
    case landingPath:
    case overviewPath:
      return 'white'
    default:
      return background;
  }
}

export default function Footer(props) {

  const location = useLocation();
  const backgroundColor = GetLocationBackground(location);

  return (
    <StyledFooter style={{ background: backgroundColor }}>
      <BackgroundContainer>
        <Container component={Grid}>
          <Grid container spacing={2}>
            <Column component={Grid} item xs={12} sm={4}>
              <Logo />
            </Column>
            <Column component={Grid} item sm={4} display={{ xs: 'none', sm: 'flex' }} marginLeft={2}>
              <LinkContainer align>
                <Typography variant="h6">
                  Menu
                </Typography>
                <StyledLink to={contactUsPath}>Contact Us</StyledLink> 
                <StyledLink to={userGuidePath}>User Guide</StyledLink> 
                <StyledLink to={termsOfServicePath}>Terms of Service</StyledLink> 
                <StyledLink to={privacyPolicyPath}>Privacy Policy</StyledLink> 
              </LinkContainer>
            </Column>
          </Grid>
        </Container>

      </BackgroundContainer>
    </StyledFooter>
  )
}
