import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { primaryMedium, secondaryMain } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(45deg, ${primaryMedium} 0%, ${secondaryMain} 100%)`,
    maxWidth: 720,
    margin: '12px auto',
  },
  titleText: {
    fontSize: 26,
    padding: 6,
    color: 'white',
    textTransform: 'uppercase',
  },
}));

export default function Layout(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Box boxShadow={2} className={classes.root}>
      <Typography variant='h1' align='center' className={classes.titleText}>
        {children}
      </Typography>
    </Box>
  )
}
