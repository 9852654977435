import React from 'react';

import { Auth } from 'aws-amplify';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import PageTitle from '../../common/headings/PageTitle';
import CompanyForm from './CompanyForm';
import ProjectForm from './ProjectForm';
import SubmitForm from './SubmitForm';
import UploadDataForm from './UploadDataForm';
import FormStepper, { FormStepperButtons } from './FormStepper';



const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: 4,
    padding: '0px 24px',
    margin: '0 auto',
    maxWidth: 780,
  },
  formContainer: {
    height: 380,
    padding: 24,
  }
}));


export default function TrialRegisterView(props) {
  const classes = useStyles();
  const { user, setDocumentTitle } = props;

  React.useEffect(() => setDocumentTitle('3S | Free Trial'))

  // ----------------------------------------------------------------------- //
  // -------------------------------- State -------------------------------- //
  // ----------------------------------------------------------------------- //
  const [company, setCompany] = React.useState('');
  const [project, setProject] = React.useState('');
  const [releases, setReleases] = React.useState([]);

  const [companyError, setCompanyError] = React.useState('');
  const [projectError, setProjectError] = React.useState('');
  const [releasesError, setReleasesError] = React.useState('');

  const [disableNext, setDisableNext] = React.useState(false);
  const [membershipExpires, setMembershipExpires] = React.useState('');

  const formProps = {
    company: company,
    project: project,
    releases: releases,
    companyError: companyError,
    projectError: projectError,
    releasesError: releasesError,
    membershipExpires: membershipExpires,
    handleCompanyChange: (e) => {
      setCompany(e.target.value);
      setCompanyError('');
    },
    handleProjectChange: (e) => {
      setProject(e.target.value);
      setProjectError('');
    },
    handleReleasesChange: (updatedReleases) => {
      setReleases(updatedReleases.map(x => x.trim()));
      setReleasesError('');
    },
  };

  React.useEffect(async () => {
    if (user && user.attributes['custom:company']) {
      setCompany(user.attributes['custom:company']);
    }
  }, [user]);



  // ----------------------------------------------------------------------- //
  // ------------------------- Form Event Handlers ------------------------- //
  // ----------------------------------------------------------------------- //
  const [currentStep, setCurrentStep] = React.useState(0);
  const handlePreviousStep = () => setCurrentStep(currentStep - 1);

  const handleCompanySubmit = async () => {
    setCompanyError('');

    // Check company name isn't blank.
    if (company.trim().length === 0) {
      setCompanyError('Company name cannot be blank.');
      return;
    }

    // Check company doesn't already exist.
    const token = user.signInUserSession.idToken.jwtToken;

    const url = `${process.env.REACT_APP_API_URL}/companies`;
    const params = { name: company.trim() };
    const headers = { Authorization: token };

    return axios.get(url, {headers, params}).then(
      response => {
        if (response.data.total_count > 0) {
          setCompanyError('Company name already registered.');
        }
        else {
          setCurrentStep(currentStep + 1);
        }
      },
      error => {
        setCompanyError('An error occurred.');
      }
    )
  }

  const handleProjectSubmit = () => {
    let error = false;
    setProjectError('');
    setReleasesError('');

    // Check project name isn't blank.
    if (project.trim().length === 0) {
      setProjectError('Project name cannot be blank.');
      error = true;
    }

    // Check at least one release has been included.
    if (releases.length === 0) {
      setReleasesError('You must include at least one release.');
      error = true;
    }

    if (!error) {
      setCurrentStep(currentStep + 1);
    }
  }

  const handleStartTrialSubmit = async () => {
    setDisableNext(true);
    const token = user.signInUserSession.idToken.jwtToken;

    const url = `${process.env.REACT_APP_API_URL}/companies/trial`;
    const data = { 
      company: company.trim(),
      project: project.trim(),
      releases: releases.join(','),
    };

    const headers = { Authorization: token };

    await axios.post(url, data, {headers}).then(
      response => {
        setMembershipExpires(response.data.membership_expires);
        setCurrentStep(currentStep + 1);
        setDisableNext(false);
      },
      error => {
        console.log(error);
        setDisableNext(false);
      }
    )

    try {
      const session = await Auth.currentSession();
      user.refreshSession(session.refreshToken, (err, session) => console.log('Token Refreshed'));
    }
    catch (e) {
      console.log('Unable to Refresh Token', e);
    }
  }


  // ----------------------------------------------------------------------- //
  // ---------------------------- Misc + Render ---------------------------- //
  // ----------------------------------------------------------------------- //
  const steps = [
    { name: 'Company', component: CompanyForm, nextText: 'Next', handleSubmit: handleCompanySubmit },
    { name: 'Project', component: ProjectForm, nextText: 'Next', handleSubmit: handleProjectSubmit },
    { name: 'Start Trial', component: SubmitForm, nextText: 'Start Trial', handleSubmit: handleStartTrialSubmit },
    { name: undefined, component: UploadDataForm, nextText: 'Upload Defect Data', handleSubmit: () => window.location.href = process.env.REACT_APP_START_TRIAL_NEXT_URL },
  ];

  const Form = steps[currentStep].component;

  return (
    <React.Fragment>
      <PageTitle>Two Month Free Trial</PageTitle>

      <Card className={classes.card}>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormStepper steps={steps} currentStep={currentStep} />
          <Divider />

          <div className={classes.formContainer}>
            <Form {...formProps} />
          </div>

          <Divider />
          <FormStepperButtons
            steps={steps}
            currentStep={currentStep}
            totalSteps={steps.length}
            onPrevious={handlePreviousStep}
            onSubmit={steps[currentStep].handleSubmit}
            disableNext={disableNext}
          />
        </form>
      </Card>
    </React.Fragment>
  )
}

