import React from 'react';

import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';

import PasswordField from '../components/form/PasswordField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';

export default function NewPassword(props) {
  const { 
    user,
    setUser,
    password,
    passwordError,
    setPasswordError,
    handlePasswordChange,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();

  React.useEffect(() => setDocumentTitle('3S | Reset Password'))

  const handleSubmit = async () => {
    // Validation.
    if (!password) {
      setCognitoError('Password Required');
      return;
    }
    if (passwordError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      const newUser = await Auth.completeNewPassword(user, password);
      setUser(newUser);
    }
    catch (error) {
      const { message } = error;
      setCognitoError(message);
    }
    setSubmitting(false);
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <Typography>
        Please enter your new password.
      </Typography>
      <br/>
      <PasswordField value={password} error={passwordError} onChange={handlePasswordChange} autoFocus={true} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Set Password" />
    </form>
  )
}
