import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { techReferences } from './references';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { primaryLight, primaryLightest, secondaryMain, textPrimary } from '../../constants/colors';
import { technicalReferencesPath } from '../../constants/routes';


const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid silver',
    background: primaryLightest,
  },
  ul: {
    padding: '0px 12px',
    listStyleType: 'none',
    // border: '1px solid grey',

  },
  li: {
    padding: '6px 12px',
    borderBottom: '1px solid gainsboro',
    // borderTop: 'none',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:hover': {
      background: primaryLight,
      cursor: 'pointer',
    }
  },
  liActive: {
    fontWeight: 'bold',
    color: secondaryMain,
  }
}));

export default function ReferenceNav(props) {
  const classes = useStyles();
  const { selectedReference } = props;
  const history = useHistory();

  return (
    <Paper className={classes.root}>
      <ul className={classes.ul}>
        <li 
          className={clsx(classes.li, { [classes.liActive]: selectedReference === undefined })} 
          onClick={() => history.push(`${technicalReferencesPath}`)}
        >
            Reference Index
        </li>
        { techReferences.map((r) => (
          <li 
          className={clsx(classes.li, { [classes.liActive]: r.navName === selectedReference })} 
          onClick={() => history.push(`${technicalReferencesPath}/${r.urlName}`)}
        >
            {r.navName}
        </li>
        ))}
      </ul>
    </Paper>
  )
}
