import React, { useState } from 'react';
import PageTitle from '../../common/headings/PageTitle';
import { PageCard } from '../../common/components/Layout';
import { Grid, Typography } from '@mui/material';

import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import styled from '@emotion/styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Column = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Section = styled.div`
  padding: 6px 0px;
`;

const Paragraph = styled.div`
  padding: 6px 0px;
`;

const CardElement = styled.div`
  width: 100%;

  background: #FFFFFF;
  box-shadow: 0px 52.4321px 54.4487px rgba(65, 62, 101, 0.11), inset 0px -5px 0px #C32F5D;
  border-radius: 26px;
  align-content: center;

  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Card = (props) => {
  const { name, title } = props;

  return (
    <CardElement>
      <Typography variant='h6' align='center' paddingBottom={2}>
        {name}
      </Typography>

      <Typography align='center' paddingBottom={2}>
        {title}
      </Typography>
    </CardElement>
  ); 
}

export default function AboutUsView(props) {
  const { setDocumentTitle } = props;
  
  React.useEffect(() => setDocumentTitle('3S | About Us'))

  return (
    <React.Fragment>
      <PageTitle>About Us</PageTitle>
      <PageCard>
        <Section>
          <Paragraph>
            Here at Sakura Software Solutions (3S), we are a premier provider for high standard
            Software Quality Assurance services. Our team is comprised of vetted experts with 
            extensive project experience and a true commitment to quality. We have 
            introduced our revolutionary STAR service to move past spreadsheets and 
            traditional methods of analysis while streamlining a way for companies to easily 
            communicate the bottom-line message of acceptable quality to anyone from 
            developers to executives. 
          </Paragraph>
        </Section>

        <Section>
          <Typography variant="h6">What Is STAR?</Typography>
          <Paragraph>
            STAR is a thoroughly tested and proven tool that improves the accuracy of defect 
            prediction like never seen before.  By using our service, we ensure that companies 
            with in-house or subcontracting software development will achieve high-quality 
            software deliveries on-time.
          </Paragraph>
          <Paragraph>
            Our cloud-based, real-time service implements zero touch automation and several 
            breakthrough analytics with simple visualization. We offer new what-if scenarios 
            that interactively quantify quality improvement through corrective actions & 
            prediction stability charts to back up our models.  User inputs are quick and easy to 
            update, by simply uploading software defect data and entering delivery dates. This 
            cloud-based service is available for customers all over the world, and can be fully 
            customized and implemented to suit your needs. 
          </Paragraph>
        </Section>

        <Section>
          <Typography variant="h6">The Team</Typography>
          <SizeMe>
            {({ size }) => (
              <Document
                file={`${process.env.PUBLIC_URL}/pdfs/ko_bio.pdf`}
              > 
                <Page pageNumber={1} width={size.width ? size.width: 1}/>
              </Document>
            )}
          </SizeMe>
        </Section>

        <Section>
          <Grid container spacing={4} justifyContent='center'>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Kazuhira Okumoto, Ph. D.' title='Chief Executive Officer'/>
            </Column>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Rashid Mijumbi, Ph. D.' title='Chief Technical Advisor'/>
            </Column>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Rory Harpur' title='Chief Technical Officer'/>
            </Column>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Joe Good' title='Senior Software Consultant'/>
            </Column>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Michael Okumoto' title='Strategic Marketing Lead'/>
            </Column>
            <Column item xs={12} sm={6} md={4}>
              <Card name='Andrew Raposo' title='Software Consultant'/>
            </Column>
          </Grid>
        </Section>

        <Section>
          <Typography variant="h6">Our Story</Typography>
          <Paragraph>
            Spending his entire career at Nokia Bell Labs, Kazu was surrounded by software 
            quality assurance practices and advancements, and would develop a first-of-its-kind
            tool in software reliability engineering, BRACE, that Nokia still uses companywide 
            today.  Stuck behind corporate red tape and using traditional methods, BRACE, was 
            never able to reach its full potential and adapt to the real needs of software project 
            managers.  After retiring in 2020, Kazu was proud of the tool that he had left behind
            but felt like his work was not done.  
          </Paragraph>
          <Paragraph>
            Realizing that major enhancements could still be applied, a new company, Sakura 
            Software Solutions (3S), was born.  An initial team was formed with two outstanding
            individuals, Rashid & Rory, whom were previously a part of the Nokia family in 
            Dublin, Ireland to tackle this challenge. 3S introduced an enhanced version of the 
            BRACE tool, STAR, which implements zero-touch automation of defect prediction 
            and corrective actions with state-of-the-art visualization GUI. 
          </Paragraph>
          <Paragraph>
            Today, the mission continues through collaboration with experts from industry and 
            academia to advance and improve STAR for real-world application in the field today.
          </Paragraph>
        </Section>
      </PageCard>
    </React.Fragment>
  )
}
