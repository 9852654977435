import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function OneDotZeroEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 1.0 Release Notes</h2>

      <p>
        STAR is a real-time interactive tool, which implements zero-touch automation for defect prediction and the quality impact of corrective actions with state-of-the-art user interface. It’s a first-of-its-kind approach in software quality assurance. It will help development and test managers to make an informed decision regarding the readiness for high quality delivery of a software product.
      </p>
      <p>
        <b>STAR 1.0</b> is the first release that we are making available for end user trial (instructions on how to trial can be found in the accompanying release documentation).
      </p>
      <p>
        This release introduces an enhanced version of GUI. It significantly improves user friendliness and simplifies the Executive Summary view. It also enhances the accuracy of defect prediction in core engine.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>
      
      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>GUI:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>New GUI:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Menu items have been rearranged for consistency, e.g., the project & release items have been moved to the top left of the page and the component and severity items are combined into one box, called Defect Filters, shown on the top right of the page.
                </li>
                <li className={classes.li}>
                  Menu items on the left are now hidden but they will show up as the cursor moves to the icons on the left. Once a selection on the menu is made, it will go away. This will provide more space for displaying metrics tables and charts.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>New executive summary:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  It now shows defect arrival / closure / open only for simplicity. This page highlights the current quality assessment based on actual and predicted data. A separate new view is created for corrective actions.
                </li>
                <li className={classes.li}>
                  90% confidence limits for open defect curve are provided. This provides an idea about the accuracy of prediction based on current data.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>New corrective actions:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  It introduces a menu to select options: Delay D1 / D2 and add developers.
                </li>
                <li className={classes.li}>
                  For each selected option, it will generate tables showing new %residual and %open. It also shows charts on arrival / closure / open curves with additional new D1 & D2 dates and new closure & open curves. It helps visualize the impact of actions.
                </li>
                <li className={classes.li}>
                  Arrival / closure: It provides weekly view of defect arrival and closure curves with actual and prediction.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Core engine:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Enhanced the implementation of prediction algorithm to improve accuracy and robustness of prediction.
            </li>
            <li className={classes.li}>
              Introduced additional post-processing to provide closure and open predictions for corrective actions as mentioned above.
            </li>
          </ul>
        </li>
      </ul>
      
      <p>
        Many of the enhancements introduced in <b>STAR 1.0</b> resulted from user feedback. We will continue to improve STAR, incorporating user feedback. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}

export function OneDotZeroJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 1.0 リリースノート</h2>

      <p>
        STAR は高機能ソフトウェア品質管理サービスをウェブ上でリアルタイムで提供します。従来の ソフトウェア信頼性モデルと先端コンピューター技術を融合し, 多重バグ曲線を自動的に生成す る画期的なアルゴリズムを導入します。納期に向け品質向上へ役立つべく完全自動化されたバ グ予測解析と是正措置の定量化を提供します。
      </p>
      <p>
        STAR 1.0 はトライアル用の初版です。セキュリティ設定のためトライアル登録が必要です。 <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> から直接3Sにご連絡ください。
      </p>
      <p>
        このリリースでは新しく改良されたユーザーインターフェースを導入しました。簡素化と使いやすさを立証します。同時に、バグ予測解析の精度を一層と改良しました。
      </p>
      <p>
        主な改善項目は以下の通りです。
      </p>
      
      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>GUI:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>New GUI:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  メニュー項目を一貫性のため調整しました。プロジェクトとリリースは 左上に、コンポーネントと重要度は一つのメニューに統合し “DefectFilter” と呼び右上に移しました。
                </li>
                <li className={classes.li}>
                  左側のメニューは平素はアイコンだけ見えるようにしており、必要に応じてカーサを左側のアイコンに移動すればメニューが自動的に見えます。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>New executive summary:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  現在のデーターの解析結果を基にした品質保証の結果を集約するために、是正措置の定量化は別のレポート項目としました。ここでは、バグ検出数、修正数、未修正数の予測曲線と実データのグラフを提供します。納期時でのバグ未検出数と未修正数の予測値は表でまとめています。目標値と比較することができます。ここでの目標値は過去の経験から定めています。べっとの値があれば自由に変更可能です。
                </li>
                <li className={classes.li}>
                  未修正数の予測曲線に90%の信頼度での上限・下限曲線を追加しました。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>New corrective actions:</b>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  新しいメニューを導入して是正措置の定量化は2つのオプションが選べるようにしました。納期時を遅らせることとバグ修正者数を増加することです。
                </li>
                <li className={classes.li}>
                  各オプションにあるスライダーを使っててきとうな値を選べば新しいバ グ未検出数と未修正数が即時に計算されます。同時にバグ検出数、修正 数、未修正数の予測曲線と実データのグラフを追加して、インパクトが 分かりやすくなります。
                </li>
                <li className={classes.li}>
                  Arrival/Closure: 週ごとのバグ検出数と修正数の予測曲線と実データのグラフを提 供します。
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Core engine:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              アルゴリズムを改良しバグ予測解析の精度と自動化を一層と改善しました。
            </li>
            <li className={classes.li}>
              ポストプロセスの一環として是正措置のオプションの選択に従って修正数、未修 正数の予測曲線が作成できるようにしました。
            </li>
          </ul>
        </li>
      </ul>
      
      <p>
        ポストプロセスの一環として是正措置のオプションの選択に従って修正数、未修 正数の予測曲線が作成できるようにしました。
      </p>  

      <p>
        <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>
      </p>


    </React.Fragment>
  )
}
