import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '12px 20%',
    fontWeight: 'bold',
    width: '60%',
  },
}));

function EmailField(props) {
  const classes = useStyles();
  const { label, disabled } = props;

  const submitButtonProps = {
    color: "secondary",
    variant: "contained",
    type: "submit",
    className: classes.root,
    disabled: disabled,
  };

  return (
    <Button {...submitButtonProps}>
      {label}
    </Button>
  )
}

export default EmailField;
