import React from 'react';
import { useHistory } from "react-router"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import validator from 'validator';

import AuthContainer from './components/AuthContainer';
import SignIn from './views/SignIn';
import SignUp from './views/SignUp';
import ForgotPasswordPrompt from './views/ForgotPasswordPrompt';

export default function AuthView(props) {
  const { user, setUser, setDocumentTitle } = props;
  const history = useHistory();
  
  const [loading, setLoading] = React.useState(true);

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');

  React.useEffect(() => {
    if (user) {
      let next = new URLSearchParams(window.location.search).get('next');

      if (!next) {
        next = process.env.REACT_APP_DASHBOARD_URL;
      }

      if (next.toLowerCase().startsWith("http")) {
        window.location.href = next;
      }

      else {
        history.push({
          pathname: next,
        }); 
      }   
    }

    else if (user === false) {
      setLoading(false);
    }
  }, [user]);

  const handleEmailChange = (event) => {
    const { value } = event.target;
    const emailError = validator.isEmail(value) ? '' : 'Must be a valid email address.';
    setEmailError(emailError);
    setEmail(value.toLowerCase());
  }

  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    const passwordError = (value.length >= 6) ? '' : 'Must be at least 6 characters.';
    setPasswordError(passwordError);
    setPassword(value);
  }

  const viewProps = {
    email: email,
    setEmail: setEmail,
    emailError: emailError,
    setEmailError: setEmailError,
    handleEmailChange: handleEmailChange,
    password: password,
    setPassword: setPassword,
    passwordError: passwordError,
    setPasswordError: setPasswordError,
    handlePasswordChange: handlePasswordChange,
    setUser: setUser,
    setDocumentTitle: setDocumentTitle,
  };

  return (
    <React.Fragment>
      { loading === false && (
        <Switch>
          <Route exact path="/login">
            <AuthContainer title={'Sign In'}>
              <SignIn {...viewProps} />
            </AuthContainer>
          </Route>

          <Route exact path="/register">
            <AuthContainer title={'Sign Up'}>
              <SignUp {...viewProps} />
            </AuthContainer>
          </Route>

          <Route exact path="/reset-password">
            <AuthContainer title={'Reset Password'}>
              <ForgotPasswordPrompt {...viewProps} />
            </AuthContainer>
          </Route>
        </Switch>
      )}
    </React.Fragment>
  )
}


