import React from 'react';
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Field from '../components/form/Field';
import EmailField from '../components/form/EmailField';
import PasswordField from '../components/form/PasswordField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';
import AuthLink from '../components/form/AuthLink';
import ConfirmSignUp from './ConfirmSignUp';
import { SignInLink } from './SignIn';

export default function SignUp(props) {
  const {
    email,
    emailError,
    setEmailError,
    handleEmailChange,
    password,
    passwordError,
    setPasswordError,
    handlePasswordChange,
    setUser,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();
  const [requestConfirmation, setRequestConfirmation] = React.useState(false);

  React.useEffect(() => setDocumentTitle('3S | Register'))

  const [fullName, setFullName] = React.useState('');
  const handleFullNameChange = (event) => setFullName(event.target.value);

  const [company, setCompany] = React.useState('');
  const handleCompanyChange = (event) => setCompany(event.target.value);

  const handleSubmit = async () => {
    // Validation.
    if (emailError || passwordError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      const { user, userConfirmed } = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
            name: fullName,
            email: email,
            'custom:company': company,
        }
      });

      if (userConfirmed) {
        setUser(user);
      }

      else {
        setRequestConfirmation(true);
      }
    }
    catch (error) {
      const { message } = error;
      setCognitoError(message);
    }
    setSubmitting(false);
  }

  if (requestConfirmation) {
    return <ConfirmSignUp {...props} />
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <Field value={fullName} autoComplete="name" label="Full Name" required={true} autoFocus={true} onChange={handleFullNameChange} />
      <Field value={company} autoComplete="organization" label="Company" onChange={handleCompanyChange} />
      <EmailField value={email} error={emailError} onChange={handleEmailChange} />
      <PasswordField value={password} error={passwordError} onChange={handlePasswordChange} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Sign Up" />
      <SignInLink label='Already have an account? Sign In' />
    </form>
  )
}


export function SignUpLink() {
  const location = useLocation();
  const history = useHistory();

  const onClick = () => {
    history.push({
      pathname: '/register',
      search: location.search,
    });
  }

  return (
    <AuthLink label='Register New Account' onClick={onClick} />
  )
}
