import React from "react";
import styled from '@emotion/styled';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import { AspectRatio } from 'react-aspect-ratio';
import { withSize } from 'react-sizeme';
import { Typography } from '@mui/material';
import { videos } from '../../videos/videos';

const Container = styled.div`
  background-color: #1C2945;
  border-radius: 40px;
  padding: 15px;
`;

const VideoRoot = styled.div`
  overflow: hidden;
  background-color: black;
  border-radius: 25px;
`;

const BaseTypography = styled(Typography) `
  color: white;
`;

const HeaderTypography = styled(BaseTypography)`
  font-size: 2.75rem;
`;

const BodyTypography = styled(BaseTypography)`
  font-size: 1.25rem;
`;

const YoutubeSlide = (props) => {
  const { url, isSelected, size } = props;

  const [playing, setPlaying] = React.useState(false);

  React.useEffect(() => {
    if (!isSelected) {
      setPlaying(false);
    }

  }, [isSelected]);

  const height = size.width / 16 * 9;

  return (
    <ReactPlayer width="100%" height={height} url={url} controls={true} playing={playing} 
      onPause={() => setPlaying(false)} onPlay={() => setPlaying(true)} />
  );
};

const SizedYoutubeSlide = withSize()(YoutubeSlide);

export const YouTubeCarousel = () => {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

  const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
        const videoId = getVideoId(item.props.url);
        return <img src={getVideoThumb(videoId)} />;
    });

  return (
    <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb} showThumbs={false}>
      { videos.map((video) => (
        <SizedYoutubeSlide key={`youtube-${video.urlName}`} url={video.url} />
      ))}
    </Carousel>
  );
};

const MainVideo = (props) => {
  return (
    <Container>
      <HeaderTypography variant='h3' align='center' paddingTop={3} paddingBottom={2}>
        Find the answers you have been looking for with STAR
      </HeaderTypography>
      <BodyTypography variant='body1' marginBottom={3} paddingX={2}>
        •	When will the product be ready to be delivered, and how many defects shall we find? <br/>
        • How many additional defects could we find from now to delivery? <br/>
        • Which of our software components are most defective? <br/>
        • What can we do to improve the software quality? And what if we increased the number of developers or delayed the delivery? <br/>
        • What if we don't have defect data early on in development? How can we make early quality estimates? <br/>
      </BodyTypography>

      <VideoRoot>
        <YouTubeCarousel />
      </VideoRoot>
    </Container>
  );
}

export default MainVideo;
