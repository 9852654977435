import makeStyles from '@mui/styles/makeStyles';

export const useReleaseNotesStyles = makeStyles((theme) => ({
    root: {
      textAlign: 'justify',
      textJustify: 'inter-word',
    },
    // ul: {
    //   padding: '12px 24px'
    // },
    li: {
      padding: '6px 0px'
    },
  }));