// export const primaryMain = '#7ebcc0';
// export const primaryDark = '#56a7ad';
// export const primaryMedium = "#c5e2e2";
// export const primaryLight = '#e7f3f3';
// export const primaryLightest = '#f8fcfc';

export const primaryMain = '#1c2945';
export const primaryDark = '#0b111b';
export const primaryMedium = "#6589c0";
export const primaryLight = '#d4deee';
export const primaryLightest = '#f4f7fb';

export const secondaryMain = '#c64a67';
export const secondaryDark = '#c22d5c';
export const secondaryDarker = '#8F0A45';

export const background = '#d4d7de';
// export const background = '#ffffff';
export const backgroundDark = '#c9cdd5';
export const backgroundLight = '#f1f2f4';

export const textPrimary = '#202c48';

// export const background = 'white';
// export const backgroundDark = '#d0c9cb';
// export const backgroundLight = '#fefefe';

export const arrivalLineColor = 'red';
export const closureLineColor = 'green';
export const openLineColor = 'blue';
export const overlayLineColor = 'orange';
export const targetLineColor = '#0000FF33';

export const redCell = 'rgba(255, 0, 0, 0.25)';
export const greenCell = 'rgba(255, 255, 0, 0.5)';
export const yellowCell = 'rgba(0, 255, 0, 0.5)';

export const grey = '#a3a6ad';
export const darkGrey = '#71747d';