import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
    color: 'red',
    fontSize: 14,
    // fontWeight: 'bold',
  },
}));

function EmailField(props) {
  const classes = useStyles();
  const { error } = props;

  return (
    <Typography className={classes.root} align='center'>
      {error}
    </Typography>
  )
}

export default EmailField;
