import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { primaryLight } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    border: '1px solid black',
    paddingLeft: 12,
    paddingRight: 8,
    marginTop: 8,
    marginBottom: 8,
    background: primaryLight,
    borderRadius: 12,
  },
}));


export default function DocumentationView(props) {
  const classes = useStyles();

  const { selectedFile, enFile, jpFile, onChange } = props;

  return (
    <Box mx={2} className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="gender"
          name="controlled-radio-buttons-group"
          value={selectedFile}
          onChange={onChange}
        >
          <FormControlLabel value={enFile} control={<Radio size="small" />} label={<Box component='div' fontSize={12} fontWeight='bold'>English</Box>} />
          <FormControlLabel value={jpFile} control={<Radio size="small" />} label={<Box component='div' fontSize={12} fontWeight='bold'>日本語</Box>} />
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
