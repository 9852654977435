import * as React from 'react';
import styled from '@emotion/styled';
import { Button, Container, Typography } from '@mui/material';
import { secondaryDarker } from '../../../constants/colors';

const SectionContainer = styled.div`
  background-image: url('/assets/landing/wave-texture-right.svg');
  background-repeat: no-repeat;
  background-position: right;
  min-height: 610px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentContainer = styled(Container)`
  perspective-origin: 0%;
  perspective: min(20vw, 259px);
`;

const RedTrapezoid = styled.div`
  width: 94%;
  height: 330px;
  transform: rotateY(-1.5deg);
  border-radius: 40px;
  background: linear-gradient(90deg, #7F003E -2.58%, #8D0944 3.5%, #A91D51 18.7%, #C02C5B 34.92%, #D03762 52.15%, #D93D67 71.41%, #DC3F68 98.77%), linear-gradient(0deg, #00A7CC 0%, #00DFDA 85.61%), linear-gradient(17.73deg, #D83A65 6.75%, #AA1E52 76.69%);
`;

const InnerContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const DemoButton = styled(Button)`
  width: 230.8px;
  height: 67.22px;

  background: white;
  box-shadow: 0px 19.6981px 45.6165px rgba(212, 58, 101, 0.37), inset 0px 2.75054px 13.7527px rgba(255, 255, 255, 0.31);
  border-radius: 72.8246px;
  color: ${secondaryDarker};
  font-size: 21px;
  text-transform: none;

  &:hover {
    background: white;
  }
`;

const CallToAction = (props) => {
  return (
    <SectionContainer>

      <ContentContainer maxWidth='lg'>
        <RedTrapezoid />
        <InnerContent>
          <Typography variant='h3' color='white' align='center'>
            Stop guessing. Let STAR guide you
          </Typography> 
          <DemoButton href={`${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary`}>Demo STAR Now</DemoButton>
        </InnerContent>
      </ContentContainer>
    </SectionContainer>
  ); 
}

export default CallToAction;
