import React from 'react';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import Field from '../components/form/Field';
import PasswordField from '../components/form/PasswordField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';

export default function ForgotPassword(props) {
  const {
    email,
    password,
    passwordError,
    setPasswordError,
    handlePasswordChange,
    setUser,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();

  const [code, setCode] = React.useState('');
  const handleCodeOnChange = (event) => setCode(event.target.value);
  
  React.useEffect(() => setDocumentTitle('3S | Forgot Password'))

  const handleSubmit = async () => {
    // Validation.
    if (!password) {
      setCognitoError('Password Required');
      return;
    }
    if (passwordError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      const user = await Auth.signIn(email, password);
      setUser(user);
    }
    catch (error) {
      const { message } = error;
      setCognitoError(message);
    }
    setSubmitting(false);
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <Typography>
        We have sent an email containing your reset code to <b>{email}</b>
      </Typography>
      <br/>
      <Field value={code} label="Code" onChange={handleCodeOnChange} autoFocus={true} />
      <PasswordField value={password} error={passwordError} onChange={handlePasswordChange} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Sign In" />
    </form>
  )
}
