import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {primaryMedium, secondaryMain} from '../../../constants/colors';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: '0 auto',
    marginTop: 48,
    width: 480,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  title: {
    color: 'white',
    background: `linear-gradient(45deg, ${secondaryMain} 0%, ${primaryMedium} 100%)`,
    padding: 18,
    marginBottom: 12,
    // border: `1px solid ${primaryMain}`,
  },
  titleText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 14,
    margin: 0,
    display: 'inline-block'
  },
  image: {
    width: 24,
    marginTop: -2,
    float: 'right',
  },
}));

export default function AuthContainer(props) {
  const classes = useStyles();
  const { title, children } = props;

  return (
    <Box elevation={12}>
      <Card className={classes.card}>
        <Box className={classes.title}>
          <Typography className={classes.titleText} gutterBottom variant="h2">
            {title}
          </Typography>

          <img className={classes.image} src={process.env.PUBLIC_URL + '/icon.png'} />
        </Box>

        <CardContent className={classes.content}>
          { children }
        </CardContent>
      </Card>
    </Box>
  )
}
