import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';

import PageTitle from '../../common/headings/PageTitle';
import TextSection from './TextSection';
import { primaryLightest, primaryDark } from '../../constants/colors'; 
import { privacyPolicyPath } from '../../constants/routes'; 
 
 
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 920,
    margin: '0 auto',
    marginBottom: 96,
    padding: '12px 12px',
    paddingBottom: 36,
  },
}));

export default function PrivacyPolicyView(props) {
  const classes = useStyles();
  
  const { setDocumentTitle } = props;

  React.useEffect(() => setDocumentTitle('3S | Terms of Service'))

  const text_1 = `
    Please read these Terms of Use (<i>Terms</i>, <i>Terms of Use</i>) carefully before using this 
    website and our STAR tool (the <i>Service</i>) operated by Sakura 
    Software Solutions (3S) LLC (<i>us</i>, <i>we</i>, or <i>our</i>). 
    <br/>
    <br/>     
    Your access to and use of the Service is conditioned on your acceptance of and compliance with 
    these Terms. These Terms apply to all visitors, users and others who access or use the Service. 
    <br/>
    <br/>     
    <b>By accessing or using the Service you agree to be bound by these Terms and accept our 
    separate <a target="_blank" href="${privacyPolicyPath}"> 3S Privacy Policy</a>. If you do 
    not accept both these Terms of Use and the Privacy Policy, you may not access or use the 
    Website, and you must immediately cease accessing or using the Website.</b>
  `;

  const text_2 = `
    We may in the future need to restrict or suspend access to parts (or all) of our Service immediately, 
    without prior notice or liability, for any reason whatsoever, including without limitation if you breach 
    the Terms. However, if we make any material change in the core functionality of the Service, then 
    we will make sure to alert the user by sharing an announcement on our Release Notes page via our 
    webpage and/or by sending you an email. 
  `;

  const text_3 = `
    To initially register to the Service, you shall create a new account for the Service.  By creating an 
    account (“Account”) and registering to the Service you become, either individually or represent your 
    employer or any entity, on behalf of whom you created the Account, a 3S customer (the 
    <i>Customer</i>). The first user of the Account is automatically assigned as the Account administrator 
    (the <i>Admin</i>).  An Admin will have the ability to assign or add-on other users to the Account, who
    will have access to control the use of the Service and their customer data.
  `;

  const text_4 = `
    Customer Data is any data, personal information, or any additional content, that is uploaded, 
    submitted, or made available to the Service by you and or any user and is handled by us on our 
    customer’s behalf.  Customers will retain all rights and control to their customer data in any forms 
    submitted to our service. By using our service, the customer grants us limited access to process, 
    and display the customer data in the Service, to address any technical support issues, and to apply 
    customization requests.  
  `;

  const text_5 = `
    Unless otherwise noted, all materials including without limitation, logos, brand names, images, 
    designs, copyrights, trademarks, patents and other intellectual property rights in and on our website 
    and all content and software located on the site shall remain the sole property of or its licensors. 
    The use of our trademarks, content and intellectual property is forbidden without the express written 
    consent from Sakura Software Solutions LLC. 
    <br/>
    <br/>
    You must not:
    <ul>
      <li>Republish material from our website without prior written consent.</li>
      <li>Sell or rent material from our website.</li>
      <li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
      <li>Redistribute any content from our website, including onto another website.</li>
    </ul>
  `;

  const text_6 = `
    As a part of our Service, we offer a free, no-commitment trial (<i>Trial Service</i>).  All information 
    regarding the Trial Service will be communicated to the user via email or within the Service.  We 
    reserve the right to modify, terminate, and/or limit the Trial Service immediately, without prior notice 
    or liability, for any reason whatsoever, including without limitation if you breach the Terms. 
  `; 
   
  const text_7 = `
    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a 
    revision is material, we will try to provide at least 30 days' notice prior to any new terms taking 
    effect. What constitutes a material change will be determined at our sole discretion.  
  `;
   
  const text_8 = `
    If you have any questions about these Terms, please contact us at <b>info@sakurasoftsolutions.com</b>.
  `;

  return (
    <React.Fragment>
      <PageTitle>Terms of Service</PageTitle>

      <Card className={classes.card}>
        <TextSection text={text_1} />
        <TextSection text={text_2} title='Restricted Access of the Service' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_3} title='Account Registration & Administration' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_4} title='Customer Data' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_5} title='Intellectual Property Rights' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_6} title='Free Trial Service' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_7} title='Changes to Terms' color={primaryLightest} borderColor='silver' />
        <br/>
        <TextSection text={text_8} />
      </Card>
    </React.Fragment>
  )
}


