export const monthlyPricing = {
  term: 'Month',
  starter: '$500',
  basic: '$1,000',
  plus: '$1,200',

  disclaimer: {
    starter: '(Max. 12 Months)',
    basic: '',
    plus: '',
  },

  discounted: {
    starter: '$350',
    basic: '$750',
    plus: '$840',
  },
}

export const yearlyPricing = {
  term: 'Month',
  starter: '$425',
  basic: '$850',
  plus: '$1,020',
  starterAnnual: '$5,100',
  basicAnnual: '$10,200',
  plusAnnual: '$12,240',

  disclaimer: {
    starter: 'Total $5,100 Billed Up Front',
    basic: 'Total $10,200 Billed Annually',
    plus: 'Total $12,240 Billed Annually',
  },

  discounted: {
    starter: '$3,500',
    basic: '$7,500',
    plus: '$8,400',
  },
}

export const ServiceInformation = [
  { 
    sectionName: 'Input',
    features: [
      { 
        feature: 'Milestone Dates (Start/D1/D2)',
        description: 'Project Milestone Dates: \n 1. Project Start Date\n 2. Delivery Date for Trial (D1)\n 3. Delivery Date for Commercial Deployment (D2)',
        leftAlign: true,
        starter: true,
        basic: true, 
        plus: true,
      },
      { 
        feature: 'Defect Data', 
        description: 'Frequently referred to as faults or bugs, a defect represents an error, flaw, or failure in a computer program or system that causes it to produce an incorrect or unexpected result, or to behave in an unintended way.\n\nSample input format will be provided. Contact us for custom data extraction from your database.',
        leftAlign: true,
        starter: true,
        basic: true, 
        plus: true,
      },
      { 
        feature: 'Effort Data', 
        description: 'Data for software development measured in hours.\n\nSample input format will be provided. Contact us for custom data extraction from your database.',
        leftAlign: true,
        starter: false,
        basic: false, 
        plus: true,
      },
    ],
  },
  { 
    sectionName: 'Defect Trend Analysis',
    description: 'Defect Trend Analysis:\nThis menu evaluates the overall software product quality before delivery by analyzing actual defect data.\n\nSTAR leverages defect data to forecast defect detection, resolution timelines, and remaining issues by delivery. We offer various output views for detailed assessment breakdowns, including a corrective actions tab for project guidance.',
    features: [
      { 
        feature: 'Executive summary',
        description: 'Provides a summary of key software quality assurance metrics.',
        starter: true,
        basic: true, 
        plus: true,
      },
      { 
        feature: 'Corrective actions',
        description: 'Corrective Actions:\nSTAR interactively quantifies the quality impact of corrective actions. We currently provide the following.\n — Delaying the delivery date\n — Adding more developers to fix defects\n — Adding more testers to accelerate test progress\n — Content reduction to reduce total defects',
        leftAlign: true,
        starter: true,
        basic: true, 
        plus: true,
      },
      { 
        feature: 'Weekly arrival / closure',
        description: 'Provides a weekly view of arrival and closure defects with actual and prediction.',
        starter: true,
        basic: true, 
        plus: true,
      },
      { 
        feature: 'Defects by component',
        description: 'Provides a breakdown to help identify problematic components.',
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Defects by severity',
        description: 'Provides a breakdown to help focus on critical and major defects.',
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Release over release', 
        description: 'Provides the previous and current release defect data that is normalized by the delivery date. We will provide a visual output for users to compare the Current vs. Previous release data.',
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Prediction Stability', 
        description: 'This showcases the accuracy of the STAR model. It should show that our predictions for the final number of defects in your software on deployment becomes very close to the final actual value many weeks before the actual deployment.',
        starter: true,
        basic: true, 
        plus: true,
      },
    ],
  },
  { 
    sectionName: 'Early Prediction\n(Planning & Testing Phase)',
    description: 'Early Prediction:\nThis menu forecasts software product quality, using past release and effort data, initially without defect data (Planning Phase). As development starts and defect data becomes available (Testing Phase), STAR incorporates the actual data to dynamically update the prediction.\n\nSTAR leverages defect data to forecast defect detection, resolution timelines, and remaining issues by delivery. We offer various output views for detailed assessment breakdowns, including a corrective actions tab for project guidance.',
    features: [
      {
        feature: 'Executive summary', 
        description: 'Provides a summary of key software quality assurance metrics.',
        starter: false,
        basic: false, 
        plus: true,
      },
      {
        feature: 'Corrective actions', 
        description: 'Corrective Actions:\nSTAR interactively quantifies the quality impact of corrective actions. We currently provide the following.\n — Delaying the delivery date\n — Adding more developers to fix defects\n — Adding more testers to accelerate test progress\n — Content reduction to reduce total defects',
        leftAlign: true,
        starter: false,
        basic: false, 
        plus: true,
      },
      {
        feature: 'Early Defect Prediction', 
        description: "Early Defect Prediction:\nPlanning Phase — This menu represents an early defect prediction without actual defect data during the planning phase. It is based on historical data (e.g., effort, sub-features, test cases) from software development and test.\n\nTesting Phase — This menu represents an updated defect prediction with actual defect data using the original prediction curve obtained without actual data.",
        leftAlign: true,
        starter: false,
        basic: false, 
        plus: true,
      },
      {
        feature: 'Effort Data', 
        description: 'This view highlights the correlation between cumulative defects and cumulative effort.\n\nSample input format will be provided. Contact us for custom data extraction from your database.',
        leftAlign: true,
        starter: false,
        basic: false, 
        plus: true,
      },
      {
        feature: 'Prediction Stability', 
        description: 'This showcases the accuracy of the STAR model. It should show that our predictions for the final number of defects in your software on deployment becomes very close to the final actual value many weeks before the actual deployment.',
        starter: false,
        basic: false, 
        plus: true,
      },
    ],
  },
  // { 
  //   sectionName: 'Early Defect Prediction w/ Actual Defects',
  //   features: [
  //     {
  //       feature: 'Exclusive summary', 
  //       starter: false,
  //       basic: false, 
  //       plus: true,
  //     },
  //     {
  //       feature: 'Corrective actions', 
  //       starter: false,
  //       basic: false, 
  //       plus: true,
  //     },
  //     {
  //       feature: 'Early prediction', 
  //       starter: false,
  //       basic: false, 
  //       plus: true,
  //     },
  //     {
  //       feature: 'Prediction stability', 
  //       starter: false,
  //       basic: false, 
  //       plus: true,
  //     },
  //     {
  //       feature: 'Effort data', 
  //       starter: false,
  //       basic: false, 
  //       plus: true,
  //     },
  //   ],
  // },
  { 
    sectionName: 'Support Features',
    features: [
      {
        feature: '24X7 online service', 
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Unlimited access', 
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Unlimited Users', 
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Unlimited releases', 
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Technical support', 
        starter: true,
        basic: true, 
        plus: true,
      },
      {
        feature: 'Software maintenance', 
        starter: true,
        basic: true, 
        plus: true,
      },
    ],
  },
] 