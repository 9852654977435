import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 18,
  },
}));

function PasswordField(props) {
  const classes = useStyles();
  const { value, error, onChange, autoFocus = false  } = props;

  const textFieldProps = {
    value: value,
    onChange: onChange,
    autoFocus: autoFocus,
    required: true,
    type: "password",
    variant: "outlined",
    label: "Password",
    placeholder: "Password",
    error: error,
    helperText: error,
    className: classes.root,
  };

  return (
    <TextField {...textFieldProps} />
  )
}

export default PasswordField;
