import React, { useState } from "react";
import styled from '@emotion/styled';
import { Box, Grid, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel'
import useMediaQuery from '@mui/material/useMediaQuery';

const Container = styled.div`
  width: 100%;
  background-color: #1C2945;
  border-radius: 40px;
  padding: 15px;
`;

const VideoRoot = styled.div`
  overflow: hidden;
  background-color: black;
  border-radius: 25px;
  height: 300px;
`;

const VideoFrame = styled.iframe`
  border: none;
  display: block;
`;

const VideoCarousel = (props) => {
  const embedIds = [
    'uC5Mvnizg5I',
    '5jM0xajiaF0',
    '0sEzGhlce3o',
  ];
  const [index, setIndex] = useState(0);
  const next = () => {
    let nextIndex = index + 1;
    if (nextIndex >= embedIds.length) {
      nextIndex = 0;
    }
    setIndex(nextIndex);
  };

  const prev = () => {
    let prevIndex = index - 1;
    if (prevIndex < 0) {
      prevIndex = embedIds.length - 1;
    }
    setIndex(prevIndex);
  };

  return (
    <Container>
      <Typography variant="h4" color="white" align="center">Our Products</Typography>
      <Carousel autoPlay={false} indicators={false} next={next} prev={prev}>
        <Videos index={index} embedIds={embedIds} />
      </Carousel>
    </Container>
  );
}

const Videos = (props) => {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { index, embedIds} = props;
  let nextIndex = index + 1;
  if (nextIndex >= embedIds.length) {
    nextIndex = 0;
  }
  const visibility = (i) => {
    return (index === i || (nextIndex === i && !isXs)) ? 'block' : 'none'; 
  };
  
  const direction = nextIndex < index ? 'row-reverse' : 'row';
  
  return (
    <Grid container spacing={2} direction={direction}>
      {
        embedIds.map((embedId, i) => (
          <Box component={Grid} item xs={12} sm={6} sx={{ display: visibility(i) }} >
            <Video embedId={embedId} />
          </Box>
        ))
      }
    </Grid>
  ); 
}

const Video = (props) => {
  return (
    <VideoRoot>
      <VideoFrame
        src={`https://www.youtube.com/embed/${props.embedId}`}
        frameBorder="0"
        height="100%"
        width="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </VideoRoot>
  ); 
}

export default VideoCarousel;
