import * as React from 'react';
import styled from '@emotion/styled';
import { HorizontalFlexBox, VerticalFlexBox } from './Layout';
import { textPrimary } from '../../constants/colors';

const IconImage = styled.img`
  height: 72px;
  padding-right: 12px;
`;

const BaseText = styled.span`
  text-decoration: none;
  text-transform: capitalize;
  text-align: center;
  padding: 0;
  color: textPrimary;
`;

const TopLine = styled(BaseText)`
  line-height: 24px;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 6px;
  padding-top: 20px;
  padding-left: 4px;
`;

const BottomLine = styled(BaseText)`
  font-size: 13px;
`;

const Logo = (props) => {
  return (
    <HorizontalFlexBox>
      <IconImage src={process.env.PUBLIC_URL + '/icon.png'} alt='Sakura Logo' />
      <VerticalFlexBox>
        <TopLine>SAKURA</TopLine>
        <BottomLine>SOFTWARE SOLUTIONS</BottomLine>
      </VerticalFlexBox>
    </HorizontalFlexBox>
  );
}

export default Logo;
