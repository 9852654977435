import { OneDotZeroEn, OneDotZeroJp } from './releases/OneDotZero';
import { OneDotOneEn, OneDotOneJp } from './releases/OneDotOne';
import { OneDotTwoEn, OneDotTwoJp } from './releases/OneDotTwo';
import { OneDotThreeEn, OneDotThreeJp } from './releases/OneDotThree';
import { OneDotFourEn, OneDotFourJp } from './releases/OneDotFour';
import { TwoDotZeroEn, TwoDotZeroJp } from './releases/TwoDotZero';
import { TwoDotOneEn, TwoDotOneJp } from './releases/TwoDotOne';

export const en = 'en';
export const jp = 'jp';

export const releaseNotes = [
  { name: '1.0', en: OneDotZeroEn, jp: OneDotZeroJp },
  { name: '1.1', en: OneDotOneEn, jp: OneDotOneJp },
  { name: '1.2', en: OneDotTwoEn, jp: OneDotTwoJp },
  { name: '1.3', en: OneDotThreeEn, jp: OneDotThreeJp },
  { name: '1.4', en: OneDotFourEn, jp: OneDotFourJp },
  { name: '2.0', en: TwoDotZeroEn, jp: TwoDotZeroJp },
  { name: '2.1', en: TwoDotOneEn, jp: TwoDotOneJp },
]