import React from 'react';
import { useHistory } from "react-router"
import { registerPath } from '../../constants/routes';

export default function RouteWrapper(props) {
  const history = useHistory();

  const { href, authRequired, user, className, children } = props;

  const handleClick = (e) => {
    e.preventDefault();

    if (authRequired && user === false) {
      history.push({
        pathname: registerPath,
        search: `?next=${href}`,
      });
    }
    else {
      window.location.href = href;
    }
  };

  return (
    <a href={href} className={className} onClick={handleClick}>
      {children}
    </a>
  )
}
