import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 10,
    // color: 'blue',
    padding: 0,
    margin: '0px 20%',
    marginTop: 6,
    fontWeight: 'bold',
    width: '60%',
  },
}));

function AuthLink(props) {
  const classes = useStyles();
  const { label, onClick } = props;

  const submitButtonProps = {
    className: classes.root,
    onClick: onClick,
  };

  return (
    <Button {...submitButtonProps}>
      {label}
    </Button>
  )
}

export default AuthLink;
