import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import PageTitle from '../../common/headings/PageTitle';
import PdfViewer from '../../common/PdfViewer';
import GetAppIcon from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1080,
  },
  header: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: 20
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    fontSize: 20,
  },
}));

export default function StarUseCasesView(props) {
  const classes = useStyles();
  const { setDocumentTitle } = props;

  const file = '/pdfs/3S_STAR_Use_Cases.pdf';
  
  React.useEffect(() => setDocumentTitle('3S | STAR Use Cases'))

  return (
    <React.Fragment>
      <PageTitle>STAR Use Cases</PageTitle>

      <Card className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.header}>
              <IconButton className={classes.link} href={file} download><GetAppIcon /></IconButton>
            </div>
            <PdfViewer file={file} />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}