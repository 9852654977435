import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function OneDotOneEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 1.1 Release Notes</h2> 
      <h3>Rollout started on May 18th, 2021</h3>
      
      <p>
        <b>STAR 1.1</b> introduces an official declaration of our Privacy Policy to ensure protection of our customer data. It improves user friendliness of creating customer projects & releases and uploading defect data when users are ready to start their <i>Two Month Free Trial</i>. This is just the start to an ever-evolving update of the STAR experience. In addition to these updates, we are still working on dozens of other improvements and are looking forward to introducing more features in future updates. Continue reading below to learn more about the most important updates and improvements we’ve worked on.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>

      <ul className={classes.ul}>

        <li className={classes.li}>
          <b>Privacy policy:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Users will view their own data and demo data only.
            </li>
            <li className={classes.li}>
              Users will not see other customers data.
            </li>
            <li className={classes.li}>
              Users can request for a non-disclosure agreement to further protect their proprietary data and information
            </li>
            <li className={classes.li}>
              An official 3S privacy policy is made available in the main page.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Free trial box:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Created a “Free Trial” box in the main page. When a user clicks the box, it will automatically navigate to set up “Company”, “Project” and “Releases” names.
            </li>
            <li className={classes.li}>
              Upon the completion of the setup a user can begin to upload the defect data and specify delivery dates.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>New customer menu:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              A new customer menu was created for users to select your own company. It allows you to select your company or demo only. No other selections are allowed to protect customer proprietary data.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Defect data upload:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              A new menu was added under User Inputs for users to upload the defect data. It navigates your folder system to locate and specify the folder and file names. Once it’s specified, it will automatically upload the data by clicking “OK”.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>New 3S logo and main page look and feel:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              A new logo and look-and-feel was introduced to improve visualization effectiveness.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>New Documentation menu:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              A new documentation menu was introduced to provide description for each topic such as user guide, STAR product overview, STAR system overview, technical references.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Prediction stability:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Prediction stability data is now shown in the “Prediction Stability” view. This showcases the accuracy of the STAR model. It should show that our predictions for the final number of defects in your software on deployment becomes very close to the final actual value many weeks before the actual deployment.
            </li>

          </ul>
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 1.1</b> resulted from User feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}

export function OneDotOneJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 1.1 リリースノート</h2>
      <h3>2021 年 5 月 20 日</h3>
      
      <p>
        STAR 1.1 は顧客データを保護するためのプライバシーポリシーを正式に導入しました。2か月 の無料トライアルの開始準備が出来次第、カスタマーのプロジェクト名およびリリース名の設 定とバグデーターを簡単にアップロードできるようにしました。これは STAR のユーザー経験 を引き続き改善すべきの第一歩です。
      </p>
      <p>
        主な改善項目は以下の通りです。
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          プライバシーポリシーI:
          <ul className={classes.ul}>
            <li className={classes.li}>
              ユーザーは自分のデーターとデモ用のデーターだけ閲覧できます。
            </li>
            <li className={classes.li}>
              ユーザーは他社のデーターは閲覧できません。
            </li>
            <li className={classes.li}>
              ユーザーは必要ならば Non-disclosure Agreement (秘密保持契約)を要求してください。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          無料トライアル アイコン:
          <ul className={classes.ul}>
            <li className={classes.li}>
              無料トライアル用のアイコンを付け加えました。これをクリックすると、STAR の指示に従って入力すれば自動的に ”Company”、“Project”、“Release” の名前が作 成されます。
            </li>
            <li className={classes.li}>
              上記の作成が完了すれば、バグデーターのアップロードのメニューに自動的に移 動します。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          “Customer”メニューを導入:
          <ul className={classes.ul}>
            <li className={classes.li}>
              このメニューを導入により、ユーザーは自分のデーターとデモ用のデーターだけ選択できます。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          バグデーターのアップロード:
          <ul className={classes.ul}>
            <li className={classes.li}>
              バグデーターのアップロード用のメニューを導入して、ユーザーは自分でデータ ーを投入できます。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          新ロゴとルック&フィール
        </li>
        <li className={classes.li}>
          新ドキュメンテーション メニューを導入
        </li>
        <li className={classes.li}>
          Prediction Stability (予測安定性):
          <ul className={classes.ul}>
            <li className={classes.li}>
              予測安定性の目安として予測安定データーを導入しました。これにより、STAR モデルの正確度が一目で把握できます。
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください。
      </p>
    </React.Fragment>
  )
}
