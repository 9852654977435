import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';

const ChartImage = styled.img`
  width: 100%;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  border-radius: 13px;
`;

const HeaderTypography = styled(Typography)`
  margin: 0 auto;
  max-width: 90%;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #f4f8ff;
  border-radius: 40px;
  border: 1px solid #f4f8ff;
  margin-bottom: 120px;
`;

const TextGrid = styled(Grid)`
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
`;

const ImageCard = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
  background: #FFFFFF;
  box-shadow: 0px 52.4321px 54.4487px rgba(65, 62, 101, 0.11), inset 0px -5px 0px #C32F5D;
  border-radius: 26px;
  padding: 20px;
  margin-bottom: -120px;
`;

const HowStarWorks = (props) => {
  return (
    <>
      <Typography variant='h4' align='center' marginBottom={4}>
        How STAR Works
      </Typography>
      <BackgroundContainer>

        <TextGrid container spacing={2}>
          <Box component={Grid} item xs={12} sm={6}>
            <HeaderTypography align='left' paddingY={2}>
              •	Core Engine: Python Scientific Stack <br/>
              •	Databases: PostgresSQL &amp; NoSQL (DynamoDB) <br/>
              •	APIs: Python Flask
            </HeaderTypography>
          </Box>

          <Box component={Grid} item xs={12} sm={6}>
            <HeaderTypography align='left' paddingY={2}>
              •	Infrastructure: AWS &amp; Terraform <br/>
              •	UI: JavaScript ES6 (React) <br/>
              •	Unified Backed: Defect Tracking Systems e.g. Jira
            </HeaderTypography>
          </Box>
        </TextGrid>

        <ImageCard>
          <ImageContainer>
            <ChartImage src={process.env.PUBLIC_URL + '/assets/landing/architecture.png'} alt='System Architecture Diagram' />
          </ImageContainer>
        </ImageCard>
      </BackgroundContainer>
    </>
  ); 
}

export default HowStarWorks;
