import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function OneDotThreeEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 1.3 Release Notes</h2> 
      <h3>Rollout started on October 11th, 2021</h3>
      
      <p>
        <b>STAR 1.3</b> introduces a new <b>Landing Page</b> which is available from <a href="https://sakurasoftsolutions.com/">https://sakurasoftsolutions.com/</a>. This will enable general users to access our STAR dashboard, Documentation, Release Notes, About Us, Contact Us, Terms of Service and Privacy Policy without actually signing in. It also activates several views with actual data including, <b>“Defects by component”</b>, <b>“Defects by severity”</b>, <b>“Prediction Stability”</b> for Leading Data (IPM), and an <b>“Early Prediction”</b> view under Leading Data (IPM).
      </p>
      <p>
        <b>STAR 1.3</b> continues to improve the usability of our STAR service from the customer’s side.
      </p>
      <p>
        Continue reading below to learn more about the most important updates and improvements we’ve worked on.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>

      <ul className={classes.ul}>

        <li className={classes.li}>
          <b>New landing page:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Phase 1</b> of our Landing Page is now available. General users can browse through the STAR dashboard, documentation, release notes, about us, contact us, terms of service and privacy policy without actually signing in.
            </li>
            <li className={classes.li}>
              The <b>dashboard</b> will take you to the STAR main page which is the Executive Summary view.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>New active views:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Defects by component:</b> This view represents actual defect data by component. The table summarizes %Defect and %Open for each component.
            </li>
            <li className={classes.li}>
              <b>Defects by severity:</b> This view represents actual defect data by severity. The table summarizes %Defect and %Open for each severity.
            </li>
            <li className={classes.li}>
              <b>Prediction Stability:</b> This view for Leading Data (IPM) demonstrates how quickly the prediction becomes stable with the leading data.
            </li>
            <li className={classes.li}>
              <b>Early Prediction:</b> This view under Leading Data (IPM) provides two new charts showing early prediction of arrival / closure & open defects with actual data being overlaid. We can now compare the actual defect data vs. early predictions.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Updated User Input Menu – Release config</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Reorganized to fit three items (Release Parameters, Defect Data, and Effort Data) <b>in one view</b>. We have eliminated the need to scroll the page down.
            </li>
            <li className={classes.li}>
              Added a <b>“Start Date”</b> field which represents the start date of defect data, especially designed for each defect prediction without actual defect data. It can be used to control the beginning of the x-axis of all charts.
            </li>
            <li className={classes.li}>
              Introduced the <b>“Prior Release”</b> parameter which is required only for early defect prediction.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Enhancements and modifications:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Error handling</b> was improved to provide more specific cause of the problem when it’s encountered.
            </li>
            <li className={classes.li}>
              <b>Defect density</b> is now calculated using the predicted defects at D1 & D2 if actual data is not available.
            </li>
            <li className={classes.li}>
              <b>End points</b> of arrival / closure prediction curves were adjusted for the “Prediction Horizon” parameter value.
            </li>
            <li className={classes.li}>
              <b>Early defect prediction</b> was modified to adjust for a special case where initial actual data does not follow the predicted curve.
            </li>
            <li className={classes.li}>
              <b>Date format change</b> for data input was made to accommodate a more universal format (yyyy-mm-dd).
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 1.3</b> resulted from User feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}

export function OneDotThreeJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 1.3 リリースノート</h2> 
      <h3>2021 年 10 月</h3>
      
      <p>
        STAR 1.3 はランディングページ初版を導入しました。このサイトからアクセスします。 <a href="https://sakurasoftsolutions.com/">https://sakurasoftsolutions.com/</a>. これにより、ログインして登録することなく Documentation, Release Notes, About Us, Contact Us, Terms of Service and Privacy Policy を閲覧することができま す。従来の STAR メインページへのアクセスは Dashboard からできます。今回のリリースでは 数々の view(“Defects by component”, “Defects by severity”, “Prediction Stability”, “Early Prediction”)を実際のバグデーターに接続しました。更に、初期バグ予測曲線の予測精度とス タビリティを改善しました。
      </p>
      <p>
        主な新機能と改善項目は以下の通りです。
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>ランディングページ</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              ランディングページ初版を導入しました。このサイトからアクセスします。<a href="https://sakurasoftsolutions.com/">https://sakurasoftsolutions.com/</a>. これにより、ログインして登録することなく Documentation, Release Notes, About Us, Contact Us, Terms of Service and Privacy Policy を閲覧することができます。
            </li>
            <li className={classes.li}>
              従来の STAR メインページへのアクセスは Dashboard からできます。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>数々の view</b> を実際のバグデーターに接続しました。
          
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Defects by component:</b> 実際のバグデーターをコンポーネントごとに整理してバグ曲線を表します。
            </li>
            <li className={classes.li}>
              <b>Defects by severity:</b> 実際のバグデーターを重要度ごとに整理してバグ曲線を表します。
            </li>
            <li className={classes.li}>
              <b>Prediction Stability:</b> D2 での予測値の安定性を評価できます。
            </li>
            <li className={classes.li}>
              <b>Early Prediction:</b> 初期バグ予測を実際のバグデーターと比較できます。バグデーターの arrival, closure & open ごとに比較可能です。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>ユーザーインプットメニューの Release config ページ</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              3項目 (Release Parameters, Defect Data, and Effort Data) を一行にまとめ ました。これにより右側のスライドが不要となります。
            </li>
            <li className={classes.li}>
              Start Date を追加してチャートの始発日をコントロールできます。
            </li>
            <li className={classes.li}>
              初期バグ予測曲線に必要な Prior Release を追加して選択できます。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>その他の改善項目</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Error Handling を追加してトラブルシューティングが迅速になります。
            </li>
            <li className={classes.li}>
              実際のバグデーターがなくても Defect Density が算出できるようにしました。
            </li>
            <li className={classes.li}>
              初期バグ予測曲線の予測精度とスタビリティを改善しました。
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください。
      </p>
    </React.Fragment>
  )
}
