import React from 'react';

import Card from '@mui/material/Card';
import styled from '@emotion/styled';
import TrialDisplay from './TrialDisplay';
import { NavLink } from 'react-router-dom';

import PageTitle from '../../common/headings/PageTitle';

const axios = require('axios').default;

const AccountCard = styled(Card)`
  padding: 24px;
  margin: 0 auto;
  max-width: 1080px;
`;

const MembershipNote = styled.div`
  font-size: 1rem;
`;


const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid black;
`;


const TitleCell = styled.td`
  padding: 16px 64px 16px 0px;
  font-size: 1.25rem;
  white-space: nowrap;
`;

const ContentCell = styled.td`
  padding: 16px 0px;
  font-size: 1.25rem;
  width: 99%;
`;

const AccountDetail = ({ title, children }) => {
  return (
    <TableRow>
      <TitleCell> { title }</TitleCell>
      <ContentCell> { children } </ContentCell>
    </TableRow>
  )
};

const parseDate = (dateStr) => {
  if (!dateStr) {
    return undefined;
  }

  const date = new Date(dateStr);

  if (isNaN(date)) {
    return undefined;
  }

  return date;
};

const RegisteredDisplay = ({ownershipInfo, email}) => {

  if (!ownershipInfo) {
    return null;
  }

  let isTrial = ownershipInfo.membership_name === 'Trial';
  let expirationTitle = 'Expires on';
  let expirationContent = 'Membership does not expire';

  if (ownershipInfo.membership_expires) {
    if (!isTrial && ownershipInfo.will_renew) {
      expirationTitle = 'Renews on';
    }

    expirationContent = ownershipInfo.membership_expires;

    const endDate = parseDate(ownershipInfo.membership_expires);
    const now = new Date();
    now.setHours(0, 0, 0);
    if (endDate < now) {
      expirationTitle = 'Expired on';
    }
  }

  const portalLinkBase = process.env.REACT_APP_STRIPE_PORTAL_URL;
  const encodedEmail = encodeURIComponent(email);

  const customerLink = `${portalLinkBase}${encodedEmail}`;

  return (
    <>
      <AccountDetail title='Membership Tier' >
        {ownershipInfo.membership_name}

        {
          !isTrial &&
          (
            <MembershipNote><NavLink to={'/contact-us'} >Contact Us</NavLink> to change your membership tier</MembershipNote>
          )
        }
         
        
      </AccountDetail>

      <AccountDetail title={expirationTitle} >
        {expirationContent}
      </AccountDetail>

      {
        !isTrial &&
        (
          <AccountDetail title='Payment Portal' >
            <a href={customerLink}>Click here</a> to access your billing portal (Stripe)
          </AccountDetail>
        )
      }
    </>
  );
}




export default function AccountView(props) {
  const { user, setDocumentTitle } = props;

  const [ownershipInfo, setOwnershipInfo] = React.useState(undefined);
  const [noCompany, setNoCompany] = React.useState(false);
  const [isTrial, setIsTrial] = React.useState(false);

  React.useEffect(() => setDocumentTitle('3S | Account'));


  React.useEffect(() => {
    if (user) {
      const token = user.signInUserSession.idToken.jwtToken;

      const url = `${process.env.REACT_APP_API_URL}/users/ownership`;
      const headers = { Authorization: token };

      return axios.get(url, {headers}).then(
        response => {
          setOwnershipInfo(response.data);
        },
        error => {
          setIsTrial(false);
          setNoCompany(true);
        }
      )
    }
  }, [user])

  React.useEffect(() => {
    if (ownershipInfo && ownershipInfo.membership_name === 'Trial') {
      setIsTrial(true);
      setNoCompany(false);
    }
    else if (ownershipInfo && ownershipInfo.customer_id) {
      setIsTrial(false);
      setNoCompany(false);
    }
  }, [ownershipInfo]);


  let [email, setEmail] = React.useState('');

  React.useMemo(() => {
    if (user) {
      let userEmail = user.signInUserSession.idToken.payload.email;
      setEmail(userEmail);
    }
  }, [user]);

  // If no company owned, then allow user to register new company via free trial
  // If in free trial or free trial has expired, then show pricing table
  // If account is active then just display the current information and a link to the customer portal
  // If account is inactive then display the current info and a link to the customer portal

  let company = ownershipInfo?.company_name;
  if (noCompany) {
    company = (
      <span>No company registered. <NavLink to={'/trial'} >Click here</NavLink> to read more about our free trial.</span>
    );
  }

  return (
    <React.Fragment>
      <PageTitle>Account Information</PageTitle>

      <AccountCard>

        <Table>
          <AccountDetail title='Email' >
            {email}
          </AccountDetail>

          <AccountDetail title='Company' >
            {company}
          </AccountDetail>

          <RegisteredDisplay ownershipInfo={ownershipInfo} email={email} />

        </Table>

        { isTrial && (
            <TrialDisplay ownershipInfo={ownershipInfo} email={email} />
          )
        }


      </AccountCard>
    </React.Fragment>
  )
}

