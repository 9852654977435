const aswconfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION_NAME,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      secure: false,
      path: '/',
      expires: 365,
    },
  }
};

export default aswconfig;