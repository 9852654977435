import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function TwoDotZeroEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 2.0 Release Notes</h2> 
      <h3>Rollout started on June 2nd, 2022</h3>

      <p>
        <b>STAR 2.0</b> declares an official version ready for <b>customization</b>. Out latest release also introduces a new look and feel for our <b>Phase III Landing Page</b>. The STAR core engine now provides zero-touch automation of defect prediction. A user-friendly message indicates why some views are unavailable for specific projects and releases. 
      </p>
      <p>
        <b>STAR 2.0</b> also improves our user experience by providing <b>a new customer support page</b> to report issues using Jira.
      </p>
      <p>
        Continue reading below to learn more about the most important updates and improvements we've worked on.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>Landing Page Phase III:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Introduced a new look and feel landing page with the following new contents:
              <ul className={classes.ul}>
                <li className={classes.li}>
                  A new button for “Demo STAR now”
                </li>
                <li className={classes.li}>
                  A few new videos on “STAR overview,” “STAR walkthrough,” “What is STAR?” and “Early defect prediction.” More videos will be added as they become available.
                </li>
                <li className={classes.li}>
                  Collaborations with academia
                </li>
                <li className={classes.li}>
                  How STAR works
                </li>
                <li className={classes.li}>
                  Real-time impact of corrective actions
                </li>
                <li className={classes.li}>
                  Several output views to suit all users, from developers to executives
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Added a link to customer support to report issues.
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Dashboard:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              Core engine:
              <p>With the following new internal features, the core engine now provides zero-touch automation of defect prediction:</p>
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Parameterized when to start IPM processing with actual data.
                </li>
                <li className={classes.li}>
                  Closure delay time for PPM is calculated from the previous release data.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>Output:</b> Added a message indicating why some views are unavailable for specific projects and releases.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 2.0</b> resulted from User feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}


export function TwoDotZeroJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 2.0 リリースノート</h2> 
      <h3>2022年6月</h3>
      
      <p>
        今回のリリースでカスタマイゼーションの準備が完了しました。ランディングページも新しいルックアンドフィールに刷新し、コアエンジンも詳細なバグ予測アルゴリズムの導入を完了し、ゼロタッチ自動化となりました。万一の不具合の場合にはユーザーフレンドリーなメッセージを表示します。カスタマーサポートのメニューから不具合のレポートをオンラインで３Sに連絡できます。
      </p>

      <p>
        主な新機能と改善項目は以下の通りです。
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>ランディングページ</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              新しい<b>ルックアンドフィール</b>に刷新し、以下のコンテンツを導入しました。
              <ul className={classes.ul}>
                <li className={classes.li}>
                  新しいボックス、<b>Demo STAR Now。</b>
                </li>
                <li className={classes.li}>
                  新しい動画、STAR Overview, STAR walkthrough, What is STAR?, Early defect prediction。
                </li>
                <li className={classes.li}>
                  <b>コラボレーション</b>
                </li>
                <li className={classes.li}>
                  STAR <b>アーキテクチャ</b>
                </li>
                <li className={classes.li}>
                  <b>コレクティブアクションのインパクト</b>を即時に 提供。
                </li>
                <li className={classes.li}>
                  他のアウトプットの紹介。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              カスタマーサポートのメニューから<b>不具合のレポート</b>が可能。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>ダッシュボード</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>コアエンジン</b>: 以下のSTAR内部の新機能の導入によりゼロタッチ自動化が可能となりました。
              <ul className={classes.ul}>
                <li className={classes.li}>
                  実際のバグデーターがある場合IPMのプロセスをいつ稼働させるかをパラメータを導入してコントロールする。
                </li>
                <li className={classes.li}>
                  クロージャ曲線に必要な遅延値を前のリリースデータから算出する。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>アウトプット</b>: 万一の不具合の場合にはユーザーフレンドリーなメッセージを表示します。
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください。
      </p>
    </React.Fragment>
  )
}
