import React from 'react';
import { useHistory } from "react-router"
import { Route, useLocation } from "react-router-dom";
import { registerPath } from '../../constants/routes';

export default function RouteWrapper(props) {
  const history = useHistory();
  const location = useLocation();

  const { path, Component, authRequired, user, setUser, setDocumentTitle } = props;

  if (authRequired && user === false) {
    history.push({
      pathname: registerPath,
      search: `?next=${location.pathname}${location.search}`,
    });
  }

  return (
    <Route exact path={path}>
      <Component user={user} setUser={setUser} setDocumentTitle={setDocumentTitle} />
    </Route>
  )
}
