import React, { useState } from 'react';
import PageTitle from '../../common/headings/PageTitle';
import PdfViewer from '../../common/PdfViewer';
import LanguageSelector from './LanguageSelector';

import styled from '@emotion/styled';

export default function UserGuideView(props) {
  const { setDocumentTitle } = props;

  const enFile = '/pdfs/documentation_en.pdf';
  const jpFile = '/pdfs/documentation_jp.pdf';

  const [file, setFile] = useState(enFile)
  
  React.useEffect(() => setDocumentTitle('3S | User Guide'))

  const LanguagePDFContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `;

  const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1080px;
    width: 100%;
  `;

  const languageSelector = (
    <LanguageSelector
      selectedFile={file}
      enFile={enFile}
      jpFile={jpFile}
      onChange={(e) => setFile(e.target.value)}
    />
  );

  return (
    <React.Fragment>
      <PageTitle>User Guide</PageTitle>
      <LanguagePDFContainer>
        <CenteredContainer>
          {languageSelector}
          <PdfViewer file={file} />
        </CenteredContainer>
      </LanguagePDFContainer>
    </React.Fragment>
  )
}
