import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {primaryMain} from '../../constants/colors';
import Field from './Field';


const useStyles = makeStyles((theme) => ({
  headerText: {
    color: primaryMain,
    fontSize: 32,
    paddingBottom: 8,
  },
  subHeaderText: {
    color: primaryMain,
    fontSize: 20,
  },
  bodyText: {
    marginTop: 12,
    color: primaryMain,
  },
  greenTick: {
    width: '100%',
    padding: 24,
    fontSize: 180,
    color: 'green',
  },
}));

function SubmitForm(props) {
  const classes = useStyles();
  const { membershipExpires } = props;

  return (
    <React.Fragment>
      <Typography variant='h2' align='center' className={classes.headerText}>
        Congratulations!
      </Typography>
      <Typography variant='h3' align='center' className={classes.subHeaderText}>
        You have setup your first project on STAR!
      </Typography>

      <CheckCircleIcon className={classes.greenTick} />

      <Typography align='center' className={classes.bodyText}>
        Your membership will expire on <b>{membershipExpires}</b>. 
        Now all that's left is for you to set your release dates and upload your first set of defects!
        Once you have done that it will only take a few seconds before you can start viewing your first software quality report.
      </Typography>
    </React.Fragment>
  )
}

export default SubmitForm;
