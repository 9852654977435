import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import {primaryMain} from '../../constants/colors';
import Field from './Field';


const useStyles = makeStyles((theme) => ({
  headerText: {
    color: primaryMain,
    fontSize: 22,
    // fontWeight: 'bold',
  },
  bodyText: {
    marginTop: 12,
    color: primaryMain,
  },
  releaseList: {
    listStyleType: 'none',
    padding: 0,
    marginTop: 4,
    height: 200,
    overflow: 'scroll',
    border: '1px solid silver',
  },
  releaseListItem: {
    padding: '1px 12px',
    borderBottom: '1px solid gainsboro',
    "&:nth-child(odd)": {
      background: 'ghostwhite'
    },
  }
}));

function SubmitForm(props) {
  const classes = useStyles();
  const { company, project, releases } = props;

  return (
    <React.Fragment>
      <Typography variant='h2' align='center' className={classes.headerText}>
        Your Trial Setup
      </Typography>
      <Typography className={classes.bodyText}>
        <b>Company:</b> {company}
      </Typography>
      <Typography className={classes.bodyText}>
        <b>Project:</b> {project}
      </Typography>
      <Typography className={classes.bodyText}>
        <b>Releases:</b>
        <ul className={classes.releaseList}>
          {releases.map((release) => <li className={classes.releaseListItem}>{release}</li>)}
        </ul>
      </Typography>
    </React.Fragment>
  )
}

export default SubmitForm;
