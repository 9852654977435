import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function TwoDotOneEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 2.1 Release Notes</h2> 
      <h3>Rollout started on December 19th, 2022</h3>

      <p>
        <b>STAR 2.1</b>   introduces  TWO  additional  options  to  our  Corrective  Action's view. In  addition,  this  release continues   to   improve   computational   efficiency,   robustness,   and   user-friendliness.   STAR   2.1   also automatically determines the optimum increment (e.g., weekly, two weeks), depending on the software development lifecycle.
      </p>
      <p>
        Many of our most recent improvements have resulted directly from our exciting new collaboration with NASA as they provide feedback on our STAR Trial.
      </p>
      <p>
        Continue reading below to learn more about the most important updates and improvements we've worked on.
      </p>
      <p>
        Specific enhancements are described below:
      </p>

      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>Landing Page/Main Menu:</b>
          <ul className={classes.ul}>

            <li className={classes.li}>
              Redesigned the look-and-feel incorporating customer feedback.
            </li>
            <li className={classes.li}>
              Demo data - Users can now find additional information under > Resources 
              <ul className={classes.ul}>
                  <li className={classes.li}>
                  This includes data specifications, projects, and their corresponding outputs
                  </li>
                </ul>
            </li>
            <li className={classes.li}>
              Technical References - Under the Resources tab we now include our latest publications from ISSRE 2022
            </li>
            <li className={classes.li}>
              A new video on Defect Trend Analysis has been released. All videos were uploaded to the landing page, LinkedIn, and YouTube.
            </li>
            <li className={classes.li}>
              Pricing Tab - Customers can now view our Service Plans and pricing breakdown.  We also provide examples of the many customizable features that our team will be happy to discuss. 
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>User Interface:</b>
          <ul className={classes.ul}>

            <li className={classes.li}>
              While uploading your defect data, STAR will now remove duplicate or blank defect data in defect IDs. 
            </li>
            <li className={classes.li}>
              Release Configuration page - Additional notes to show the correct date format have been added to the column headers in the user defect data CSV file. 
            </li>
            <li className={classes.li}>
              Introduced a “Prediction in Progress” pop-up which now shows up in the Executive Summary view after either the “Save Config” box or the “Update defects” box while STAR computations are on-going. 
            </li>
            <li className={classes.li}>
              A Time Stamp of the last prediction update is now shown at the top of the Executive Summary view. 
            </li>
            <li className={classes.li}>
              Always display actual defect curves on the Executive Summary page, independent of the prediction status. 
            </li>
            <li className={classes.li}>
              Display the arrival prediction curve in the executive summary view if the closure curve prediction fails. 
            </li>
            <li className={classes.li}>
              Display the arrival & closure prediction curves in the executive summary view if the open prediction curve fails. 
            </li>
            <li className={classes.li}>
              Display the arrival & closure data in the Release Config view of User Inputs upon updating the defect data. This will help visualize the user defect data as soon as it is uploaded. 
            </li>
            <li className={classes.li}>
              New Projects & Releases can now be to added directly from our simple STAR drop down menus. We have also made it possible to make any names editable. 
            </li>
            <li className={classes.li}>
              A new “Effort Data” menu under Leading Data (PPM) and Leading Data (IPM) has been added.  This menu will provide charts showing actual development and test effort data for each release. It also provides actual defect data and preprocessed effort data overlaid with a dual vertical axis.
            </li>
          </ul>
        </li>




        <li className={classes.li}>
          <b>Core Engine:</b>
          <ul className={classes.ul}>


            <li className={classes.li}>
              Automatically determine the optimum increment in weeks for defect trend analysis. 
            </li>
            <li className={classes.li}>
              Added two new options to the corrective action set, which now supports a total of four options that can be analyzed individually or in combination with each other. 
              <ul className={classes.ul}>
                <li className={classes.li}>
                1) Delay the delivery date, 2) add more developers, 3)add more testers, and 4) reduce the content.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Closure Prediction Enhancements: Refined the closure prediction algorithm.
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Include the case where the closure curve is not keeping up with the arrival curve. 
                </li>
                <li className={classes.li}>
                  Handle the case where no closure data is available.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              Resolved AWS timeout issue.
              <ul className={classes.ul}>
                <li className={classes.li}>
                  Stop prediction stability data at D2. 
                </li>
                <li className={classes.li}>
                  Simplify the closure prediction algorithm. 
                </li>
                <li className={classes.li}>
                  Allow only one component and one severity at a time. 
                </li>
                <li className={classes.li}>
                  Incorporate multi-core processing for prediction stability. 
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className={classes.li}>
          <b>Internal Development Process:</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
            Automated the deployment process for the staging and official sites. This allows for  continuous testing and deployment while also speeding up our delivery timeline for key features and critical fixes for our customers!
            </li>
          </ul>
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 2.1</b> resulted from User feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}


export function TwoDotOneJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 2.1 リリースノート</h2> 
      <h3>2022年12月</h3>
      
      <p>
        今回のリリースではNASAのトライアルからの経験を基に解析法の効率化、ロバスト性の強化、そしてユーザーフレンドリーの増改築に集中しました。具体的にはCorrective Actionsのオプションに2項目を追加し、バグデーターの最適な集計間隔を自動的に設定します。今後もNASAとのコラボレーションを基にSTARを一段と実践向きツールとして構築していきます。
      </p>
      <p>
        主な新機能と改善項目は以下の通りです。
      </p>
      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>ランディングページ</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              ユーザーフィードバックを基に<b>ルックアンドフィール</b>を刷新。
            </li>
            <li className={classes.li}>
              Demo dataの説明書をResourcesのメニューに追加。
            </li>
            <li className={classes.li}>
              ISSRE 2020の論文をTechnical Referencesに追加。
            </li>
            <li className={classes.li}>
              Defect Trend Analysisの動画をLanding page, LinkedIn, YouTubeにアップロード。
            </li>
            <li className={classes.li}>
              Pricing TabをService Plansに追加。カスタマー新機能のサンプルも掲載。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          ユーザーインターフェース
          <ul className={classes.ul}>
            <li className={classes.li}>
            ユーザーインプットのバグデーターの重複、空白を検索、削除しエラーメッセージを防ぐ。
            </li>
            <li className={classes.li}>
            日付様式とコラムヘッダーの注意書をRelease Configurationに追加。
            </li>
            <li className={classes.li}>
            Save Configをクリックすると、Executive Summaryに “Prediction in Progress”のメッセージが、そして終了後に日付・時間が表示される。
            </li>
            <li className={classes.li}>
            予測曲線が失敗しても実データー曲線は表示される。Closure曲線が失敗してもArrival曲線は表示され、またOpen曲線が失敗してもArrivalとClosure曲線は表示される。
            </li>
            <li className={classes.li}>
            Release Configでユーザーインプットのバグデーターの投入後にArrivalとClosure曲線が表示される。
            </li>
            <li className={classes.li}>
            新しいProjectとReleaseの名前がDrop-downのメニューから追加・変更が可能。
            </li>
            <li className={classes.li}>
            “Effort Data”メニュー項目をLeading Data (PPM)とLeading Data (IPM)に追加。Development effort dataとtest effort dataを同時にグラフで表示、またバグデーターとエフォートデータの相関関係をグラフで表示。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>コアエンジン</b>
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>バグデーターの最適間隔を計算し採用する。</b>
            </li>
            <li className={classes.li}>
              Corrective Action<b>メニューに２項目を追加、計４メニュー項目で納入日の延期、開発者の追加、試験者の追加、コンテンツの削減となる。</b>
            </li>
            <li className={classes.li}>
              Closure予測アルゴリズムを改良し、Arrival曲線に追従していない場合、closureデーターがない場合にも対応可能にした。
            </li>
            <li className={classes.li}>
              Multi-coreプロセッシングを導入してAWSプラットフォームからのタイムアウト問題を解決。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          内部開発プロセス
          <ul className={classes.ul}>
            <li className={classes.li}>
              デプロイメントのプロセスを改良してStagingとofficialのサイトの構築が自動的に作成されます。これにより、新機能と重要なバグにさらに迅速な対応が可能となる。
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください。
      </p>
    </React.Fragment>
  )
}
