import React from 'react';
import Box from '@mui/material/Box';

import { useReleaseNotesStyles } from '../../../common/styles/ReleaseNoteStyles';

export function OneDotTwoEn() {
  const classes = useReleaseNotesStyles();

  return (
    <Box className={classes.root}>
      <h2>STAR 1.2 Release Notes</h2> 
      <h3>Rollout started on September 12, 2021</h3>
      <p>
        <b>STAR 1.2</b> introduces an <b>early defect prediction</b>, which is based on <b>development and test effort data</b>. It integrates previous release data for defect arrival, closure and open predictions without actual defect data during the planning phase. This will enable project managers to balance development & test resources against the quality target at the delivery date. It significantly improves <b>prediction stability</b> if the input data is accurately collected. The early defect prediction curve will then become a basis for updating predictions as actual defect data becomes available every week. <b>STAR 1.2</b> implements <b>innovative algorithms</b> to automate the generation of arrival, closure and open defect prediction curves without human intervention (i.e., <b>zero-touch automation</b>).
      </p>
      <p>
        <b>STAR 1.2</b> also improves <b>performance and scalability</b> by switching the internal database to <b>AWS DynamoDB</b>.
      </p>
      <p>
        Continue reading below to learn more about the most important updates and improvements we’ve worked on.
      </p>
      <p>
        Key specific enhancements are as follows:
      </p>
      <ul className={classes.ul}>
        <li className={classes.li}>
          <b>Two new menu items</b> (Leading Data (PPM) & Leading Data (IPM)):
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Leading Data (PPM):</b> An early defect prediction <b>without actual defect data</b> is demonstrated in the Executive Summary view and the Early Prediction view for Project D Release B. It uses development and test effort data and defect data from previous release, Release A. STAR internally calculates defect density (defects / 1,000 hours of effort) and normalizes test effort data as a test progress curve. It then transforms development effort data into an adjusted development effort curve via the test progress curve. An innovative algorithm automatically generates a new defect arrival curve without actual defect data.
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Early Prediction View:</b> The defect arrival curve along with +/- 10% lower and upper limits are shown in this view. It also shows predicted defects at D1 & D2 using the defect density values calculated based on Release A data. The algorithm transforms the adjusted development effort curve into a new defect prediction curve which go through the predicted defects at D1 & D2.
                </li>
                <li className={classes.li}>
                  <b>Executive Summary View:</b> STAR integrates the new arrival curve prediction with the currently implemented algorithm for closure and open defect predictions. The closure prediction is adjusted using the previous release data, Release A.
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>Leading Data (IPM):</b> An early defect prediction <b>with actual defect data</b> is demonstrated in the Executive Summary view for Project D Release B. It uses the early defect prediction without actual defect data as a leading data. As actual defect data becomes available every week, the early prediction curve is transformed in line with actual data using another innovative algorithm. It has proven to be effective in improving prediction stability. This algorithm has many use cases which will be implemented (e.g., component- level / severity level predictions) to STAR in the future.
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Early Prediction View:</b> Actual defect data is overlaid over the previously derived early prediction curve. If everything goes well, actual data is expected to closely follow the early prediction within the +/- 10% limits.
                </li>
                <li className={classes.li}>
                  <b>Executive Summary View:</b> STAR integrates the new arrival curve prediction with the currently implemented algorithm for closure and open defect predictions. Although it is a snapshot view, it provides consistent and stable predictions as illustrated for Project D Release B.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          <b>Internal database upgrade:</b> Although it’s not a user visible feature, the upgrade to AWS DynamoDB provides significant improvements in performance and scalability.
        </li>
        <li className={classes.li}>
          <b>New Summary table:</b> An overall summary table of our STAR assessment is now shown color- coded in terms of %Residual and %Open. The breakdown is as follows, with acceptable (green), warning (yellow) and at risk (red) shown in the top left table. More detailed derivations are found in the other tables below.
        </li>
        <li className={classes.li}>
          <b>No gap between actual and predicted open defects at current week:</b> There is a small but recognizable gap in the open curves at the current week due to a computational problem. The algorithm was modified to eliminate the gap. The prediction curve now starts with the same actual value at current week. This is in response to a frequently asked question.
        </li>
      </ul>

      <p>
        Many of the enhancements introduced in <b>STAR 1.2</b> resulted from user feedback, and we will continue to do so. Please, if you have any questions or suggestions, don't hesitate to contact us at <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a>.
      </p>
    </Box>
  );
}

export function OneDotTwoJp() {
  const classes = useReleaseNotesStyles();

  return (
    <React.Fragment>
      <h2>STAR 1.2 リリースノート</h2>
      <h3>2021 年 9 月 12 日</h3>

      <p>
        <b>STAR 1.2</b> は初期バグ予測曲線の新機能を導入しました。ソフトウェア開発・テストに必要な時 間の推定値と前回のリリースからのバグデーターをもとにバグデーターなしで今回のリリース の計画中にバグ予測曲線を作成します。革新的なアルゴリズムを導入し、すべて自動的に作成 されます。これにより、プロジェクト マネージャーはリリースの計画中に納期日の品質目標 値を達成するに必要な開発者数とテスト者数を定量的に把握できます。そして、バグデーター が実際に入ってくると、初期バグ予測曲線はこのデータをもとに自動的にアップデートされま す。この手法により、予測安定度も一段と向上することも立証されます。
      </p>
      <p>
        <b>STAR 1.2</b> は内部データベースを AWS DynamoDB に変更することで、パフォーマンスとスケーラ ビリティを更に向上しました。
      </p>
      <p>
        主な新機能と改善項目は以下の通りです。
      </p>
      <ul className={classes.ul}>
        <li className={classes.li}>
          メニュー項目の追加 (Leading Data (PPM) & Leading Data (IPM):
          <ul className={classes.ul}>
            <li className={classes.li}>
              <b>Leading Data (PPM):</b> バグデーター無しで初期バグ予測曲線の作成できることを立 証するために Project D Release B を使い Executive Summary view に初期バグ予測曲 線を掲載します。Project D Release B には前回のリリース、Release A、から開発 者数とテスト者数を基にした時系列エフォートデータ (Development & Test Effort in 1,000 hours) とバグデーターをを入力します。STAR は内部で納期日 (D1 & D2) での Defect Density (Defects / 1,000 hours) を計算します。そして、計画中の Release B からの開発・テストの推定されるエフォートデータを基に革新的なア ルゴリズムが自動的にバグ予測曲線を作成します。
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Early Prediction View:</b> ここでは初期バグ予測曲線と+/- 10%の上限・下限曲 線を掲載します。そして、Defect Density とエフォートデータから計算さ れた納期日でのバグ数の推定値を掲載します。アルゴリズムがエフォー トデータ曲線を基に、この2点を最小幅で作成していることを立証しま す。
                </li>
                <li className={classes.li}>
                  <b>Executive Summary view:</b> ここでは上記のアライバル曲線 Arrival Curve に更 に既存のクロージャー曲線 Closure Curve とオープン曲線 Open Curve を統 合します。クロージャー曲線は Release A のデータを基に作成されます。
                </li>
              </ul>
            </li>
            <li className={classes.li}>
              <b>Leading Data (IPM):</b> バグデーターが実際に入ってくると、初期バグ予測曲線はこ のデータをもとに自動的にアップデートされることを同じ Project D Release B を 使い立証します。アルゴリズムは初期バグ予測曲線をリードデータとして毎週入 ってくるバグデーターを基に予測曲線をアップデートします。
              <ul className={classes.ul}>
                <li className={classes.li}>
                  <b>Early Prediction View:</b> 実際のバグデーターを上記で作成された予測曲線に 上乗せします。バグデーターが予測曲線に沿って上下限内で推移してい るかを確認します
                </li>
                <li className={classes.li}>
                  <b>Executive Summary view:</b> 実際のバグデーターを基にアライバル・クロージ ャー・オープン曲線すべてアップデートされます。スナップショットの データを掲載してますが、予測曲線が徐々にアップデートされているこ とが分かります。
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          内部データベースのアップグレード:
          <ul className={classes.ul}>
            <li className={classes.li}>
              o ユーザーには直接見えないがら内部データベースを AWS DynamoDB にアップグレードしました。これにより、パフォーマンスとスケーラビリティを更に向上しました。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          新総括表:
          <ul className={classes.ul}>
            <li className={classes.li}>
              Executive Summary view の左上に現リリースの %Residual と %Openを基にした品 質評価表を纏めました。
            </li>
          </ul>
        </li>
        <li className={classes.li}>
          実際地と予測値のギャップを削除:
          <ul className={classes.ul}>
            <li className={classes.li}>
              アルゴリズムの計算方法の精度のため最後の週で実際地と予測値の小さなギャッ プがありました。アルゴリズムを改良することでこのギャップを削除しました
            </li>
          </ul>
        </li>
      </ul>

      <p>
        お客様のフィードバックを参考にしながら STAR を一層使いやすいツールにしていきます。ご 意見を ”Contact Us” <a href="mailto:info@sakurasoftsolutions.com">info@sakurasoftsolutions.com</a> を通じてお聞かせください
      </p>
    </React.Fragment>
  )
}
