import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 18,
  },
}));

function Field(props) {
  const classes = useStyles();
  const { value, label, error, onChange, autoFocus = false } = props;

  const textFieldProps = {
    value: value,
    autoFocus: autoFocus,
    onChange: onChange,
    variant: "outlined",
    label: label,
    placeholder: label,
    error: Boolean(error),
    helperText: error,
    className: classes.root,
  };

  return (
    <TextField {...textFieldProps} />
  )
}

export default Field;
