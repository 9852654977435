import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import EmailIcon from '@mui/icons-material/Email';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';

import PageTitle from '../../common/headings/PageTitle';
import { primaryLightest, primaryMedium, secondaryMain } from '../../constants/colors'; 

const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 920,
    margin: '0 auto',
    marginBottom: 48,
    padding: 24,
  },
  field: {
    width: '100%',
    marginBottom: 24,
  },
  formControl: {
    width: '100%',
    marginBottom: 24,
    borderRadius: 4,
  },
  selectLabel: {
    background: 'white',
    padding: '0px 6px',
  },
  button: {
    width: 280,
  },
  error: {
    paddingTop: 24,
    color: 'red',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 120,
    color: secondaryMain,
  },
  headerText: {
    fontSize: 32,
    paddingBottom: 8,
  },
  subHeaderText: {
    fontSize: 20,
  },
}));

export default function ContactUsView(props) {
  const classes = useStyles();

  const { user, setDocumentTitle } = props;

  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [reason, setReason] = React.useState('General Enquiry');
  const [message, setMessage] = React.useState('');
  const [disableForm, setDisableForm] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState('');

  React.useEffect(() => setDocumentTitle('3S | Contact Us'))

  React.useEffect(() => {
    if (user && user.attributes.name) {
      setName(user.attributes.name);
    }
    if (user && user.attributes.email) {
      setEmail(user.attributes.email);
    }
    if (user && user.attributes['custom:company']) {
      setCompany(user.attributes['custom:company']);
    }
  }, [user]);

  const handleFormSubmit = async () => {
    setDisableForm(true);
    const url = `${process.env.REACT_APP_API_URL}/emailer/contact-us`;

    const data = { 
      name: name.trim(),
      company: company.trim(),
      email: email.trim(),
      reason: reason.trim(),
      message: message.trim(),
    };

    await axios.post(url, data)
      .then(response => {
        setDisableForm(false);
        setSubmitted(true);
      })
      .catch(error => {
        console.log(error);
        setError('An error occured while submitting this form.');
        setDisableForm(false);
      }
    )
  }

  const emailFieldProps = {
    autoFocus: true,
    required: true,
    disabled: disableForm,
    type: "email",
    autoComplete: "email",
    value: email,
    label: 'Email',
    placeholder: 'Email',
    variant: 'outlined',
    className: classes.field,
    onChange: (e) => setEmail(e.target.value),
  };

  const nameFieldProps = {
    required: true,
    disabled: disableForm,
    autoComplete: "name",
    value: name,
    label: 'Name',
    placeholder: 'Name',
    variant: 'outlined',
    className: classes.field,
    onChange: (e) => setName(e.target.value),
  };

  const companyFieldProps = {
    disabled: disableForm,
    autoComplete: "organization",
    value: company,
    label: 'Company',
    placeholder: 'Company',
    variant: 'outlined',
    className: classes.field,
    onChange: (e) => setCompany(e.target.value),
  };

  const submitButtonProps = {
    disabled: disableForm,
    color: "secondary",
    variant: "contained",
    type: "submit",
    className: classes.button,
  };

  const messageFieldProps = {
    disabled: disableForm,
    required: true,
    value: message,
    label: 'Message',
    placeholder: 'Message',
    variant: 'outlined',
    multiline: true,
    rows: 8,
    className: classes.field,
    onChange: (e) => setMessage(e.target.value),
  };

  const selectProps = {
    value: reason,
    onChange: (e) => setReason(e.target.value),
  }

  const homeButtonProps = {
    color: "secondary",
    variant: "contained",
    className: classes.button,
    onClick: () => window.location = process.env.REACT_APP_HOME_URL,
  }

  return (
    <React.Fragment>
      <PageTitle>Contact Us</PageTitle>
      
      <Card className={classes.card}>
        {submitted ? (
          <React.Fragment>
            <Typography variant='h2' align='center' className={classes.headerText}>
              Thanks for getting in touch!
            </Typography>
            <Typography variant='h3' align='center' className={classes.subHeaderText}>
              We will get back to you as soon as possible.
            </Typography>

            <br />
            <br/>

            <Box textAlign='center'>
              <EmailIcon className={classes.icon} />
              <ArrowRightAltOutlinedIcon className={classes.icon} />
              <PersonIcon className={classes.icon} />
            </Box>

            <br/>
            <br/>

            <Typography align='center'>
              We have sent you an email confirming that your message has been recieved. If you have not recieved this email, please contact us at <b>info@sakurasoftsolutions.com</b>.
            </Typography>

            <br/>
            <br/>

            <Box textAlign='center'>
              <Button {...homeButtonProps}>
                Return Home
              </Button>
            </Box>
          </React.Fragment>
        ):(
          <React.Fragment>
            <form onSubmit={ (e) => { handleFormSubmit(); e.preventDefault(); } }>
              <TextField {...emailFieldProps} />
              <TextField {...nameFieldProps} />
              <TextField {...companyFieldProps} />

              <FormControl variant="outlined" disabled={disableForm} className={classes.formControl}>
                <InputLabel id='reason-select' className={classes.selectLabel} shrink>
                  Reason for Contacting
                </InputLabel>
                <Select labelId="reason-select" {...selectProps}>
                  <MenuItem value='General Enquiry'>General Enquiry</MenuItem>
                  <MenuItem value='Schedule Demo'>Schedule Demo</MenuItem>
                  <MenuItem value='Pricing'>Pricing</MenuItem>
                  <MenuItem value='Data Privacy & Security'>Data Privacy & Security</MenuItem>
                  <MenuItem value='Feature Request'>Feature Request</MenuItem>
                  <MenuItem value='Bug Report'>Bug Report</MenuItem>
                </Select>
              </FormControl>

              <TextField {...messageFieldProps} />

              <Box textAlign='center'>
                <Button {...submitButtonProps}>
                  Submit
                </Button>
              </Box>

              <Typography align='center' className={classes.error}>
                {error}
              </Typography>
            </form>
          </React.Fragment>
        )}
      </Card>
    </React.Fragment>
  )
}


