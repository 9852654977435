import AuthView from '../pages/auth/AuthView';
import ContactUsView from '../pages/contact_us/ContactUsView';
import PrivacyPolicyView from '../pages/terms/PrivacyPolicyView';
import TermsOfServiceView from '../pages/terms/TermsOfServiceView';
import TrialLandingView from '../pages/trial_landing/TrialLandingView';
import TrialRegisterView from '../pages/trial_register/TrialRegisterView';
import TutorialView from '../pages/documentation/TutorialView';
import UserGuideView from '../pages/documentation/UserGuideView';
import TechnicalReferencesView from '../pages/technical_references/TechnicalReferencesView';
import PricingView from '../pages/documentation/PricingView';
import StarOverviewView from '../pages/documentation/StarOverviewView';
import StarUseCasesView from '../pages/documentation/StarUseCasesView';
import ReleaseNotesView from '../pages/release_notes/ReleaseNotesView';
import AboutUsView from '../pages/documentation/AboutUsView';
import OverviewView from '../pages/overview/Overview';
import LandingView from '../pages/landing/LandingView';
import DemoDataView from '../pages/documentation/DemoDataView';
import VideosView from '../pages/videos/VideosView';
import AccountView from '../pages/account/AccountView';

export const signInPath = '/login';
export const registerPath = '/register';
export const resetPasswordPath = '/reset-password';

export const overviewPath = '/';
export const contactUsPath = '/contact-us';
export const trialLandingPath = '/trial';
export const trialRegisterPath = '/trial/register';
export const privacyPolicyPath = '/privacy-policy';
export const termsOfServicePath = '/terms-of-service';
export const releaseNotesPath = '/release-notes';
export const releaseNotesDeepPath = '/release-notes/:releaseId';
export const documentationPath = '/documentation';
export const userGuidePath = '/user-guide';
export const technicalReferencesPath = '/technical-references';
export const technicalReferencesDeepPath = '/technical-references/:referenceName';
export const videosPath = '/video-library';
export const videosDeepPath = '/video-library/:videoName';
export const pricingPath = '/pricing';
export const tutorialPath = '/tutorial';
export const aboutUsPath = '/about-us';
export const demoDataPath = '/demo-data';
export const landingPath = '/landing';
export const starOverviewPath = '/star-overview';
export const starUseCasesPath = '/star-use-cases';
export const accountPath = '/account';

export const routes = [
  { path: [signInPath, registerPath, resetPasswordPath], Component: AuthView, authRequired: false },
  { path: contactUsPath, Component: ContactUsView, authRequired: false },
  { path: trialRegisterPath, Component: TrialRegisterView, authRequired: true },
  { path: trialLandingPath, Component: TrialLandingView, authRequired: false },
  { path: privacyPolicyPath, Component: PrivacyPolicyView, authRequired: false },
  { path: termsOfServicePath, Component: TermsOfServiceView, authRequired: false },
  { path: tutorialPath, Component: TutorialView, authRequired: false },
  { path: userGuidePath, Component: UserGuideView, authRequired: false },
  { path: [technicalReferencesPath, technicalReferencesDeepPath], Component: TechnicalReferencesView, authRequired: false },
  { path: pricingPath, Component: PricingView, authRequired: false },
  { path: [releaseNotesPath, releaseNotesDeepPath], Component: ReleaseNotesView, authRequired: false },
  { path: [videosPath, videosDeepPath], Component: VideosView, authRequired: false },
  { path: aboutUsPath, Component: AboutUsView, authRequired: false },
  { path: demoDataPath, Component: DemoDataView, authRequired: false },
  { path: landingPath, Component: LandingView, authRequired: false },
  { path: starOverviewPath, Component: StarOverviewView, authRequired: false },
  { path: starUseCasesPath, Component: StarUseCasesView, authRequired: false },
  { path: accountPath, Component: AccountView, authRequired: true },
  // Keep empty route as last item in list
  { path: overviewPath, Component: LandingView, authRequired: false },
];