import React, { useEffect } from "react";
const StripePricingTable = ({customerId, email}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    'client-reference_id': customerId,
    'customer-email': email,
    'pricing-table-id': process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    'publishable-key': process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  });
};
export default StripePricingTable;