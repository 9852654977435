import React, { useState } from "react";
import { Typography, Button } from '@mui/material';
import styled from '@emotion/styled';
import InfoIcon from '@mui/icons-material/Info';
import { contactUsPath } from '../../../constants/routes';
import { secondaryDarker } from '../../../constants/colors';

const CustAvailContainer = styled.div`
    background: #A91D51;
    text-align: center;
    margin: 0 auto;
    margin-top: 2.5px;
    margin-left: auto; 
    margin-right: auto;
    padding-top: 10px;
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  width: 250px;
  background-color: #323232;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  font-size: 14px;
`;

const ContactUsButton = styled(Button)`
  width: 150px;
  height: 50xpx;

  background: white;
  border-radius: 72.8246px;
  color: ${secondaryDarker};
  font-size: 21px;
  text-transform: none;
  margin-bottom: 10px;

  &:hover {
    background: white;
  }
`;

const InfoTooltip = ({children, description, leftAlign}) => {
    const [visible, setVisible] = useState(false);
  
    return (
      <TooltipContainer onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
        {children}
        <TooltipText style={{ textAlign: leftAlign ? "left" : "center" }} isVisible={visible}>
          <b>{description.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}</b>
        </TooltipText>
      </TooltipContainer>
    );
}

const CustomizationAvailDesc = "Recognizing diverse company needs, our aim is to simplify your experience with STAR.\n\nReach out for tailored solutions, ensuring seamless integration. Additional charges apply for customization, based on estimated effort by 3S.\n\nWe develop and maintain customized features on STAR's cloud platform (AWS) to enhance functionality."

const CustomizationAvail = (props) => {
    const { tableWidth } = props;
    return (
        <CustAvailContainer style={{width: tableWidth}}>
            <Typography variant='h3' color='white' align='center'>
                <u>Customization Available!</u>
                <InfoTooltip description={CustomizationAvailDesc} leftAlign={true}>
                    <InfoIcon style={{transform: "scale(1)"}} sx={{ verticalAlign: 'top', fontSize: 20, marginLeft: 0.5, color: 'rgba(255, 255, 255, 0.7)' }} />
                </InfoTooltip>
            </Typography>

            <ContactUsButton href={contactUsPath}>Contact Us</ContactUsButton>
        </CustAvailContainer>
    );
};

export default CustomizationAvail;