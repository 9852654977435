import React, { useRef } from 'react';
import clsx from 'clsx';
import YoutubeEmbed from '../../common/YoutubeEmbed';
import PageTitle from '../../common/headings/PageTitle';
import { Link, NavLink } from "react-router-dom";
import PdfViewer from '../../common/PdfViewer';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { primaryMain, secondaryMain } from '../../constants/colors';
import { registerPath } from '../../constants/routes';
import { IconButton } from '@mui/material';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import ExternalLink from '../../common/routing/ExternalLink';

const useStyles = makeStyles((theme) => ({
  viewer: {
    margin: '20px auto',
    maxWidth: 1080,
  },
  pageSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  contentSection: {
    maxWidth: 1080,
    width: '100%',
    align: 'center',
  },
  guideSection: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
  },
  inCollaborationSection: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    marginBottom: 50,
  },
  collaborationBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 270,
    alignItems: 'center',
    justifyContent: 'center',
  },
  guideBox: {
    display: 'flex',
    width: 270,
    alignItems: 'center',
  },
  guideImage: {
    height: 100,
    width: 'auto',
  },
  collaborationImage: {
    maxHeight: 60,
    maxWidth: 210,
    width: 'auto',
  },
  collaborationImageBox: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
  },
  sideBySideSection: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    gap: '0px 60px',
    alignItems: 'center',
  },
  overviewImage: {
    flex: 1,
    flexShrink: 2,
    height: 'auto',
    maxWidth: 407,
    minWidth: 290,
  },
  whiteSection: {
    backgroundColor: 'white',
    padding: 50,
  },
  videoSection: {
    backgroundColor: 'white',
    padding: 50,
    minHeight: 200,
  },
  headSection: {
    backgroundColor: 'white',
    minHeight: 'calc(100vh - 76px)',
    padding: 50,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  seeMoreIcon: {
    fontSize: '3rem',
    color: primaryMain,
  },
  freeTrialSection: {
    backgroundColor: 'white',
    paddingBottom: 50,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  darkSection: {
    backgroundColor: primaryMain,
    padding: 50,
    textAlign: 'center'
  },
  dashboardSection: {
    backgroundColor: secondaryMain,
    padding: 50,
  },
  dashboardImage: {
    maxWidth: '100%',
    borderRadius: 8,
  },
  typography1: {
    margin: '20px 0',
    fontWeight: 'bold',
    color: primaryMain,
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
  },
  darkTypography: {
    flex: 1,
    minWidth: 290,
    margin: '20px 0',
    color: 'white',
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
    textAlign: 'justify',
  },
  typography2: {
    margin: '20px 0',
    color: primaryMain,
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
  },
  collaborationTypography: {
    margin: '10px 0',
    color: primaryMain,
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
  },
  redText: {
    color: secondaryMain,
  },
  trialButton: {
    backgroundColor: secondaryMain,
    color: 'white',
    fontWeight: 'bold',
    fontSize: 30,
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: -8,
    borderRadius: 8,    
    '&:hover': {
      background: secondaryMain,
    }
  },
  navLink: {
    textDecoration: 'none'
  }
}));

export default function OverviewView(props) {
  const classes  = useStyles();
  const moreRef = useRef(null);
  
  const executeScroll = () => moreRef.current.scrollIntoView({behavior: "smooth"});

  return (
    <React.Fragment>
      <Box className={clsx(classes.headSection, classes.pageSection)}>
        <div className={classes.contentSection}>
          <Typography variant='h4' className={classes.typography1}>
            <strong>REAL TIME, ZERO TOUCH, PRESCRIPTIVE & PREDICTIVE:</strong>
          </Typography>
          <Typography variant='h4' className={classes.typography2}>
            THE FUTURE OF SOFTWARE QUALITY MANAGEMENT        
          </Typography>
          <ExternalLink className={classes.navLink} href={`${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary`} >
            <Button className={classes.trialButton}>
              DEMO STAR NOW
            </Button>
          </ExternalLink>
        </div>

        <div className={clsx(classes.contentSection, classes.guideSection)}>
          <div className={classes.guideBox}>
            <Typography variant='h4' className={classes.typography2}>
              <strong>LET <span className={classes.redText}>STAR</span> BE YOUR GUIDE</strong>
            </Typography>
          </div>
          <div className={classes.guideBox}>
            <img className={classes.guideImage} src={process.env.PUBLIC_URL + '/assets/users.png'} alt='Users' />
            <Typography variant='body1' className={classes.typography2}>
              Several output views to suit all users from developers to executives
            </Typography>
          </div>
          <div className={classes.guideBox}>
            <img className={classes.guideImage} src={process.env.PUBLIC_URL + '/assets/representation.png'} alt='Representation' />
            <Typography variant='body1' className={classes.typography2}>
              Straightforward representation of software quality with customization
            </Typography>
          </div>
          <div className={classes.guideBox}>
            <img className={classes.guideImage} src={process.env.PUBLIC_URL + '/assets/visualization.png'} alt='Visualization' />
            <Typography variant='body1' className={classes.typography2}>
              Ability to see the real-time impact of corrective actions
            </Typography>
          </div>
        </div>

        <div className={classes.contentSection}>
          <IconButton onClick={executeScroll} size="large">
            <ArrowDropDownCircleOutlined className={clsx(classes.seeMoreIcon, 'bounce')} />
          </IconButton>
        </div>
      </Box>

      <Box ref={moreRef} className={clsx(classes.darkSection, classes.pageSection)}>
        <div className={clsx(classes.contentSection, classes.sideBySideSection)}>
          <img className={classes.overviewImage} src={process.env.PUBLIC_URL + '/assets/overview_graphic.png'} alt='System Overview Graphic' />
          <Typography variant='body1' className={classes.darkTypography}>
            
            <p>STAR provides a cloud-based and real-time service for software defect prediction which enables software development and test managers to make informed decisions regarding the readiness for high quality delivery of a software product.</p>
            <p>A seamless integration of data extraction, pre-processing,  zero-touch automated core engine with our user-friendly web interface and state-of-the-art visualization.</p>
            <p>At the core is our prediction engine that has been tested on real-world processes, and is continuously enhanced to provide the most accurate defect predictions.</p>
            <p>STAR’s prescriptive component simulates multiple corrective actions and presents our users with potential implications on software quality & delivery schedule.</p>

          </Typography>
        </div>
      </Box>

      <Box className={clsx(classes.dashboardSection, classes.pageSection)}>
        <div className={classes.contentSection}>
          <img className={classes.dashboardImage} src={process.env.PUBLIC_URL + '/assets/dashboard.png'} alt='Dashboard Screenshot' />
        </div>
      </Box>

      <Box className={clsx(classes.videoSection, classes.pageSection)}>
        <div className={classes.contentSection}>
          <SizeMe>
            {({ size }) => (
              <YoutubeEmbed embedId={'0sEzGhlce3o'} width={size.width ? size.width : 1} />
            )}
          </SizeMe>
        </div>
      </Box>

      <Box className={clsx(classes.freeTrialSection, classes.pageSection)}>
        <div className={classes.contentSection}>
          <Typography variant='h4' className={classes.typography1}>
            In collaboration with:
          </Typography>

          <div className={classes.inCollaborationSection}>
            <div className={classes.collaborationBox}>
              <div className={classes.collaborationImageBox}>
                <img className={classes.collaborationImage} src={process.env.PUBLIC_URL + '/assets/universities/hiroshima.png'} alt='Hiroshima University' />
              </div>
              <Typography variant='body1' className={classes.collaborationTypography}>
                Professor Tadashi Dohi
              </Typography>
            </div>
            <div className={classes.collaborationBox}>
              <div className={classes.collaborationImageBox}>
                <img className={classes.collaborationImage} src={process.env.PUBLIC_URL + '/assets/universities/rutgers.png'} alt='Rutgers University' />
              </div>
              <Typography variant='body1' className={classes.collaborationTypography}>
                Professor Hoang Pham
              </Typography>
            </div>
            <div className={classes.collaborationBox}>
              <div className={classes.collaborationImageBox}>
                <img className={classes.collaborationImage} src={process.env.PUBLIC_URL + '/assets/universities/massachusetts_dartmouth.png'} alt='University of Massachusetts Dartmouth' />
              </div>
              <Typography variant='body1' className={classes.collaborationTypography}>
                Professor Lance Fiondella
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.contentSection}>
          <Typography variant='h3' className={classes.typography1}>
            Sign your company up for STAR
          </Typography>
          <Typography variant='h4' className={classes.typography2}>
            Start your two month free trial
          </Typography>
          <NavLink className={classes.navLink} to={registerPath}>
            <Button className={classes.trialButton}>
              GET STARTED
            </Button>
          </NavLink>
        </div>
      </Box>

    </React.Fragment>
  );
}
