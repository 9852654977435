import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 18,
  },
}));

function EmailField(props) {
  const classes = useStyles();
  const { value, error, onChange, autoFocus = false } = props;

  const textFieldProps = {
    value: value,
    onChange: onChange,
    autoFocus: autoFocus,
    required: true,
    type: "email",
    autoComplete: "email",
    variant: "outlined",
    label: "Email Address",
    placeholder: "Email Address",
    error: error,
    helperText: error,
    className: classes.root,
  };

  return (
    <TextField {...textFieldProps} />
  )
}

export default EmailField;
