import React from 'react';
import { NavLink } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import PageTitle from '../../common/headings/PageTitle';
import { primaryMain, primaryLight } from '../../constants/colors';
import { trialRegisterPath } from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '12px 24px',
    margin: '0 auto',
    maxWidth: 780,
  },
  bodyText: {
    fontSize: 14,
    marginTop: 12,
    color: primaryMain,
  },
  privatePolicyText: {
    color: 'dimgrey',
    width: '75%',
    fontSize: 12,
    margin: '12px auto',
  },
  listItem: {
    paddingBottom: 8,
  },
  footer: {
    width: '100%'
  },
  button: {
    marginTop: 8,
    padding: '4px 24px',
    fontSize: 12,
    fontWeight: 'bold',
  }
}));


function LandingPage(props) {
  const classes = useStyles();

  const { setDocumentTitle } = props;
  
  React.useEffect(() => setDocumentTitle('3S | Free Trial'))

  return (
    <React.Fragment>
      <PageTitle>Two Month Free Trial</PageTitle>
      
      <Card className={classes.card}>
        <Typography className={classes.bodyText}>
           We feel that the best way to show you the impact that STAR will have on your software quality is to let you try it for yourself.
           This means that we are giving you the opportunity to get hands on with a two month free trial!
        </Typography>

        <Typography className={classes.bodyText}>
            <b>This free trial includes:</b>
            <ul>
              <li className={classes.listItem}>Access to our analytics platform and interactive corrective actions for one project, allowing you to <b>proactively diagnose and solve potential software quality issues</b>.</li>
              <li className={classes.listItem}>Access to our decades of expertise in creating <b>robust, reliable, and highly available software systems</b>. We will personally oversee the progress of each project and release, and will consult with you on possible issues and how to address them.</li>
            </ul>
        </Typography>

        <Typography className={classes.bodyText}>
            <b>This free trial will require the following data:</b>
            <ul>
              <li className={classes.listItem}>Defect data for your project in CSV format <a href={process.env.REACT_APP_DEFECT_CSV_TEMPLATE_URL}>(example template)</a>.</li>
              <li className={classes.listItem}>Delivery dates for each release you want to include as part of this trial.</li>
            </ul>
        </Typography>

        <Typography align='center' className={classes.privatePolicyText}>
          If you have any questions regarding data privacy and security, you can read our <a href={process.env.REACT_APP_PRIVACY_POLICY_URL}>Privacy Policy</a> or email us at <b>info@sakurasoftsolutions.com</b>.
        </Typography>

        <Divider/>

        <Box textAlign='center'>
          <Button component={NavLink} to={trialRegisterPath} className={classes.button} color='secondary' variant='contained'>
            Start Your Free Trial
          </Button>
        </Box>
      </Card>
    </React.Fragment>
  )
}

export default LandingPage;
