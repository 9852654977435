import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import PricingTable from './PricingTable';

const parseDate = (dateStr) => {
  if (!dateStr) {
    return undefined;
  }

  const date = new Date(dateStr);

  if (isNaN(date)) {
    return undefined;
  }

  return date;
};

const Container = styled.div`
  padding: 32px 0px;
`;

const HeaderLine = styled(Typography)`
  margin: 16px auto;
`;

export default function TrialDisplay({ownershipInfo, email}) {

  let tagline = `Upgrade your trial membership today!`;

  if (ownershipInfo.membership_expires) {
    const endDate = parseDate(ownershipInfo.membership_expires);
    const now = new Date();
    now.setHours(0, 0, 0);
    if (endDate >= now) {
      tagline = `Your trial membership will end on ${ownershipInfo.membership_expires}. ` + tagline; 
    } 
    else {
      tagline = `Your trial membership expired on ${ownershipInfo.membership_expires}. Please select a membership option to continue using STAR.`; 
    }
  }

  return (
    <>
      <Container>
        <HeaderLine variant='h4' align='center'>{tagline}</HeaderLine>
      </Container>


      <PricingTable customerId={ownershipInfo.company_uuid} email={email} />
    </>
  );
}
