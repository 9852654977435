import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import PageTitle from '../../common/headings/PageTitle';
import styled from '@emotion/styled';


const Root = styled(Box)`
  text-align: justify;
  text-justify: inter-word;
`;

const ListElem = styled.li`
  padding: 6px 0px;
`;

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  margin: 10px auto;
`;

const TH = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  background-color: #00468b;
  color: white;
  text-align: center;
  padding: 0 10px;
`;

const TD = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 0 5px;
`;

const ValCell = styled(TD)`
  text-align: center;
`;

const GreyRow = styled.tr`
  background-color: #ededed;
`;

const BlueRow = styled.tr`
  background-color: #e8f4f8;
`;

const GreenRow = styled.tr`
  background-color: #d2f8d2;
`;

export function DemoDataNotes() {
  return (
    <Root>
      <ol>
        <ListElem>
          Demo data:
          <ol type="a">
            <ListElem>
              Actual defect and effort datasets have been generated based on over 50 years of experience working with real projects.
            </ListElem>
            <ListElem>
              Predictions for arrival, closure, and open defect data will be produced based on proprietary analytical models.
            </ListElem>
          </ol>
        </ListElem>
        <ListElem>
          Demo projects
          <p>There are four sets of project data to demonstrate the power of <b>Automated Defect Prediction</b> for all cases, i.e., no parameter adjustments are needed by end users. </p>
          <ol type="a">
            <ListElem>
              <b>Defect trend analysis:</b> This menu represents a defect prediction based on actual defect trend data.
              <ol type="i">
                <ListElem>
                  <b>Project A:</b> This data set presents a <b>typical scenario</b> where we want to know several weeks prior to the delivery date whether the current release is ready for delivery.
                </ListElem>
                <ListElem>
                  <b>Project B:</b> This data set presents several snapshot views for the same release. It demonstrates the importance of <b>continuous defect prediction</b> as the defect trend continues to change depending on test progress.
                </ListElem>
                <ListElem>
                  <b>Project C:</b> This data set contains three releases within the same project to demonstrate <b>release over release</b>.
                </ListElem>
              </ol>
            </ListElem>
            <ListElem>
              <b>Early defect prediction: Project D</b> 
              <ol type="i">
                <ListElem>
                  <b>Leading data (PPM):</b> This menu represents an early defect prediction <b>without actual defect data</b> during the planning phase. It is based on historical data (e.g., effort, sub-features, test cases) from software development and test. - <b>Release B</b>
                </ListElem>
                <ListElem>
                  <b>Leading data (IPM):</b> Leading Data (IPM): This menu represents a defect prediction <b>with actual defect data</b> using <b>Leading data (PPM)</b> output <b>(Release B)</b> or effort data of current release only <b>(Release A)</b>.
                </ListElem>
              </ol>
            </ListElem>
          </ol>
        </ListElem>
        <ListElem>
          STAR outputs
          <ol type="a">
            <ListElem>
              There are several views:
              <ol type="i">
                <ListElem>
                  <b>Executive summary:</b> Key quality metrics with tables and charts
                  <ol>
                    <ListElem>
                      %Residual defects
                    </ListElem>
                    <ListElem>
                      %Open defects
                    </ListElem>
                    <ListElem>
                      Defects at key milestones
                    </ListElem>
                    <ListElem>
                      Arrival / closure / open defect trend charts
                    </ListElem>
                  </ol>
                </ListElem>
                <ListElem>
                  <b>Corrective action:</b> real-time view of quality impact by a selected action              
                  <ol>
                    <ListElem>
                      Delay delivery date
                    </ListElem>
                    <ListElem>
                      Additional developers for bugfixes
                    </ListElem>
                  </ol>
                </ListElem>
                <ListElem>
                  <b>Arrival / closure:</b> Weekly view of defect arrival and closure curves
                </ListElem>
                <ListElem>
                  <b>Defects by component:</b> Defects trend breakdown by component
                </ListElem>
                <ListElem>
                  <b>Defects by severity:</b> Defect trend breakdown by severity
                </ListElem>
                <ListElem>
                  <b>Early defect prediction:</b> Prediction without actual defect data plus +/- 10% limits. Plus, an early defect prediction with actual data overlaid.
                </ListElem>
                <ListElem>
                  <b>Release over release:</b> Defect trend view of the last three releases, normalized by the delivery date
                </ListElem>
                <ListElem>
                  <b>Prediction stability:</b> Weekly changes in prediction at the delivery date over time for evaluating the stability and accuracy of prediction
                </ListElem>
              </ol>
            </ListElem>
            <ListElem>
              Additional menus for each view using the Defect Filters box on the top right
              <ol type="i">
                <ListElem>
                  <b>Component</b>
                </ListElem>
                <ListElem>
                  <b>Severity</b>
                </ListElem>
              </ol>
            </ListElem>
          </ol>
        </ListElem>
        <ListElem>
          STAR menu vs. Demo projects
          <ol type="a">
            <ListElem>
              <b>Defect trend:</b> Projects A – D all releases
            </ListElem>
            <ListElem>
              <b>Leading data (PPM):</b> Project D Release B only
            </ListElem>
            <ListElem>
              <b>Leading data (IPM):</b> Project D Releases A & B
            </ListElem>
          </ol>
        </ListElem>
      </ol>

      <Table>
        <tr>
          <TH rowSpan={2}>Input Data</TH>
          <TH rowSpan={2}>View Type</TH>
          <TH>Project A</TH>
          <TH>Project B</TH>
          <TH>Project C</TH>
          <TH colSpan={2}>Project D</TH>
        </tr>
        <tr>
          <TH>Release A</TH>
          <TH>All Releases</TH>
          <TH>All Releases</TH>
          <TH>Release A</TH>
          <TH>Project B</TH>
        </tr>

        <GreyRow><ValCell rowSpan={7}>Defect Trend</ValCell><TD>Executive Summary</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Corrective Action</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Arrival/closure</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Defects by component</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Defects by severity</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Release over release</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>
        <GreyRow><TD>Prediction stability</TD><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreyRow>

        <BlueRow><ValCell rowSpan={3}>Leading Data (PPM)</ValCell><TD>Executive Summary</TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell></BlueRow>
        <BlueRow><TD>Corrective Action</TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell></BlueRow>
        <BlueRow><TD>Early prediction </TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell></BlueRow>

        <GreenRow><ValCell rowSpan={4}>Leading Data (IPM)</ValCell><TD>Executive Summary</TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreenRow>
        <GreenRow><TD>Corrective Action</TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreenRow>
        <GreenRow><TD>Early prediction </TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreenRow>
        <GreenRow><TD>Prediction stability</TD><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>NA</ValCell><ValCell>X</ValCell><ValCell>X</ValCell></GreenRow>
      </Table>

      <Table>
        <tr><ValCell>X</ValCell><ValCell>Available</ValCell></tr>
        <tr><ValCell>NA</ValCell><ValCell>Not Available</ValCell></tr>
      </Table>
    </Root>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1280,
  },
  sidebar: {
    borderRight: '1px solid silver',
  },
}));

export default function ReleaseNotesView(props) {
  const classes = useStyles();
  const { setDocumentTitle } = props;

  React.useEffect(() => setDocumentTitle('3S | Demo Data'))

  return (
    <React.Fragment>
      <PageTitle>Demo Data Overview</PageTitle>
      <Card className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box px={2} pb={2}>
              <DemoDataNotes />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}
