import React from 'react';
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';

import Field from '../components/form/Field';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';

export default function ConfirmSignUp(props) {
  const { email, password, setUser, setDocumentTitle } = props;

  const location = useLocation();
  const history = useHistory();

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();

  const [confirmationCode, setConfirmationCode] = React.useState('');
  const [confirmationCodeError, setConfirmationCodeError] = React.useState('');
  const handleConfirmationCodeOnChange = (event) => setConfirmationCode(event.target.value);
  
  React.useEffect(() => setDocumentTitle('3S | Confirm Registration'))

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await Auth.confirmSignUp(email, confirmationCode);
      const user = await Auth.signIn(email, password);
      await Auth.verifyUserAttribute(user, 'email');
      setUser(user);
    }
    catch (error) {
      const { code, message } = error;

      if (code === 'NotAuthorizedException') {
        history.push({
          pathname: '/login',
          search: location.search,
        });
      }

      else {
        setCognitoError(message);
      }
    }
    setSubmitting(false);
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <Typography>
        We have sent an email containing your confirmation code to <b>{email}</b>
      </Typography>
      <br/>
      <Field value={confirmationCode} label="Confirmation Code *" error={confirmationCodeError} autoFocus={true} onChange={handleConfirmationCodeOnChange} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Confirm Email" />
    </form>
  )
}
