import * as React from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Typography, Container } from '@mui/material';

const ChartImage = styled.img`
  width: 100%;
  max-width: 740px;
`;

const SectionContainer = styled.div`
  width: 100%;
  margin-top: -60px;
  margin-left: 0;
  background-image: url('/assets/landing/Group.png');
  height: 875px;
  padding-bottom: 225px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled(Typography)`
  color: white;
  font-size: 2.25rem;
  margin-bottom: 16px;
`;

const Body = styled(Typography)`
  color: white;
  font-size: 1.125rem;
`;

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const YourWorldWith = (props) => {
  return (
    <SectionContainer>
      <Container>
        <Grid container spacing={2}>
          <Column component={Grid} item xs={12} sm={6} display={{ xs: "none", sm: 'flex' }}>
            <ChartImage src={process.env.PUBLIC_URL + '/assets/landing/mid-graphic.png'} alt='Chart Image' />
          </Column>
          <Column component={Grid} item xs={12} sm={6}>
            <Header variant='h4'>
              Your World with STAR: Achieve Software Quality Excellence
            </Header>
            <Body variant='body'>
              •	Base your decisions on data-driven and statistically sound prediction methods
              <br/>
              •	Automated, online, real-time, and easy-to-use service
              <br/>
              •	Streamline simple input data with customization for maximum efficiency 
              <br/>
              •	Collaborate effectively team-wide to get a clear picture of what corrective actions are needed to achieve acceptable quality 
              <br/>
              •	Get a high-level overview of your current software quality with customizable defect filters
            </Body>
          </Column>
        </Grid>
      </Container>
    </SectionContainer>
  ); 
}

export default YourWorldWith;
