import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import PageTitle from '../../common/headings/PageTitle';
import VideoNav from './VideoNav';
import ReactPlayer from 'react-player';
import { withSize } from 'react-sizeme';
import { videos } from './videos';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1280,
  },
  sidebar: {
    borderRight: '1px solid silver',
    paddingTop: '0 !important',
    marginTop: 24,
    paddingRight: 24,
  },
}));

const YoutubePlayer = (props) => {
  const { url, size } = props;

  const height = size.width / 16 * 9;

  return (
    <ReactPlayer width="100%" height={height} url={url} controls={true} />
  );
};

const SizedPlayer = withSize()(YoutubePlayer);

export default function VideosView(props) {
  const classes = useStyles();
  const { setDocumentTitle } = props;

  const [selectedVideo, setSelectedVideo] = useState(videos[0].urlName);

  let { videoName } = useParams();

  if (videoName && videoName !== selectedVideo && videos.find(x => x.urlName === videoName)) {
    setSelectedVideo(videoName);
  }

  const video = videos.find(obj => obj.urlName === selectedVideo);

  const navName = videos.find(x => x.urlName === selectedVideo)['navName'];

  React.useEffect(() => setDocumentTitle('3S | Video Library'))

  return (
    <React.Fragment>
      <PageTitle>Video Library</PageTitle>
      <Card className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} className={classes.sidebar}>
            <VideoNav selectedVideo={navName} />
          </Grid>
          <Grid item xs={12} sm={9}>
            <SizedPlayer url={video.url} />
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}