import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';


const ChartImage = styled.img`
  width: 100%;
`;

const HeaderTypography = styled(Typography)`
  margin: 0 auto;
  max-width: 90%;
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #f4f8ff;
  border-radius: 40px;
  border: 1px solid #f4f8ff;
  margin-bottom: 120px;
`;

const ImageCard = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1000px;
  background: #FFFFFF;
  box-shadow: 0px 52.4321px 54.4487px rgba(65, 62, 101, 0.11), inset 0px -5px 0px #C32F5D;
  border-radius: 26px;
  padding: 20px;
  margin-bottom: -120px;
`;

const ImageContainer = styled.div`
  overflow: hidden;
  border-radius: 13px;
`;

const OutputViews = (props) => {
  return (
    <BackgroundContainer>
      <HeaderTypography variant='h3' align='center' paddingTop={5} paddingBottom={2}>
        Several output views to suit all users from developers to executives
      </HeaderTypography>

      <ImageCard>
        <ImageContainer>
          <ChartImage src={process.env.PUBLIC_URL + '/assets/landing/defects-by-component.png'} alt='Defects By Component Page Screenshot' />
        </ImageContainer>
      </ImageCard>
    </BackgroundContainer>
  ); 
}

export default OutputViews;
