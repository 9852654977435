import React from 'react';

import { useHistory } from 'react-router';
import { Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Auth } from 'aws-amplify';
import uniqid from 'uniqid';

import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ExpandMore, ChevronRight, MoreVert } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Toolbar, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';

import ExternalLink from '../routing/ExternalLink';
import { videosPath, signInPath, releaseNotesPath, trialLandingPath, pricingPath, contactUsPath, aboutUsPath, starOverviewPath, starUseCasesPath, tutorialPath, userGuidePath, technicalReferencesPath, demoDataPath, privacyPolicyPath, termsOfServicePath, landingPath, overviewPath, accountPath } from '../../constants/routes';
import { maxWidth, headerMediumBreakPoint, headerSmallBreakPoint } from '../../constants/dimensions';
import { secondaryMain, primaryMedium, background, textPrimary } from '../../constants/colors';
import Logo from '../components/Logo';


// -------------------------------- Top Bar -------------------------------- //
// ------------------------------------------------------------------------- //
const useNavBarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  right: {
    float: 'right',
  },
  rightLink: {
    color: '#a72755',
  },
  divider: {
    backgroundColor: textPrimary,
    height: 24,
    marginTop: 30,
  },
  userDivider: {
    backgroundColor: '#a72755',
    height: 24,
    marginTop: 12,
  },
  logo: {
    height: '48px',
    width: 'auto',
    float: 'left',
  },
  logoNavItem: {
    height: '100%',
    float: 'left',
    marginRight: 60,
    textDecoration: 'none',
  },
  navItem: {
    color: textPrimary,
    marginLeft: 8,
    marginRight: 8,
    textTransform: 'none',
    textDecoration: 'none',
    cursor: 'pointer',
    height: 48,
  },
  menuItemTypography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
    marginBottom: -4,
  },
  menuItemTypography2: {
    color: textPrimary,
    marginBottom: -4,
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;',
    fontSize: 18,
  },
  link: {
    textDecoration: 'none',
    padding: 8,
    display: 'flex',
    alignItems: 'center',
  },
  menuLink: {
    textDecoration: 'none',
    color: 'black',
  },
  menuIndicator: {
    marginLeft: 4,
  },
  register: {
    backgroundColor: secondaryMain,
    paddingLeft: 25,
    paddingRight: 25,
    height: '47.23px',
    background: 'linear-gradient(0deg, #A72755, #A72755), linear-gradient(306.64deg, rgba(255, 41, 105, 0.65) 27.07%, rgba(247, 122, 28, 0.65) 71.29%), #FF9A3D',
    boxShadow: '0px 17.0142px 34.9238px rgba(218, 50, 108, 0.41), inset 0px 0px 9.32099px rgba(255, 255, 255, 0.52)',
    borderRadius: '55.926px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
  }
}));

const StandaloneMenuItem = styled(MenuItem)({ paddingLeft: 0, paddingTop: 0.2, paddingBottom: 4.35 });

const LargeMenuColumns = (headerLinkSets, popupState) => {
  let columns = {};

  Object.entries(headerLinkSets).forEach(([header, links]) => {
    const colNum = links[0]; // First item in the array is the column number
    if (!columns[colNum]) { columns[colNum] = []; }

    const hoverMenuItems = links.slice(1).map((link) => (
      <NavMenuItem {...link} closeParent={popupState.close} />
    ));

    const content = (
      <React.Fragment key={header}>
        { header && <Typography style={{color: textPrimary}} variant="h6">{header}</Typography> }
        { hoverMenuItems }
      </React.Fragment>
    );

    columns[colNum].push(content);
  });

  return Object.entries(columns).map(([columnNumber, items]) => (
    <Grid item xs={12 / Object.keys(columns).length} key={columnNumber}>
      {items}
    </Grid>
  ));
};

const NavMenuItem = (props) => {
  const classes = useNavBarStyles();
  const [id, setId] = React.useState(uniqid());
  const { title, link, standalone, externalLink, handleClick, childLinks, closeParent } = props;

  if (childLinks) {
    return (
      <PopupState variant="popover" popupId={id}>
        {(popupState) => (
          <React.Fragment>
            <MenuItem aria-controls={id} aria-haspopup="true" aria-expanded={popupState.isOpen} {...bindHover(popupState)}>
              <Typography className={classes.menuItemTypography}>
                {title}
              </Typography>
              <ChevronRight className={classes.menuIndicator} />
            </MenuItem>
            <HoverMenu
              {...bindMenu(popupState)}
              id={id}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {childLinks.map((childLink) => (
                <NavMenuItem {...childLink} closeParent={popupState.close} />
              ))}
            </HoverMenu>
          </React.Fragment>
        )}
      </PopupState>
    );
  }

  if (externalLink) {
    return (
      <MenuItem onClick={closeParent}>
        <ExternalLink href={externalLink} className={classes.menuLink}>
          <Typography className={classes.menuItemTypography}>
            {title}
          </Typography>
        </ExternalLink>
      </MenuItem>
    );
  }

  if (handleClick) {
    const onClick = () => {
      closeParent();
      handleClick();
    };

    return (
      <MenuItem onClick={onClick}>
        <Typography className={classes.menuItemTypography}>
          {title}
        </Typography>
      </MenuItem>
    );
  }

  // if (link) {
    return (
      standalone ?
      <StandaloneMenuItem onClick={closeParent}>
        <NavLink to={link ?? '/'} className={classes.menuLink}>
          <Typography className={classes.menuItemTypography2} variant='h6'>
            {title}
          </Typography>
        </NavLink>
      </StandaloneMenuItem> :
      <MenuItem onClick={closeParent}>
        <NavLink to={link ?? '/'} className={classes.menuLink}>
            <Typography className={classes.menuItemTypography}>
              {title}
            </Typography>
        </NavLink>
      </MenuItem>
    );
  // }
   

  // return null;
}

const NavButton = (props) => {
  const classes = useNavBarStyles();
  const { title, link, externalLink, handleClick, typographyClasses } = props;

  if (handleClick) {
    return (
      <Button className={classes.navItem} onClick={handleClick} >
        <Typography variant='h6' className={clsx(classes.typography, typographyClasses)}>
          {title}
        </Typography>
      </Button>
    );
  }

  if (externalLink) {
    return (
      <ExternalLink className={clsx(classes.navItem, classes.link)} href={externalLink} >
        <Typography variant='h6' className={clsx(classes.typography, typographyClasses)}>
          {title}
        </Typography>
      </ExternalLink>
    );
  }

  return (
    <NavLink className={clsx(classes.navItem, classes.link)} to={link ?? '/'} >
      <Typography variant='h6' className={clsx(classes.typography, typographyClasses)}>
        {title}
      </Typography>
    </NavLink>
  );
}

const NavMenuLink = (props) => {
  const classes = useNavBarStyles();
  const { title, link } = props;

  return (
    <React.Fragment>
      <NavLink className={clsx(classes.navItem, classes.link)} to={link} >
        <Typography variant={'h5'} className={classes.typography}>
          {title}
        </Typography>
      </NavLink>
    </React.Fragment>
  );
}

const NavMenu = (props) => {
  const classes = useNavBarStyles();
  const { title, id, links, largerHoverMenu, typographyClasses, typographyVariant } = props;

  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => (
        <React.Fragment>
          <Button aria-controls={id} aria-haspopup="true" aria-expanded={popupState.isOpen} {...bindHover(popupState)} className={classes.navItem} disableRipple={true}>
            <Typography variant={typographyVariant ?? 'h5'} className={clsx(classes.typography, typographyClasses)}>
              {title}
            </Typography>
          </Button>
          {!largerHoverMenu ? 
            <HoverMenu
              {...bindMenu(popupState)}
              id={id}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {links.map((link) => (
                <NavMenuItem {...link} closeParent={popupState.close} />
              ))}
            </HoverMenu> : 

            <HoverMenu
              {...bindMenu(popupState)}
              id={id}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: {
                  width: '425px',
                },
              }}
            >
              <Grid container spacing={2} style={{ padding: '10px' }}>
                { LargeMenuColumns(links, popupState) }
              </Grid>
            </HoverMenu>
          }
        </React.Fragment>
      )}
    </PopupState>
  );
}

const RightMenu = (props) => {
  const classes = useNavBarStyles();
  const { links } = props;

  return (
    <PopupState variant="popover" popupId='header-menu'>
      {(popupState) => (
        <React.Fragment>
          <IconButton
            aria-controls='header-menu'
            aria-haspopup="true"
            aria-expanded={popupState.isOpen}
            {...bindHover(popupState)}
            className={classes.navItem}
            disableRipple={true}
            size="large">
            <MoreVert />
          </IconButton>
          <HoverMenu
            {...bindMenu(popupState)}
            id='header-menu'
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {links.map((link) => (
              <NavMenuItem {...link} closeParent={popupState.close} />
            ))}
          </HoverMenu>
        </React.Fragment>
      )}
    </PopupState>
  );
}

const serviceLinks = [
  { title: 'Dashboard Demo', externalLink: `${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary` },
  { title: 'Free Trial', link: trialLandingPath },
];

const resourcesLinks = {
  'Documentation' : [1,
    { title: 'STAR Overview', link: starOverviewPath },
    { title: 'STAR Use Cases', link: starUseCasesPath },
    { title: 'Tutorial', link: tutorialPath } ],
  'Legal' : [1,
    { title: 'Terms of Service', link: termsOfServicePath },
    { title: 'Privacy Policy', link: privacyPolicyPath } ],
  'STAR User Resources' : [2,
    { title: 'User Guide', link: userGuidePath },
    { title: 'Demo Data', link: demoDataPath },
    { title: 'Release Notes', link: releaseNotesPath } ],
  '' : [2,
    { title: 'Video Library', link: videosPath, standalone: true },
    { title: 'Technical References', link: technicalReferencesPath, standalone: true } ],
};

const aboutLinks = [
  { title: 'About Us', link: aboutUsPath },
  { title: 'Contact Us', link: contactUsPath },
  { title: 'LinkedIn', externalLink: 'https://www.linkedin.com/company/sakura-software-solutions/'}
];

const customerSupportLinks = [
  { title: 'Contact Us', link: contactUsPath },
  { title: 'Report an Issue', externalLink: 'https://sakurasoftsolutions.atlassian.net/servicedesk/customer/portals'}
];

const LeftBar = (props) => {
  const { hideLinks } = props;
  const classes = useNavBarStyles();

  return (
    <Box className={classes.root}>
      <Button component={Link} className={classes.logoNavItem} to={overviewPath} >
        <Logo />
      </Button>
      
      { !hideLinks && (
        <React.Fragment>
          <NavMenu id='service-menu' title='Service' links={serviceLinks} />
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <NavMenu id='resources-menu' title='Resources' largerHoverMenu={true} links={resourcesLinks} />
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <NavMenuLink id='pricing-link' title='Pricing' link={pricingPath} />
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <NavMenu id='about-menu' title='About 3S' links={aboutLinks} />
        </React.Fragment>
      )}
    </Box>
  )
};

const RightBar = (props) => {
  const classes = useNavBarStyles();
  const { user, setUser, hideLinks, hideAllLinks } = props;
  const history = useHistory(); 

  const signOutHandler = () => {
    Auth.signOut();
    setUser(false);
    history.push({pathname: signInPath});
  }

  const accountLinks = [
    { title: 'Membership', link: accountPath },
    { title: 'Sign Out', handleClick: signOutHandler }
  ];

  if (hideLinks) {
    const links = user ? [
      { title: 'STAR', externalLink: process.env.REACT_APP_DASHBOARD_URL},
      { title: 'Contact Us', link: contactUsPath },
      { title: 'Report an Issue', externalLink: 'https://sakurasoftsolutions.atlassian.net/servicedesk/customer/portals' },
      { title: 'Membership', link: accountPath },
      { title: 'Sign Out', handleClick: signOutHandler },
    ] : [
      { title: 'Contact Us', link: contactUsPath },
      { title: 'Report an Issue', externalLink: 'https://sakurasoftsolutions.atlassian.net/servicedesk/customer/portals' },
      { title: 'Sign In', link: signInPath },
      { title: 'Demo STAR Now', externalLink: `${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary` },
    ];

    if (hideAllLinks) {
      links.push(...serviceLinks);
      links.push(...resourcesLinks);
      links.push({ title: 'Pricing', link: pricingPath });
      links.push(...aboutLinks);
    }

    return (
      <Box className={clsx(classes.root, classes.right)}>
        <RightMenu links={links} />
      </Box>
    );
  }
  
  return (
    <Box className={clsx(classes.root, classes.right)}>
      { user ? (
        <React.Fragment>
          <NavButton title='STAR' externalLink={process.env.REACT_APP_DASHBOARD_URL} typographyClasses={classes.rightLink} />
          <Divider orientation="vertical" flexItem className={classes.userDivider} />
          <NavMenu id='customer-support' title='Customer Support' links={customerSupportLinks} typographyClasses={classes.rightLink} typographyVariant='h6' />
          <Divider orientation="vertical" flexItem className={classes.userDivider} />
          <NavMenu id='account-menu' title='Account' links={accountLinks} typographyClasses={classes.rightLink} typographyVariant='h6' />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavMenu id='customer-support' title='Customer Support' links={customerSupportLinks} typographyClasses={classes.rightLink} typographyVariant='h6' />
          <Divider orientation="vertical" flexItem className={classes.userDivider} />
          <NavButton title='Sign In' link={signInPath} typographyClasses={classes.rightLink} />

          <ExternalLink className={clsx(classes.navItem, classes.register)} href={`${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary`} >
            <Typography variant='h6' className={classes.typography}>
              Demo STAR Now
            </Typography>
          </ExternalLink>

        </React.Fragment>
      ) }
    </Box>
  )
};

// -------------------------------- Header --------------------------------- //
// ------------------------------------------------------------------------- //
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: -100,
    height: 100,
    width: '100%',
  },
  headerRoot: {
    backgroundColor: 'white',
  },
  toolbar: {
    maxWidth: maxWidth,
    width: '100%',
    margin: '0 auto',
    minHeight: '36px',
    padding: '8px 48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  nav: {
    width: '100%',
  },
}));


function Header(props) {
  const classes = useStyles();
  const { user, setUser } = props;

  const [fullSize, setFullSize] = React.useState(window.innerWidth >= headerMediumBreakPoint);
  const [smallSize, setSmallSize] = React.useState(window.innerWidth < headerSmallBreakPoint);

  const updateMedia = () => {
    setFullSize(window.innerWidth >= headerMediumBreakPoint);
    setSmallSize(window.innerWidth < headerSmallBreakPoint);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className={classes.root}>
      <AppBar className={classes.headerRoot}>
        <Toolbar className={classes.toolbar}>
          <LeftBar hideLinks={smallSize} />
          <RightBar user={user} setUser={setUser} hideLinks={!fullSize} hideAllLinks={smallSize} />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header;
