import * as React from 'react';
import styled from '@emotion/styled';
import { Container, Grid, Typography } from '@mui/material';

const Column = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const SectionContainer = styled.div`
  background-image: url('/assets/landing/wave-texture-left.svg');
  background-repeat: no-repeat;
  background-position: left;
  min-height: 610px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1px;
`;

const CardElement = styled.div`
  width: 100%;

  background: #FFFFFF;
  box-shadow: 0px 52.4321px 54.4487px rgba(65, 62, 101, 0.11), inset 0px -5px 0px #C32F5D;
  border-radius: 26px;
  align-content: center;

  min-height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardImage = styled.img`
  max-height: 60px;
  max-width: 210px;
  width: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const NasaImage = styled.img`
  max-height: 90px;
  max-width: 210px;
  width: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Card = (props) => {
  const { university, imageName, name } = props;

  return (
    <>
      <CardElement>
        <CardImage src={process.env.PUBLIC_URL + `/assets/universities/${imageName}`} alt={`${university} Logo`} />

        { name && (
          <Typography variant='h6' align='center' paddingBottom={2}>
            {name}
          </Typography>
        )}
      </CardElement>
    </>
  ); 
}
const NasaCard = () => {
  return (
    <>
      <CardElement>
        <NasaImage src={process.env.PUBLIC_URL + `/assets/nasa_logo.png`} alt='NASA Logo' />
      </CardElement>
    </>
  ); 
}

const Collaborations = (props) => {
  return (
    <SectionContainer>
      <Container maxWidth="lg">
        <Typography variant='h4' align='center' paddingBottom={4}>
          In Collaboration With
        </Typography>
        <Grid container spacing={4} justifyContent='center' paddingBottom={4}>
          <Column item xs={12} sm={6} md={4}>
            <NasaCard />
          </Column>
        </Grid>
        <Grid container spacing={4} justifyContent='center'>
          {/* <Column item xs={12} sm={6} md={4}>
            <Card imageName='duke.svg' university='Duke University' name='Professor Kishor Trivedi' />
          </Column> */}
          <Column item xs={12} sm={6} md={4}>
            <Card imageName='hiroshima.png' university='Hiroshima University' name='Professor Tadashi Dohi' />
          </Column>
          <Column item xs={12} sm={6} md={4}>
            <Card imageName='rutgers.png' university='Rutgers University' name='Professor Hoang Pham' />
          </Column>
          <Column item xs={12} sm={6} md={4}>
            <Card imageName='massachusetts_dartmouth.png' university='Universty of Massachusetts Dartmouth' name='Professor Lance Fiondella' />
          </Column>
        </Grid>
      </Container>
    </SectionContainer>
  ); 
}

export default Collaborations;
