import React, { useState, forwardRef } from "react";
import { Button } from '@mui/material';
import styled from '@emotion/styled';
import { registerPath } from '../../../constants/routes';
import { ServiceInformation } from './pricing_data'
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  border-radius: 10px;
  font-size: .75rem;
  margin-top: 25px;
  margin-left: auto; 
  margin-right: auto;
`;

const PlanHeader = styled.th`
  height: 10px;
  width: 350px;
  border-right: 1px solid black;
  text-align: center;
  font-size: 1.65rem;
  padding-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
`;

const PlanName = styled.th`
  border-right: 1px solid black;
  color: black;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
`;

const GetStartedButton = styled(Button)`
  width: 180px;
  height: 55px;
  background: ${({ buttonColor }) => buttonColor};
  border-radius: 12px;
  color: white;
  font-size: 24px;
  text-transform: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

const ServiceFeatures = styled.th`
  border-right: 1px solid black;
  color: black;
  text-align: center;
  font-size: 1.15rem;
  padding-top: 0px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0px;
`;

const ServicePriceText = styled(ServiceFeatures)`
  font-size: 1.70rem;
  color: ${props => (props.isStrikedOut ? 'lightgray' : 'black')};
`;

const ServicePriceDiscounted = styled(ServiceFeatures)`
  border-right: none;
  font-size: 1.9rem;
  color: black;
`;

const ServiceDisclaimerText = styled(ServiceFeatures)`
  font-size: 0.9rem;
`;

const TDCentered = styled.td`
  border-right: 1px solid black;
  font-size: 1.1rem;
  color: lightgray;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
  text-align: center;
`;

const TDCenteredIncluded = styled(TDCentered)`
  color: black;
`;

const Divider = styled.hr`
  border-top: 2px solid lightgray;
  border-radius: 5px;
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  width: 250px;
  background-color: #323232;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1000;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  font-size: 14px;
`;

const ServicePrice = ({price, term, strikeThrough,}) => {
  if (strikeThrough) {
    return <ServicePriceText isStrikedOut={true}><s>{price}/{term}</s><br/></ServicePriceText>
  }

  return <ServicePriceText>{price}/{term}</ServicePriceText>
};

const ServiceDisclaimer = ({disclaimerText}) => {
  return <ServiceDisclaimerText>{disclaimerText}</ServiceDisclaimerText>
};

const InfoTooltip = ({children, description, leftAlign}) => {
  const [visible, setVisible] = useState(false);

  return (
    <TooltipContainer onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
      {children}
      <TooltipText style={{ textAlign: leftAlign ? "left" : "center" }} isVisible={visible}>
        <b>{description.split('\n').map((item, key) => {
          return <span key={key}>{item}<br/></span>
        })}</b>
      </TooltipText>
    </TooltipContainer>
  );
}

function HoverableCell({featureObj, included, sectionObj}) {
  if (featureObj && included) {
    return (
      <TDCenteredIncluded>
        <span style={{color:'green'}}>✓&nbsp;</span>{featureObj.feature}
        { featureObj.description && 
          <InfoTooltip description={featureObj.description} leftAlign={featureObj.leftAlign ? true : false} >
            <InfoIcon style={{transform: "scale(1)"}} sx={{ verticalAlign: 'middle', fontSize: 16.5, marginLeft: 0.5, color: 'rgba(0, 0, 0, 0.54)' }} />
          </InfoTooltip>
        }
      </TDCenteredIncluded>
    );
  } else if (featureObj) {
    return (
      <TDCentered>{featureObj.feature}</TDCentered>
    );
  } else if (sectionObj) {
    return (
      <ServiceFeatures>
        
        <u>{sectionObj.sectionName.split('\n').map((item, key) => {
          return key < sectionObj.sectionName.split('\n').length - 1 ? <span key={key}>{item}<br/></span> : <span key={key}>{item}</span>
        })}</u>
        { sectionObj.description && 
          <InfoTooltip description={sectionObj.description} leftAlign={true} >
            <InfoIcon style={{transform: "scale(1)"}} sx={{ verticalAlign: 'middle', fontSize: 16.5, marginLeft: 0.5, color: 'rgba(0, 0, 0, 0.54)' }} />
          </InfoTooltip>
        }
      </ServiceFeatures>
    );
  }
}

const ServicePlanTable = forwardRef((props, ref) => {
  const { termPricing, applyDiscount, serviceDisclaimer } = props;
  return (
    <Table ref={ref}>
      <tr>
        <PlanHeader style={{background:'#0070c0'}}></PlanHeader>
        <PlanHeader style={{background:'#00b050'}}></PlanHeader>
        <PlanHeader style={{background:'#ffc000'}}></PlanHeader>
      </tr>
      <tr>
        <PlanName><u>Starter</u></PlanName>
        <PlanName><u>Basic</u></PlanName>
        <PlanName><u>Plus</u></PlanName>
      </tr>

      <tr>
        <ServicePrice price={termPricing.starter} term={termPricing.term} strikeThrough={applyDiscount} />
        <ServicePrice price={termPricing.basic} term={termPricing.term} strikeThrough={applyDiscount} />
        <ServicePrice price={termPricing.plus} term={termPricing.term} strikeThrough={applyDiscount} />
      </tr>

      <tr>
        <ServiceDisclaimer disclaimerText={serviceDisclaimer.starter} />
        <ServiceDisclaimer disclaimerText={serviceDisclaimer.basic} />
        <ServiceDisclaimer disclaimerText={serviceDisclaimer.plus} />
      </tr>

      { applyDiscount && 
        (
          <tr>
            <ServicePrice price={termPricing.discounted.starter} term={termPricing.term} />
            <ServicePrice price={termPricing.discounted.basic} term={termPricing.term} />
            <ServicePrice price={termPricing.discounted.plus} term={termPricing.term} />
          </tr>
        )
      }

      <tr>
        <TDCenteredIncluded><GetStartedButton href={`${registerPath}?plan=starter&term=${termPricing.term}`} buttonColor='#0070c0' hoverColor='#0088d1'>Get Started</GetStartedButton></TDCenteredIncluded>
        <TDCenteredIncluded><GetStartedButton href={`${registerPath}?plan=basic&term=${termPricing.term}`} buttonColor='#00b050' hoverColor='#00c060'>Get Started</GetStartedButton></TDCenteredIncluded>
        <TDCenteredIncluded><GetStartedButton href={`${registerPath}?plan=plus&term=${termPricing.term}`} buttonColor='#ffc000' hoverColor='#ffd110'>Get Started</GetStartedButton></TDCenteredIncluded>
      </tr>
      <tr>
        <TDCenteredIncluded><b>1 Project</b></TDCenteredIncluded>
        <TDCenteredIncluded><b>Unlimited Projects</b></TDCenteredIncluded>
        <TDCenteredIncluded><b>Unlimited Projects</b></TDCenteredIncluded>
      </tr>

      {ServiceInformation.map((sectionItem, sectionIndex) => (
        <>
          <tr>
              <TDCentered><Divider></Divider></TDCentered>
              <TDCentered><Divider></Divider></TDCentered>
              <TDCentered><Divider></Divider></TDCentered>
          </tr>

          <tr>
            <HoverableCell sectionObj={sectionItem} />
            <HoverableCell sectionObj={sectionItem} />
            <HoverableCell sectionObj={sectionItem} />
            {/* <ServiceFeatures><u>{sectionItem.sectionName}</u></ServiceFeatures> */}
            {/* <ServiceFeatures><u>{sectionItem.sectionName}</u></ServiceFeatures> */}
            {/* <ServiceFeatures><u>{sectionItem.sectionName}</u></ServiceFeatures> */}
          </tr>

          {sectionItem.features.map((featureItem, featureIndex) => (
            <tr>
              <HoverableCell featureObj={featureItem} included={featureItem.starter} />
              <HoverableCell featureObj={featureItem} included={featureItem.basic} />
              <HoverableCell featureObj={featureItem} included={featureItem.plus} />
              {/* {featureItem.starter ? <TDCenteredIncluded><span style={{color:'green'}}>✓</span> {featureItem.feature}</TDCenteredIncluded> : <TDCentered>{featureItem.feature}</TDCentered>} */}
              {/* {featureItem.basic ? <TDCenteredIncluded><span style={{color:'green'}}>✓</span> {featureItem.feature}</TDCenteredIncluded> : <TDCentered>{featureItem.feature}</TDCentered>} */}
              {/* {featureItem.plus ? <TDCenteredIncluded><span style={{color:'green'}}>✓</span> {featureItem.feature}</TDCenteredIncluded> : <TDCentered>{featureItem.feature}</TDCentered>}      */}
            </tr>
          ))}
        </>
      ))}

      <tr>
        <PlanHeader style={{background:'#0070c0'}}></PlanHeader>
        <PlanHeader style={{background:'#00b050'}}></PlanHeader>
        <PlanHeader style={{background:'#ffc000'}}></PlanHeader>
      </tr>
    </Table>
);});

export default ServicePlanTable;