import React from 'react';
import PageTitle from '../../common/headings/PageTitle';
import PdfViewer from '../../common/PdfViewer';

export default function StarOverviewView(props) {
  const { setDocumentTitle } = props;

  const file = '/pdfs/star_overview.pdf';
  
  React.useEffect(() => setDocumentTitle('3S | STAR Overview'))

  return (
    <React.Fragment>
      <PageTitle>STAR Overview</PageTitle>
      <PdfViewer file={file} />
    </React.Fragment>
  )
}
