import * as React from 'react';
import styled from '@emotion/styled';
import { Button, Container, Typography } from '@mui/material';
import { secondaryDarker, primaryMain } from '../../../constants/colors';

const SectionContainer = styled.div`
  background-repeat: no-repeat;
  background-position: right;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentContainer = styled(Container)`
  perspective-origin: 0%;
  perspective: min(20vw, 259px);
`;

const RedTrapezoid = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 30px;
  background: linear-gradient(180deg, ${primaryMain}, #557dd4 98.77%);
`;

const InnerContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const DemoButton = styled(Button)`
  width: 230.8px;
  height: 67.22px;

  background: white;
  border-radius: 72.8246px;
  color: #5d5d5d;
  font-size: 21px;
  text-transform: none;

  &:hover {
    background: white;
  }
`;

const CallToAction = (props) => {
    return (
        <>
            <SectionContainer>
                <ContentContainer maxWidth='lg'>
                    <RedTrapezoid />
                    <InnerContent>
                        <Typography variant='h3' color='white' align='center'>
                            Stop guessing. Let STAR guide you.
                        </Typography> 
                        <DemoButton href={`${process.env.REACT_APP_DASHBOARD_URL}/demo/mpm/executive-summary`}>Demo STAR Now</DemoButton>
                    </InnerContent>
                </ContentContainer>
            </SectionContainer>
        </>
    );
}

export default CallToAction;