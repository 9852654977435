import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { Helmet } from 'react-helmet';

import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {primaryMain, primaryLight, secondaryMain, background, textPrimary } from './constants/colors';

import App from './App';

// https://bareynol.github.io/mui-theme-creator
const SakuraThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1286,
      xl: 1536,
    }
  },
  palette: {
    type: 'light',
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: '#0b111b',
    },
    secondary: {
      main: secondaryMain,
    },
    background: {
      default: 'white',
    },
    text: {
      primary: textPrimary,
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: '"Josefin Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  }
};

const theme = createTheme(SakuraThemeOptions);

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />  
        <Helmet>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Spinnaker:400&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,500,700&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap" />
        </Helmet>  
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
