import React from "react";
import makeStyles from '@mui/styles/makeStyles';

// -------------------------------- Top Bar -------------------------------- //
// ------------------------------------------------------------------------- //
const useVideoStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  iframe: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    }
}));

const YoutubeEmbed = (props) => {
  const { embedId, width } = props;
  const classes = useVideoStyles();

  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframe}
        width={width}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

export default YoutubeEmbed;
