import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { releaseNotes } from './notes';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { primaryLight, primaryLightest, secondaryMain, textPrimary } from '../../constants/colors';
import { releaseNotesPath } from '../../constants/routes';


const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid silver',
    background: primaryLightest,
  },
  ul: {
    padding: '0px 12px',
    listStyleType: 'none',
    // border: '1px solid grey',

  },
  li: {
    padding: '6px 12px',
    borderBottom: '1px solid gainsboro',
    // borderTop: 'none',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '&:hover': {
      background: primaryLight,
      cursor: 'pointer',
    }
  },
  liActive: {
    fontWeight: 'bold',
    color: secondaryMain,
  }
}));

export default function VersionNav(props) {
  const classes = useStyles();
  const { selectedRelease } = props;
  const history = useHistory();

  return (
    <Paper className={classes.root}>
      <ul className={classes.ul}>
        { releaseNotes.map((r) => (
          <li 
            key={`releasenotes-${r.name}`}
            className={clsx(classes.li, { [classes.liActive]: r.name === selectedRelease })} 
            onClick={() => history.push(`${releaseNotesPath}/${r.name}`)}
          >
            Release {r.name}
        </li>
        ))}
      </ul>
    </Paper>
  )
}
