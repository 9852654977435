import React from 'react';
import { Link } from 'react-router-dom';
import GetAppIcon from '@mui/icons-material/GetApp';
import { technicalReferencesPath } from '../../constants/routes';
import styled from '@emotion/styled';


const Table = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  font-size: .75rem;
`;

const TH = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  background-color: #00468b;
  color: white;
  text-align: center;
  padding: 5px;
`;

const TD = styled.td`
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
`;

const TDCentered = styled(TD)`
  text-align: center;
`;

const DownloadIcon = styled(GetAppIcon)`
  color: black;
`;

const ReferenceLink = styled(Link)`
  color: #0000EE;
`;

export default function ReferenceNav(props) {
  const { references } = props;

  return (
    <Table>
      <tr>
        <TH>Title</TH>
        <TH>Authors</TH>
        <TH>Publications</TH>
        <TH>Download</TH>
      </tr>
      { references.map((r) => (
        <tr>
          <TD><ReferenceLink to={`${technicalReferencesPath}/${r.urlName}`}>{r.navName}</ReferenceLink></TD>
          <TD>{r.authors}</TD>
          <TD>{r.publications}</TD>
          <TDCentered><a href={`/pdfs/references/${r.en}`} download><DownloadIcon /></a></TDCentered>
        </tr>
      ))}
    </Table>
  )
}
