import React from 'react';
import PageTitle from '../../common/headings/PageTitle';
import PdfViewer from '../../common/PdfViewer';

export default function TutorialView(props) {
  const { setDocumentTitle } = props;

  const file = '/pdfs/tutorial_en.pdf';
  
  React.useEffect(() => setDocumentTitle('3S | Tutorial'))

  return (
    <React.Fragment>
      <PageTitle>Tutorial</PageTitle>
      <PdfViewer file={file} />
    </React.Fragment>
  )
}
