import React, { useState, useRef } from 'react';
import PageTitle from '../../common/headings/PageTitle';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import makeStyles from '@mui/styles/makeStyles';
import { monthlyPricing, yearlyPricing } from './pricing_sections/pricing_data';
import ServicePeriodSelect from './pricing_sections/ServicePeriodSelect';
import ServicePlanTable from './pricing_sections/ServicePlanTable';
import CallToAction from './pricing_sections/CallToAction';
import CustomizationAvail from './pricing_sections/CustomizationAvailable';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1200,
  }
}));

const BodyTypography = styled(Typography)`
  font-size: 0.9rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
`;

const DiscountBanner = styled.div`
  position: relative;
  background-color: #d4d7de;
  color: #000000;
  overflow: hidden;
  padding: 15px 0px;
  margin-bottom: 20px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:before {
    left: 0;
    border-width: 38px 0 38px 25px; /* Fixed pixel value for the triangle */
    border-color: transparent transparent transparent white;
  }

  &:after {
    right: 0;
    border-width: 38px 25px 38px 0; /* Fixed pixel value for the triangle */
    border-color: transparent white transparent transparent;
  }
`;

function PricingView(props) {
  const { setDocumentTitle } = props;
  const classes = useStyles();

  const tableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState("auto");

  const [selectedPricing, setSelectedPricing] = useState(monthlyPricing);
  const [activeButton, setActiveButton] = useState('monthly');

  const servicePeriodChanged = (term) => {
    const termOptions = {'monthly':monthlyPricing, 'yearly':yearlyPricing};
    setSelectedPricing(termOptions[term])
    setActiveButton(term)
  };

  React.useEffect(() => setDocumentTitle('3S | Pricing'))

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setTableWidth(`${entry.contentRect.width}px`);
      }
    });
    if (tableRef.current) { resizeObserver.observe(tableRef.current); }

    return () => {
      if (tableRef.current) { resizeObserver.unobserve(tableRef.current); }
    };
  }, []);

  const applyDiscount = false;

  return (
    <React.Fragment>
      <>
        <PageTitle>Pricing</PageTitle>
        <Card className={classes.card}>
          <Grid container spacing={3}>
            <Grid item xs={12}>

              { applyDiscount && 
                (
                  <DiscountBanner >
                    <BodyTypography variant='body2' style={{fontSize:'2rem'}} marginTop={0} marginBottom={0} align='center'>
                      <b>Limited Time — STAR Introductory Pricing 30% OFF!</b>
                    </BodyTypography>
                  </DiscountBanner>
                )
              }

              <ServicePeriodSelect servicePeriodChanged={servicePeriodChanged} activeButton={activeButton}></ServicePeriodSelect>

              <ServicePlanTable termPricing={selectedPricing} applyDiscount={applyDiscount} serviceDisclaimer={selectedPricing.disclaimer} ref={tableRef}></ServicePlanTable>
              <CustomizationAvail tableWidth={tableWidth}></CustomizationAvail>
              <CallToAction></CallToAction>
            </Grid>
          </Grid>
        </Card>
      </>
    </React.Fragment>
  )
}

export default PricingView;
