import React from 'react';
import styled from '@emotion/styled';
import Container from '@mui/material/Container';

import CallToAction from './sections/CallToAction';
import Collaborations from './sections/Collaborations';
import HowStarWorks from './sections/HowStarWorks';
import MainVideo from './sections/MainVideo';
import OutputViews from './sections/OutputViews';
import DefectFilters from './sections/DefectFilters'
import RealtimeImpact from './sections/RealtimeImpact';
import Tagline from './sections/Tagline';
import VideoCarousel from './sections/VideoCarousel';
import YourWorldWith from './sections/YourWorldWith';
import WhatIsStar from './sections/WhatIsStar'
import AWSGovCloud from './sections/AWSGovCloud'

const FullContainer = styled(Container)`
  padding: 0 !important;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  z-index: -2;
`;

const WavyBackgroundSection = styled(FullContainer)`
  position: relative;
  z-index: -1;
  top: -120px;
  margin-bottom: -100px;
  @media (max-width: 600px) {
    margin-bottom: -60px;
  }
`;

const BottomBackgroundSection = styled(FullContainer)`
  position: relative;
  z-index: -1;
  top: -120px;
  margin-bottom: -200px;
`;

const MainVideoContainer = styled(Container)`  
  position: relative;
  margin-bottom: -200px;
  top: -120px;
`;

const SpacedContainer = styled(Container)`
  margin-bottom: 80px;
`;

const ExtraSpacedContainer = styled(Container)`
  margin-bottom: 200px;
`;

const ExtraSpacedContainerRelative = styled(Container)`
  position: relative;
  margin-bottom: 50px;
  top: -120px;
`;

export default function LandingView(props) {
  const { setDocumentTitle } = props;
  
  React.useEffect(() => setDocumentTitle('3S'))

  return (
    <>
      <FullContainer maxWidth="xl">
        <Tagline />
      </FullContainer>
      <ExtraSpacedContainerRelative maxWidth="lg">
        <WhatIsStar />
      </ExtraSpacedContainerRelative>
      <MainVideoContainer maxWidth="lg">
        <MainVideo />
      </MainVideoContainer>
      <FullContainer maxWidth="xl">
        <YourWorldWith />
      </FullContainer>
      <ExtraSpacedContainer maxWidth="lg">
        <RealtimeImpact />
      </ExtraSpacedContainer>
      <ExtraSpacedContainer maxWidth="lg">
        <DefectFilters />
      </ExtraSpacedContainer>
      <WavyBackgroundSection maxWidth="xl">
        <Collaborations />
      </WavyBackgroundSection>
      <ExtraSpacedContainer maxWidth="lg">
        <HowStarWorks />
      </ExtraSpacedContainer>
      <BottomBackgroundSection maxWidth="xl">
        <CallToAction />
      </BottomBackgroundSection>
    </>
  );
}
