import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { primaryLight, textPrimary } from '../constants/colors';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  viewer: {
    margin: '0 auto',
    maxWidth: 1080,
    width: '100%',
  },
  nav: {
    // display: 'flex',
    borderRadius: '0 0 4px 4px',
    border: '1px solid grey',
    width: '100%',
    background: primaryLight,
    height: 32,
    padding: '0px 12px',
  },
  navButton: {
    margin: 6,
    height: 18,
    fontWeight: 'bold',
    float: 'right',
    fontSize: 9,
    lineHeight: '18px',
  },
  textContainer: {
    minWidth: 65,
  },
  navText: {
    fontSize: 12,
    lineHeight: '32px',
    fontWeight: 'bold',
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '32px',
  },  
  link: {
    textAlign: 'center',
    color: textPrimary,
    textTransform: 'none',
    cursor: 'pointer',
    fontSize: 16,
    lineHeight: '32px',
    fontWeight: 'bold',
  },
  downloadTextContainer: {
    minWidth: 65,
    textAlign: "center",
  },
}));


export default function DocumentationView(props) {
  const classes = useStyles();

  const { file, languageSelector, downloadLink } = props;

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  React.useEffect(() => {
    setTotalPages(0);
    setCurrentPage(1);
  }, [file]);

  const onDocumentLoadSuccess = ({numPages}) => setTotalPages(numPages);
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);

  const NavBar = () => (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" boxShadow={2} className={classes.nav}>
      <div className={classes.textContainer}>
        <Typography className={classes.navText}>
          Page {currentPage} of {totalPages}
        </Typography>
      </div>

      <div>
        <Button className={classes.navButton} variant='contained' color='secondary' disabled={currentPage >= totalPages} onClick={onNextPage}>
          Next Page
        </Button>
        <Button className={classes.navButton} variant='contained' color='secondary' disabled={currentPage <= 1} onClick={onPrevPage}>
          Prev Page
        </Button>
      </div>

    </Grid>
  );

  return (
    <React.Fragment>
      <Card className={classes.viewer}>
        {languageSelector}
        <Box p={2}>
          <div className={classes.downloadTextContainer}>
            { 
              downloadLink && (
                <a className={classes.link} href={file} download>Download</a>
                ) 
            }
          </div>
          <SizeMe>
            {({ size }) => (
              <Document
                file={process.env.PUBLIC_URL + file}
                onLoadSuccess={onDocumentLoadSuccess}
              > 
                <Page pageNumber={currentPage} width={size.width ? size.width: 1}/>
              </Document>
            )}
          </SizeMe>
        </Box>

        { languageSelector && <br/> }
        { totalPages > 1 && <NavBar /> }
      </Card>
    </React.Fragment>
  )
}
