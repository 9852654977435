import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { primaryLightest } from '../../constants/colors';
import { en, jp } from './notes';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid silver',
    background: primaryLightest,
    padding: '4px 12px',
    marginBottom: 12,
  },
}));


export default function DocumentationView(props) {
  const classes = useStyles();

  const { language, onChange, releaseVersion } = props;

  return (
    <Paper mx={2} className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="gender"
          name="controlled-radio-buttons-group"
          value={language}
          onChange={onChange}
        >
          <FormControlLabel value={en} control={<Radio size="small" />} label={<Box component='div' fontSize={12} fontWeight='bold'>English</Box>} />
          { releaseVersion.jp && <FormControlLabel value={jp} control={<Radio size="small" />} label={<Box component='div' fontSize={12} fontWeight='bold'>日本語</Box>} /> }
        </RadioGroup>
      </FormControl>
    </Paper>
  )
}
