import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import PageTitle from '../../common/headings/PageTitle';
import ReferenceNav from './ReferenceNav';
import ReferenceIndex from './ReferenceIndex';
import LanguageSelector from '../release_notes/LanguageSelector';
import PdfViewer from '../../common/PdfViewer';
import { en, techReferences } from './references';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GetAppIcon from '@mui/icons-material/GetApp';
import { technicalReferencesPath } from '../../constants/routes';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 24,
    margin: '0 auto',
    maxWidth: 1080,
  },
  sidebar: {
    borderRight: '1px solid silver',
    paddingTop: '0 !important',
    marginTop: 24,
    paddingRight: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  link: {
    color: 'black',
    textDecoration: 'none',
    fontSize: 20,
  },
}));

function TechnicalReferencesHeader(props) {
  const classes = useStyles();
  const { reference } = props;
  const history = useHistory();

  return (
    <React.Fragment>
      <div className={classes.header}>
        <IconButton className={classes.link} onClick={() => history.push(`${technicalReferencesPath}`)}><ArrowBackIosIcon fontSize="small" /> Index</IconButton>

        <IconButton className={classes.link} href={`/pdfs/references/${reference.en}`} download><GetAppIcon /></IconButton>
      </div>
    </React.Fragment>
  );
}

export default function TechnicalReferencesView(props) {
  const classes = useStyles();
  const { setDocumentTitle } = props;

  const [language, setLanguage] = useState(en);
  const [selectedReference, setSelectedReference] = useState();

  let { referenceName } = useParams();

  if (referenceName !== selectedReference) {
    if (!referenceName) {
      setSelectedReference(undefined);
    } else if (referenceName !== selectedReference && techReferences.find(x => x.urlName === referenceName)) {
      setSelectedReference(referenceName);
    }
  }

  const referenceVersion = techReferences.find(obj => obj.urlName === selectedReference);
  const navName = referenceVersion ? referenceVersion['navName'] : undefined;

  React.useEffect(() => setDocumentTitle('3S | Technical References'))

  let displayComponent;
  if (selectedReference) {
    displayComponent = (
        <React.Fragment>
          <TechnicalReferencesHeader reference={referenceVersion} />
          <PdfViewer file={`/pdfs/references/${referenceVersion[language]}`} />
        </React.Fragment>
      );
  } else {
    displayComponent = <ReferenceIndex references={techReferences} />
  }

  return (
    <React.Fragment>
      <PageTitle>Technical References</PageTitle>
      <Card className={classes.card}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={4} className={classes.sidebar}>
            <ReferenceNav selectedReference={navName} />
          </Grid> */}
          <Grid item xs={12}>
            { displayComponent }
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  )
}