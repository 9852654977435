import React from 'react';
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import EmailField from '../components/form/EmailField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';
import AuthLink from '../components/form/AuthLink';
import ForgotPassword from './ForgotPassword';
import { SignInLink } from './SignIn';

export default function ForgotPasswordPrompt(props) {
  const {
    email,
    emailError,
    setEmailError,
    handleEmailChange,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();
  const [requestNewPassword, setRequestNewPassword] = React.useState(false);

  React.useEffect(() => setDocumentTitle('3S | Reset Password'))

  const handleSubmit = async () => {
    // Validation.
    if (!email) {
      setCognitoError('Email Required');
      return;
    }
    if (emailError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      await Auth.forgotPassword(email);
      setRequestNewPassword(true);
    }
    catch (error) {
      const { message } = error;
      setCognitoError(message);
    }
    setSubmitting(false);
  }

  if (requestNewPassword) {
    return <ForgotPassword {...props} />
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      <Typography>
        Send password reset code.
      </Typography>
      <br />
      <EmailField value={email} error={emailError} onChange={handleEmailChange} autoFocus={true} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Send Code" />
      <SignInLink label="Return to Sign In" />
    </form>
  )
}


export function ForgotPasswordPromptLink() {
  const location = useLocation();
  const history = useHistory();

  const onClick = () => {
    history.push({
      pathname: '/reset-password',
      search: location.search,
    });
  }

  return (
    <AuthLink label='Forgot Password?' onClick={onClick} />
  )
}
