import React from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const useStyles = makeStyles((theme) => ({
  formStepperRoot: {
    padding: '12px 18px',
    background: 'none',
  },
  stepLabel: {
    fontSize: 12,
  },
  formButtonsRoot: {
    width: 240,
    margin: '0 auto',
  },
  formButton: {
    width: 110,
    margin: '12px 5px',
    padding: 4,
    fontSize: 10,
    fontWeight: 'bold',
  },
  fullWidth: {
    width: '100%',
  }
}));

export default function FormStepper(props) {
  const classes = useStyles();
  const { steps, currentStep } = props;

  return (
    <Stepper className={classes.formStepperRoot} activeStep={currentStep}>
      {steps.slice(0,-1).map((step) => (
        <Step key={step.name}>
          <StepLabel classes={{label: classes.stepLabel}}>{step.name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export function FormStepperButtons(props) {
  const classes = useStyles();
  const { steps, currentStep, totalSteps, onPrevious, onSubmit, disableNext } = props;

  const isFirstStep = currentStep === 0;
  const isFinalStep = currentStep === totalSteps - 1;

  return (
    <div className={classes.formButtonsRoot}>
      {!isFinalStep && (
        <Button tabIndex={-1} className={classes.formButton} disabled={isFirstStep} onClick={onPrevious}>
          Back
        </Button>
      )}
      <Button type="submit" className={clsx(classes.formButton, {[classes.fullWidth]: isFinalStep})} variant="contained" color="secondary" disabled={disableNext} onClick={onSubmit}>
        {steps[currentStep].nextText}
      </Button>
    </div>
  )
}