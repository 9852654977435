import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import ChipInput from '../../common/components/ChipInput';
import {primaryMain} from '../../constants/colors';
import Field from './Field';


const useStyles = makeStyles((theme) => ({
  text: {
    color: primaryMain,
    marginBottom: 12,
    fontWeight: 'bold'
  },
}));

export default function ProjectForm(props) {
  const classes = useStyles();
  const { project, projectError, handleProjectChange } = props;
  const { releases, releasesError, handleReleasesChange } = props;

  const onReleasesChange = (e, values) => {
    handleReleasesChange(values);
  };

  return (
    <React.Fragment>
      <Typography className={classes.text}>
        Please enter the name of the project you will be using for this trial.
      </Typography>
      <Field value={project} label='Project Name' autoFocus={true} error={projectError} onChange={handleProjectChange} />

      <br/>
      <br/>

      <Typography className={classes.text}>
        Add at least one release by typing it's name and pressing enter.
      </Typography>
      <ChipInput
        label='Releases'
        value={releases}
        onChange={onReleasesChange}
        helperText={releasesError}
        error={releasesError}
        fullWidth={true}
      />
    </React.Fragment>
  )
}
