import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

const VerticalFlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const HorizontalFlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const PageCard = styled(Card)`
  padding: 24px;
  margin: 0 auto;
  max-width: 1280px;
`;

export { VerticalFlexBox, HorizontalFlexBox, PageCard };
